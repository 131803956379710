import React from "react";
import { Link } from "react-router-dom";
import {jwtValid} from './Utils'
import { createUser } from './createUser'
import history from "./history";
import { createCartTransfer } from './createCartTransfer'

class TryNow extends React.Component {

  constructor(props) {
    super(props);
  }

  async setJwtAndTransferCart(data) {

    await localStorage.removeItem('valid_plans');
    await localStorage.setItem("jwt", data.jwt);

    createCartTransfer()
    .then(res => {
      if(res.error) {
        this.setState({
          isLoaded: true
        });        
        let myErrors = {}
        myErrors.login = res.error
        this.setState({myErrors: myErrors})
      } else {
        this.setState({
          products: res.products
        }, () => {
          this.setState({
            isLoaded: true
          });
          {/*        
          if (res.plan_status === "valid_paid") {
            localStorage.setItem('plan_valid', true)
            if (res.user.topic) {
              history.push("/dashboard");
            } else {
              history.push("/for-students");
            }
          } else if (res.plan_status === "expired_paid") {
            history.push("/upgrade");
            this.props.setNotification({notice: "Your subscription is expired. Please upgrade your plan."});            
          } else if (res.plan_status === "no_plan") {
            if (this.state.products.length > 0) {
              history.push("/checkout");
            } else {
              history.push("/orders");
            }
            localStorage.setItem('plan_valid', true)
            if (res.user.topic) {
              history.push("/dashboard");
            } else {
              history.push("/for-students");
            }          
          }
        */}
          localStorage.setItem('valid_plans', JSON.stringify(res.topics_list_include_free))
          history.push("/for-students");
        });
      }
    })    
  }    

  createGuestUser(event) {
    event.preventDefault();

    const email = `guest_${Date.now()}${Math.floor(Math.random() * 100)}@prepform.com`
    const password = "secret123S"
    
    localStorage.setItem("guest", true);

    createUser({"user": {"guest": true, "email": email, "password": password}})
    .then(response => {

      const request = {"auth": {"email": email, "password": password}};    
      fetch('/api/v1/user_token', {
        method: 'POST',
        body: JSON.stringify(request),     
        headers: {'Content-Type': 'application/json', 'Accept': 'application/json' }
      })    
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })    
      .then((data) => {
        this.setJwtAndTransferCart(data)     
      })
    })     
    .catch(error => {
      history.push("/login");
    });
  }

  render () {
    const {buttonClass} = this.props
    return (
      <>
        {jwtValid(localStorage.getItem('jwt')) 
          ?
            <Link to="/for-students" className={`button is-rounded ${buttonClass}`}>Try It Now</Link>
          :
            <Link to="/signup" className={`button is-rounded ${buttonClass}`} onClick={(e) => this.createGuestUser(e)}>Try It Now</Link>
        }
      </>
    )   
  }
}


export default TryNow