import React from "react";
import { VictoryLabel, VictoryTooltip } from 'victory';

class PieLabel extends React.Component {
  render() {
    const { datum, active } = this.props;
    const name = datum.name
    const strong_length = datum.strong_length
    const moderate_length = datum.moderate_length
    const todo_length = datum.todo_length

    return(
      <g className="pie_legend" style={ active ? { visibility: "visible", opacity: 1} : {visibility: "hidden", opacity: 0}}>
        <foreignObject width={400} height={400} x={0} y={420} orientation="top" >
          <div className="legend_container">
            <div className="pie_legend_circle" style={{backgroundColor: datum.color}}></div>
            <div className="legend_content">
              <h2 className="title is-5 has-text-weight-bold mb-0">{name}</h2>
              <nav className="level is-mobile">
                <div className="level-left">
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Strong</p>
                      <p className="title is-4">{strong_length}</p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">Moderate</p>
                      <p className="title is-4">{moderate_length}</p>
                    </div>
                  </div>
                  <div className="level-item has-text-centered">
                    <div>
                      <p className="heading">To-do</p>
                      <p className="title is-4">{todo_length}</p>
                    </div>
                  </div>
                </div>
              </nav>
            </div>
          </div>
        </foreignObject>
      </g>
    )
  }
}

export default PieLabel;