export const createProspect = (prospect) => {
  return fetch('/api/v1/prospects', {
    method: 'POST',
    body: JSON.stringify(prospect),     
    headers: {'Content-Type': 'application/json',
              'Accept': 'application/json',
    }
  })
  .then(res => res.json())
}

