export const removeFromCart = (order) => {
  let token = "Bearer " + localStorage.getItem("jwt")
  return fetch("/api/v1/carts/remove_cart_item", {
    method: "PUT",
    headers: {
      "Authorization": token,
      "Content-Type": "application/json",
      'Accept': 'application/json'
    },
    body: JSON.stringify(order)
  })
  .then(res => res.json())
}

