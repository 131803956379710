import React from "react";
import {Route, Redirect} from "react-router-dom";
import {jwtValid} from './Utils'

const AppRouteSignedIn = ({component: Component, layout: Layout, setNotification, addToCart, removeFromCart, products, user, ...rest}) => (
  <Route 
    {...rest}
    render={props => (
      jwtValid(localStorage.getItem('jwt'))
        ?
          <Layout>
            <Component setNotification={setNotification} addToCart={addToCart} removeFromCart={removeFromCart} products={products} user={user} {...props}/>
          </Layout>
        :
          <Redirect to="/login"/>
    )}
  />
)


export default AppRouteSignedIn;
