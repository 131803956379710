import React from 'react';
import Loading from "./Loading";
import { createFlashcard } from './createFlashcard'
import {handleResponse} from './Utils'

// We use the Map construct for checkedTags for flexibility and convenience of setting and getting values
class NewFlashcard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFront: true,
      showBack: false,
      isLoaded: false,
      myErrors: {
        newFlashcard: ''
      },          
    };      

    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitFromTest = this.onSubmitFromTest.bind(this);
    this.stripHtmlEntities = this.stripHtmlEntities.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.showFront = this.showFront.bind(this);
    this.showBack = this.showBack.bind(this);
    this.clearState = this.clearState.bind(this);
    this.handleCancelFromTest = this.handleCancelFromTest.bind(this);
  }

  // Get tags
  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt")
    const url = "/api/v1/flashcards/new";
    fetch(url, { method: 'GET', headers: {'Authorization': token }})
    .then((flashcard) => handleResponse(flashcard))
    .then(response => this.setState({ ...response, front: "", back: "", tags: "", isLoaded: true }))     
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }

  stripHtmlEntities(str) {
    return String(str)
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  onSubmit(event) {
    event.preventDefault();
    const { front, back, tags } = this.state;
    const body = { front, back, tags }

    createFlashcard(body)
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors.newFlashcard = res.error
        this.setState({myErrors: myErrors})
      } else {
        this.props.history.push("/flashcards");
      }
    })
    .catch(error => {
      this.props.setNotification({error: error.message}) 
    })
  }

  onSubmitFromTest(event) {
    event.preventDefault();
    const { front, back, tags } = this.state;
    const body = { front, back, tags }

    createFlashcard(body)
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors.newFlashcard = res.error
        this.setState({myErrors: myErrors})
      } else {
        this.clearState()
        // This closes the Create Flashcard box in EditTest
        this.props.handleCancel()
        this.props.showAlert()
      }
    })
    .catch(error => console.log(error.message)); 
  }  

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleCancel(event) {
    event.preventDefault()
    this.clearState()
    this.props.history.push("/flashcards");
  }

  clearState(){
    this.setState({
      front: "",
      back: "",
      tags: ""
    })    
  }

  showFront() {
    this.setState({
      showFront: true,
      showBack: false
    })
  }

  showBack() {
    this.setState({
      showFront: false,
      showBack: true
    })
  }

  handleCancelFromTest(event) {
    event.preventDefault()
    this.clearState()
    this.props.handleCancel(event)
  }  

  render() {
    const {myErrors, showFront, showBack, front, back, tags} = this.state
    if (this.state.isLoaded === false) {
      return (
        <Loading/>   
      )
    } else {
      return (
        <>
          {this.props.fromTest
            ? <>
                <form onSubmit={this.onSubmitFromTest}>
                  {this.myErrors && 
                    <p className='help is-danger'>{this.myErrors.newFlashcard}</p>}      
                  <div className="columns">
                    <div className="column">
                      <div className="tabs is-right is-small">
                        <ul>
                          <li className={`${showFront ? "is-active" : ""}`}><a onClick={this.showFront}>Front</a></li>
                          <li className={`${showBack ? "is-active" : ""}`}><a onClick={this.showBack}>Back</a></li>
                        </ul>
                      </div>                    
                      <div className="field">             
                        <div className="control">
                          {showFront &&
                            <textarea className="textarea" rows="4" name="front" value={front} placeholder="Front" onChange={this.handleChange}></textarea>
                          }
                          {showBack &&
                            <textarea className="textarea" rows="4" name="back" value={back} placeholder="Back" onChange={this.handleChange}></textarea>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="columns">
                    <div className="column">      
                      <div className="field">
                        <label className="label"> 
                          Tags
                        </label>              
                        <div className="control">
                          <input className="input" type="text" placeholder="Comma-separated tags" name="tags" value={tags} onChange={this.handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <div className="buttons">
                      <button type="submit" className="button is-dark">Create</button>
                      <button className="button is-light" onClick={this.handleCancelFromTest}>Cancel</button>
                    </div>
                  </div>
                </form>
              </>            
            : <>
                <h1 className="title mb-0">New Flashcard</h1>
                {this.props.user.topic &&
                  <p className="tertiary_gray mb-5">{this.props.user.topic}</p>
                }
                <div className="box">
                  <form onSubmit={this.onSubmit}>
                    {this.myErrors && 
                      <p className='help is-danger'>{this.myErrors.newFlashcard}</p>}      
                    <div className="columns">
                      <div className="column">
                        <div className="field">
                          <label className="label"> 
                            Front
                          </label>              
                          <div className="control">
                            <textarea className="textarea" rows="6" name="front" value={front} onChange={this.handleChange}></textarea>                                                 
                          </div>
                        </div>
                      </div>
                      <div className="column">
                        <div className="field">
                          <label className="label"> 
                            Back
                          </label>              
                          <div className="control">
                            <textarea className="textarea" rows="6" name="back" value={back} onChange={this.handleChange}></textarea>                                                
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="columns">
                      <div className="column">      
                        <div className="field">
                          <label className="label"> 
                            Tags
                          </label>              
                          <div className="control">
                            <input className="input" type="text" placeholder="Comma-separated tags" name="tags" value={tags} onChange={this.handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="field">
                      <div className="buttons">
                        <button type="submit" className="button is-dark">Create</button>
                        <button className="button is-light" onClick={this.handleCancel}>Cancel</button>
                      </div>
                    </div>
                  </form>
                </div>
              </>
          }
        </>
      );
    }    

  }      
}

export default NewFlashcard;