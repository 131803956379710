export const createCart = (order) => {
  return fetch("/api/v1/carts/create", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Accept': 'application/json'
    },
    body: JSON.stringify(order)
  })
  .then(res => res.json())
}

