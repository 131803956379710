import React, { useState } from 'react';
import { createUser } from './createUser'
import PasswordStrength from "./PasswordStrength";
import { useForm } from 'react-hook-form'
import { useHistory, Link } from "react-router-dom";
import Loading from "./Loading";

// We use the Map construct for checkedTags for flexibility and convenience of setting and getting values
const Signup = props => {
  const { register, errors, getValues, handleSubmit } = useForm();

  let history = useHistory();

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");  
  const [password_confirmation, setPasswordConfirmation] = useState("");  
  const [test_date, setTestDate] = useState("");
  const [isLoaded, setIsLoaded] = useState({});
  const [myErrors, setMyErrors] = useState({});

  const onSubmit = data => { 
    localStorage.removeItem('guest');
    localStorage.removeItem('jwt');
    localStorage.removeItem('valid_plans');

    setIsLoaded(false)

    const request = {"user": {"first_name": data.first_name, "last_name": data.last_name, "email": data.email, "password": data.password, "password_confirmation": data.password_confirmation, "test_date": data.test_date}};
    createUser(request)
    .then(res => {
      if(res.error) {
        setIsLoaded(true)
        let myErrors = {}
        myErrors['email'] = res.error
        setMyErrors(myErrors)
      }else {
        setIsLoaded(true)
        history.push("/");
        props.setNotification({notice: "Almost done! Please confirm your account in the email we just sent you."})
      }
    })
  } 

  return (
    <>
      {isLoaded === false && 
        <Loading/>
      }    
      <section className="hero is-medium signup_hero">
        <div className="hero-body">
          <div className="columns is-centered">
            <div className="column is-one-third">
              <div className="box has-background-dark-green">
                <h1 className="title has-text-white mb-6">Sign Up</h1>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <div className="field">
                    <label htmlFor="first_name" className="label">First Name* </label>
                    <input ref={register({ 
                      required: 'First name is required',
                      validate: {
                        isAlphanumeric: (value) => {
                          return value.match(/^\w+$/) || 'Please use alphanumeric characters only'
                        },
                      }
                      })} 
                      name="first_name" id="first_name" type="text" className={errors.first_name ? "is-danger input" : "input"} onChange={e => setFirstName(e.target.value)}/>
                    {errors.first_name && (
                      <p className="help is-danger">{errors.first_name.message}</p>
                    )}                              
                  </div>
                  <div className="field">
                    <label htmlFor="last_name" className="label">Last Name* </label>
                    <input ref={register({ 
                      required: 'Last name is required',
                      validate: {
                        isAlphanumeric: (value) => {
                          return value.match(/^\w+$/) || 'Please use alphanumeric characters only'
                        },
                      }                
                      })} 
                    name="last_name" id="last_name" type="text" className={errors.last_name ? "is-danger input" : "input"} onChange={e => setLastName(e.target.value)} />
                    {errors.last_name && (
                      <p className="help is-danger">{errors.last_name.message}</p>
                    )}                              
                  </div>                          
                  <div className="field">
                    <label htmlFor="email" className="label">Email* </label>
                    <input ref={register({ 
                      required: 'Email is required',
                      pattern: {
                        value: /^(('[\w-\s]+')|([\w-]+(?:\.[\w-]+)*)|('[\w-\s]+')([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                        message: "Invalid email format"
                      }
                    })} 
                    name="email" id="email" type="email" className={errors.email ? "is-danger input" : "input"} onChange={e => setEmail(e.target.value)} />
                    {errors.email && (
                      <p className="help is-danger">{errors.email.message}</p>
                    )}              
                  </div>
                  <div className="field">
                    <PasswordStrength 
                      custref={register({ 
                        required: 'Password is required',
                        validate: {
                          isMediumStrength: (value) => {
                            return value.match(/^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/) || 'Password must include 1 lowercase letter, 1 uppercase letter, 1 number, and be 8 or more characters.'
                          },
                        }
                      })}                 
                      onChange={e => setPassword(e.target.value)} 
                      errorClass={errors.password ? "is-danger input" : "input"}
                      name="password"
                      label="Password">
                    </PasswordStrength>
                    {errors.password
                      ?
                        <p className="help is-danger">{errors.password.message}</p>
                      :
                        <p className="help">Password must include 1 lowercase letter, 1 uppercase letter, 1 number, and be 8 or more characters.</p>
                    }                              
                  </div>
                  <div className="field">
                    <label htmlFor="password_confirmation" className="label">Password Confirmation*</label>
                    <input
                    ref={register({
                      required: 'Please confirm password!',
                      validate: {
                        matchesPreviousPassword: (value) => {
                          const { password } = getValues();
                          return password === value || 'Passwords should match!';
                        },
                      }
                    })}              
                    name="password_confirmation" id="password_confirmation" type="password" className={errors.password_confirmation ? "is-danger input" : "input"} onChange={e => setPasswordConfirmation(e.target.value)} />
                    {errors.password_confirmation && (
                      <p className="help is-danger">{errors.password_confirmation.message}</p>
                    )}                              
                  </div>
                  {/*}
                  <div className="field">
                    <label className="label">How did you hear about us?</label>
                    <div className="control">
                      <div className="select">
                        <select>
                          <option>Choose one</option>
                          <option>With options</option>
                        </select>
                      </div>
                    </div>
                  </div>
                  <div className="field">
                    <label htmlFor="test_date" className="label">Test Date*</label>
                    <input ref={register({ 
                      required: 'Test date is required'
                      })}
                      name="test_date" id="test_date" type="date" className={errors.test_date ? "is-danger input" : "input"} onChange={e => setTestDate(e.target.value)}/>
                    {errors.test_date && (
                      <p className="help is-danger">{errors.test_date.message}</p>
                    )}
                    {myErrors.email &&
                      <p className="help is-danger">{myErrors.email}</p>
                    }              
                  </div>
                  */}            
                  <div className="field">
                    <div className="control">
                      <p className="help">
                        By signing up, you are agreeing to the <a href="/terms">Terms of Service</a>
                      </p>
                    </div>
                  </div>                  
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-link">Sign Up</button>
                    </div>
                  </div>
                    <div className="field">
                      <div className="control">                    
                        <Link to="/login" className="is-text">Log In</Link>
                      </div>
                    </div>                                      
                </form>   
              </div>        
            </div>
          </div>
        </div>
      </section>
    </>
  );      
}
export default Signup;