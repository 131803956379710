import React from "react";
import { Link } from "react-router-dom";
import moment from 'moment/moment.js'
import {handleResponse} from './Utils'
import Tabs from "./Tabs";
import TableRow from "./TableRow";
import Loading from "./Loading";

class Lessons extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      lessons: [],
      filtered_lessons: [],
      isLoaded: false
    };
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt")
    const url = "/api/v1/lessons/index";
    fetch(url, { method: 'GET', headers: {'Authorization': token }})
    .then((test) => handleResponse(test))
    .then(response => this.setState({ lessons: response, filtered_lessons: response, isLoaded: true }))
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }

  render() {
    const {lessons, filtered_lessons} = this.state;

    if (this.state.isLoaded === false) {
      return (
        <Loading/>    
      )
    } else {
      return (
        <>
          <h1 className="title">Lessons</h1>
          {lessons.length > 0
            ?
              <div className="box">
                {filtered_lessons.length > 0
                  ?
                    <div className="table-container">
                      <table className="table is-narrow">
                        <thead>
                          <tr>
                            <th>Name</th>
                            <th>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filtered_lessons.map((lesson) => {
                            return (
                              <TableRow key={lesson.id} lesson={lesson} indent={0}/>
                            )
                          })}
                        </tbody>
                      </table>
                    </div>
                  :
                    <article className="message">
                      <div className="message-body">{`No lessons to show.`}</div>
                    </article>                  
                }               
              </div>
            :
              <article className="message is-dark">
                <div className="message-body">
                  There are no lessons.
                </div>
              </article>
          }
        </>
      );
    }    
  }    

}
export default Lessons;