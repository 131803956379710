import React from "react";

const Tab = props => {
  const { name } = props.tab;
  const { activeTab, changeActiveTab } = props;
  
  return (
    <li className={name === activeTab ? "is-active" : ""} onClick={(e) => changeActiveTab(name, e)}>
      <a>
        <span>{name}</span>
      </a>
    </li>
  );
}


export default Tab;