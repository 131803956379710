import React from "react";
import {Route} from "react-router-dom";

const AppRoute = ({component: Component, layout: Layout, setNotification, addToCart, removeFromCart, products, user, setUserState, ...rest}) => (
  <Route 
    {...rest}
    render={props => (
      <Layout>
        <Component setNotification={setNotification} addToCart={addToCart} removeFromCart={removeFromCart} products={products} user={user} setUserState={setUserState} {...props}/>
      </Layout>
    )}
  />
)


export default AppRoute;
