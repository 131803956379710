import React from 'react';

const Arrows = props => {
  let { percent } = props;

  let fluidDashoffset1 = 494.801
  let fluidDashoffset1a = 17.678
  const strokeDashoffset1 = "494.801"
  const strokeDashoffset1a = "17.678"  

  if (percent === 1.5) {
    fluidDashoffset1 = fluidDashoffset1a = 0
  } else {
    fluidDashoffset1 = Math.max(0, 494.801 - (494.801 * percent))
    if (percent > 0.99) {
      fluidDashoffset1a = Math.max(0, 17.678 - (17.678 * percent))
    }
  }
  
  return (
    <svg width="720px" height="153px" viewBox="0 0 912 153" version="1.1" xmlns="http://www.w3.org/2000/svg">
      <g stroke="#1EC95B" strokeWidth="4" strokeLinecap="round" fill="none" fillRule="evenodd">
          <g id="arrow-1" transform="translate(2.5, 1.5)">
              <path d="M376.5,0.5 L376.5,75.5 C376.5,86.5 367.5,95.5 356.5,95.5 C349.166667,95.5 241.166667,95.5 32.5,95.5 C21.5,95.5 12.4999497,104.5 12.4999497,115.5 C12.4999497,122.833333 12.4999497,133.833333 12.4999497,148.5" strokeDasharray={strokeDashoffset1} strokeDashoffset={strokeDashoffset1} style={{"strokeDashoffset": fluidDashoffset1}}></path>
              <line x1="12.5" y1="149.5" x2="25" y2="137" strokeDasharray={strokeDashoffset1a} strokeDashoffset={strokeDashoffset1a} style={{"strokeDashoffset": fluidDashoffset1a}}></line>
              <line x1="12.5" y1="149.5" x2="0" y2="137" strokeDasharray={strokeDashoffset1a} strokeDashoffset={strokeDashoffset1a} style={{"strokeDashoffset": fluidDashoffset1a}}></line>
          </g>
          <g id="arrow-3" transform="translate(533, 1.5)">
              <path d="M0,0.5 L0,75.5 C0,86.5 9,95.5 20,95.5 C27.3333333,95.5 135.333333,95.5 344,95.5 C355,95.5 364.00005,104.5 364.00005,115.5 C364.00005,122.833333 364.00005,133.833333 364.00005,148.5" strokeDasharray={strokeDashoffset1} strokeDashoffset={strokeDashoffset1} style={{"strokeDashoffset": fluidDashoffset1}}></path>
              <line x1="364.5" y1="149.5" x2="377" y2="137" strokeDasharray={strokeDashoffset1a} strokeDashoffset={strokeDashoffset1a} style={{"strokeDashoffset": fluidDashoffset1a}}></line>
              <line x1="364.5" y1="149.5" x2="352" y2="137" strokeDasharray={strokeDashoffset1a} strokeDashoffset={strokeDashoffset1a} style={{"strokeDashoffset": fluidDashoffset1a}}></line>
          </g>
      </g>
    </svg>    
  );
}


export default Arrows