import React from 'react';
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import InjectedCheckoutForm from "./InjectedCheckoutForm";
import {centsToDollars} from './Utils'
import { config } from './Constants'

const stripePromise = loadStripe(`${config.url.STRIPE_KEY}`);

class CheckoutPage extends React.Component { 
  constructor(props) {
    super(props);
  }

  render() {
    const {products, removeFromCart} = this.props
    return (
      <section className="section">
        <div className="container">
          <h1 className="title">
            Checkout
          </h1>        
          <Elements stripe={stripePromise}>
            <InjectedCheckoutForm products={products} removeFromCart={removeFromCart} history={this.props.history}/>
          </Elements>
        </div>
      </section>
    );
  }
}

export default CheckoutPage;