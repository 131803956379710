import React, { useState } from 'react';
import { updatePasswordChange } from './updatePasswordChange'
import PasswordStrength from "./PasswordStrength";
import { useForm } from 'react-hook-form'

// We use the Map construct for checkedTags for flexibility and convenience of setting and getting values
const PasswordChange = props => {
  const { register, errors, getValues, handleSubmit, reset } = useForm();

  const [password, setPassword] = useState(""); 
  const [new_password, setNewPassword] = useState("");  
  const [new_password_confirmation, setNewPasswordConfirmation] = useState(""); 
  const [myErrors, setMyErrors] = useState({});

  const onSubmitPasswordChange = data => {
    const request = {"id": props.id, "password": data.password, "new_password": data.new_password, "new_password_confirmation": data.new_password_confirmation};
    const history = props.history
    updatePasswordChange(request)
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors['responseErrors'] = res.error
        setMyErrors(myErrors)
      }else {
        history.push("/dashboard")
        props.setNotification({notice: "Your password has been updated."})
      }
    })
  }   

  return (
    <div className="columns">
      <div className="column is-one-third">
        <form onSubmit={handleSubmit(onSubmitPasswordChange)}>
          <div className="field">
            <PasswordStrength 
              custref={register({ 
                required: 'Password is required',
                validate: {
                  isMediumStrength: (value) => {
                    return value.match(/^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/) || 'Password must include 1 lowercase letter, 1 uppercase letter, 1 number, and be 8 or more characters.'
                  },
                }
              })}                 
              onChange={e => setNewPassword(e.target.value)} 
              errorClass={errors.new_password ? "is-danger input" : "input"}
              name="new_password"
              label="New Password">
            </PasswordStrength>
            {errors.new_password && (
              <p className="help is-danger">{errors.new_password.message}</p>
            )}                              
          </div>
          <div className="field">
            <label htmlFor="new_password_confirmation" className="label">New Password Confirmation*</label>
            <input
            ref={register({
              required: 'Please confirm password!',
              validate: {
                matchesPreviousPassword: (value) => {
                  const { new_password } = getValues();
                  return new_password === value || 'Passwords should match!';
                },
              }
            })}              
            name="new_password_confirmation" id="new_password_confirmation" type="password" className={errors.new_password_confirmation ? "is-danger input" : "input"} onChange={e => setNewPasswordConfirmation(e.target.value)} />
            {errors.new_password_confirmation && (
              <p className="help is-danger">{errors.new_password_confirmation.message}</p>
            )}                              
          </div>
          <div className="field">
            <label htmlFor="password" className="label">Current Password*</label>
            <input name="password" ref={register({ required: 'Password is required'})} id="password" type="password" className="input" onChange={e => setPassword(e.target.value)} />                              
            {errors.password && (
              <p className="help is-danger">{errors.password.message}</p>
            )}
          </div>                                       
          <div className="field">
            <div className="control">
              <button type="submit" className="button is-link">Update</button>
            </div>
            {myErrors.responseErrors &&
              <p className="help is-danger">{myErrors.responseErrors}</p>
            }              
          </div>
        </form>
      </div>
    </div>
  );     
}
export default PasswordChange;