import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';
const Stopwatch = (props) => {

  const time_spent = props.time_spent;
  let seconds = ("0" + (Math.floor(time_spent / 1000) % 60)).slice(-2);
  let minutes = ("0" + (Math.floor(time_spent / 60000) % 60)).slice(-2);
  let hours = ("0" + Math.floor(time_spent / 3600000)).slice(-2);    
  return (
    <div className="tags has-addons">
      <span className="tag timer_content">
        {minutes}:{seconds}
      </span>
      {props.hideTimerControl === false &&
        <>
          {props.timerOn === false && props.time_spent === 0 && (
            <span className="tag is-dark pause_play" onClick={props.startTimer}>
              <FontAwesomeIcon icon={faPlay} size='xs'/>
            </span>
          )}
          {props.timerOn === true && (
            <span className="tag is-dark pause_play" onClick={props.stopTimer}>
              <FontAwesomeIcon icon={faPause} size='xs'/>
            </span>
          )}
          {props.timerOn === false && props.time_spent > 0 && (
            <span className="tag is-dark pause_play" onClick={props.startTimer}>
              <FontAwesomeIcon icon={faPlay} size='xs'/>
            </span>
          )}
        </>             
      }
    </div>
  );
}
export default Stopwatch;