import React, { Component } from "react";
import Option from "./Option";
import Tutor from "./Tutor";
import Latex from "./Latex";

const studentProducedResponse = "Student-produced response";

class QuestionModal extends Component {

  componentDidMount() {
    window.ensureMathJax();
  }  
  
  componentDidUpdate(prevProps) {
    if (prevProps.value !== this.props.value) {
      window.ensureMathJax();
    }
  }

  render() {
    const { value, showModal, id, closeModal } = this.props;
    const isGridIn = value.question_type === studentProducedResponse;

    const answerExplanation = value.answer_explanation;
    const timeSpent = Number((value.time_spent / 1000)).toFixed();
    const percentageCorrect = (value.question_percent_correct_average * 100).toFixed();
    let correctOption;

    if (value.question_type === studentProducedResponse) {
      correctOption = value.correct_grid_in_answer;
    } else {
      correctOption = value.options.find(option => option.correct === true).letter;
    }

    return (
      <Latex>
        <div className={showModal === id ? "modal is-active" : "modal"}>
          <div className="modal-background" onClick={closeModal}></div>
          <div className="modal-content">
            <div className="box">
              <div className="level">
                <h1 className="is_secondary_font question_content_wrapper content">
                  <div dangerouslySetInnerHTML={{ __html: value.content }}></div>
                </h1>
              </div>
              {!isGridIn && (
                <div className="options_wrapper is_secondary_font">
                  {value.options
                    .sort((a, b) => (a.letter > b.letter ? 1 : -1))
                    .map((option, index) => {
                      return (
                        <div key={option.id} className="field">
                          <div className="control">
                            <Option
                              readOnly={true}
                              disabled={true}
                              id={index}
                              isSelected={
                                value.answer_attributes && value.answer_attributes.letter === option.letter
                              }
                              label={option.content}
                              correctOption={correctOption}
                              value={option.letter}
                            />
                          </div>
                        </div>
                      );
                    })}
                </div>
              )}
              {answerExplanation && (
                <Tutor
                  answerExplanation={answerExplanation}
                  correctOption={correctOption}
                  timeSpent={timeSpent}
                  percentageCorrect={percentageCorrect}
                />
              )}
            </div>
          </div>
          <button className="modal-close is-large" aria-label="close" onClick={closeModal}></button>
        </div>
      </Latex>
    );
  }
}

export default QuestionModal;