export const handleResponse = (response) => {
  let contentType = response.headers.get('content-type')
  if (contentType.includes('application/json')) {
    return handleJSONResponse(response)
  } else if (contentType.includes('text/html')) {
    return handleTextResponse(response)
  } else {
    // Other response types as necessary. I haven't found a need for them yet though.
    throw new Error(`Sorry, content-type ${contentType} not supported`)
  }
};

export const handleJSONResponse = (response) => {
  return response.json()
  .then(json => {
    if (response.ok) {
      return json
    } else {
      return Promise.reject(Object.assign({}, json, {
        status: response.status,
        statusText: response.statusText
      }))
    }
  })
}
export const handleTextResponse = (response) => {
  return response.text()
  .then(text => {
    if (response.ok) {
      return text
    } else {
      return Promise.reject({
        status: response.status,
        statusText: response.statusText,
        err: text
      })
    }
  })
}

export const centsToDollars = (cents) => {
  var dollars = cents / 100;
  dollars = dollars.toLocaleString("en-US", {style:"currency", currency:"USD"});
  return dollars
}

export const jwtValid = (token) => {
  const jwt = require('jsonwebtoken')
  if (token) {
    var decoded = jwt.decode(token, {complete: true});
    const now = Date.now().valueOf() / 1000
    if (typeof decoded.payload.exp !== 'undefined' && decoded.payload.exp < now) {
      return false
    }
    return true
  } else {
    return false
  }
}

export const capSentence = (text) => {
  let wordsArray = text.toLowerCase().split(' ')
  let capsArray = wordsArray.map(word=>{
      return word[0].toUpperCase() + word.slice(1)
  })
  return capsArray.join(' ')
}
