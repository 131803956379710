import React from "react";
import ImgWithFallback from './ImgWithFallback';
import eric_oh from '../images/EricOh.webp';
import eric_oh_png from '../images/EricOh.png';

const About = (props) => {

  return (
    <>
      <section className="hero is-medium about_hero">
        <div className="hero-body">
          <div className="container">
            <h1 className="title is-1 is-spaced">
              Mission
            </h1>
            <h2 className="subtitle">To help learners of all backgrounds reach their academic and professional goals by enhancing proven test prep techniques with AI technology.</h2>
          </div>
        </div>
      </section>    
      <section className="section pb-144">          
        <div className="container">
          <div className="columns">
            <div className="column is-8">
              <h1 className="title is-spaced">
                Meet the founder
              </h1>
              <h2 className="subtitle">A high-quality education helped me pursue my interests and achieve my goals. I started Prepform so students of all backgrounds would have access to the same kind of education.</h2>
              <h2 className="subtitle">I grew up in Southern California, surrounded by dozens of SAT prep programs, and I must have gone to all of them. Different programs followed different styles and techniques, but the strategy they shared was to create a study plan and review mistakes.</h2>
              <h2 className="subtitle">A study plan is:</h2>
              <div className="content">
                <ul>
                  <li><p className="subtitle">taking a diagnostic test,</p></li>
                  <li><p className="subtitle">setting a target score,</p></li>
                  <li><p className="subtitle">creating a study schedule,</p></li>
                  <li><p className="subtitle">identifying mistakes, and finally,</p></li>
                  <li><p className="subtitle">reviewing those mistakes.</p></li>
                </ul>
              </div>
              <h2 className="subtitle">I wanted to take this structure and optimize it with machine learning, while accounting for elements of human learning and memory.</h2>
              <h2 className="subtitle">I'm a big fan of SuperMemo, a memorization technique developed by Piotr Wozniak, where you review material just as you're about to forget it. Cognitive psychology tells us human forgetting follows a pattern, but Piotr quantified this behavior to identify the precise moment forgetting happens.</h2>
              <h2 className="subtitle">The goal was to build on his research with AI and tailor it to not only test prep but to each student, and make it the engine of the study plan.</h2>
              <h2 className="subtitle">The result is Blend AI, which guides students to internalize knowledge rather than memorize material, and gives them the best chance to ace their next exam.</h2>
              <h2 className="subtitle">
                <span>Eric Oh</span>
                <br/>
                <span>Founder and Lead Developer</span>
                <br/>
                <span>Prepform</span>
              </h2>
            </div>
            <div className="column is-4">      
              <ImgWithFallback
                src={eric_oh}
                fallback={eric_oh_png}
                alt="Photo of Prepform founder, Eric Oh"
              />
            </div>          
          </div>
        </div>
      </section>         
    </>
  );
}

export default About;