import React from 'react';
import Loading from "./Loading";
import {handleResponse} from './Utils'
import MistakeQuestion from "./MistakeQuestion";

// We use the Map construct for checkedTags for flexibility and convenience of setting and getting values
class EditMistake extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      myErrors: {
        newMistake: ''
      },          
    };      

    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitFromTest = this.onSubmitFromTest.bind(this);
    this.stripHtmlEntities = this.stripHtmlEntities.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleCancelFromTest = this.handleCancelFromTest.bind(this);
  }

  // Get tags
  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt")
    let id
    // This checks if updating from test where we pass mistakeId
    {this.props.mistakeId
      ?
        id = this.props.mistakeId
      :
        { match: { params: { id } } } = this.props;
    }
    fetch(`/api/v1/mistakes/${id}`, { method: 'GET', headers: {'Authorization': token }})
    .then((mistake) => handleResponse(mistake))
    .then(response => this.setState({ ...response, isLoaded: true }))     
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }

  stripHtmlEntities(str) {
    return String(str)
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  onSubmit(event) {
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem("jwt")
    let id = this.state.id

    fetch(`/api/v1/mistakes/${id}`, {
      method: 'put',
      body: JSON.stringify(this.state),
      headers: { "Authorization": token, 'Content-Type': 'application/json' },
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })    
    .then((response) => {
      this.props.history.push("/mistakes");
    })
    .catch(error => console.log('error', error));
  }

  onSubmitFromTest(event) {
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem("jwt")
    let id = this.props.mistakeId

    fetch(`/api/v1/mistakes/${id}`, {
      method: 'put',
      body: JSON.stringify(this.state),
      headers: { "Authorization": token, 'Content-Type': 'application/json' },
    })
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors.newMistake = res.error
        this.setState({myErrors: myErrors})
      } else {
        // This closes the Create Flashcard box in EditTest
        this.props.handleCancel()
        this.props.showAlert()
      }
    })
    .catch(error => console.log('error', error));
  }  

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleCancel(event) {
    this.props.history.push("/mistakes");
  }

  handleCancelFromTest(event) {
    event.preventDefault()
    this.props.handleCancel(event)
  }   

  handleDelete(event) {
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem("jwt")
    let id = this.state.id

    fetch(`/api/v1/mistakes/${id}`, {
      method: 'DELETE',
      body: JSON.stringify(this.state),
      headers: { "Authorization": token, 'Content-Type': 'application/json' },
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })    
    .then((response) => {
      this.props.history.push("/mistakes");
    })
    .catch(error => console.log('error', error));
  }       

  render() {
    const {myErrors, identify, solution, clue, notes, question } = this.state
    const {topic} = this.props.user
    if (this.state.isLoaded === false) {
      return (
        <Loading/>    
      )
    } else {    
      return (
        <>
          {this.props.fromTest
            ? <>
                <form onSubmit={this.onSubmitFromTest}>
                  {myErrors.newMistake && 
                    <p className='help is-danger'>{myErrors.newMistake}</p>}      
                  <div className="columns">
                    <div className="column">                   
                      <div className="field">
                        <label className="label"> 
                          What did I do wrong?
                        </label>              
                        <div className="control">
                          <textarea className="textarea" rows="1" name="identify" value={identify || ""} onChange={this.handleChange}></textarea>                                                 
                        </div>
                      </div>
                      <div className="field">
                        <label className="label"> 
                          What should I have done instead?
                        </label>              
                        <div className="control">
                          <textarea className="textarea" rows="1" name="solution" value={solution || ""} onChange={this.handleChange}></textarea>                                                
                        </div>
                      </div>
                      <div className="field">
                        <label className="label"> 
                          What is the clue in this question that I missed?
                        </label>              
                        <div className="control">
                          <textarea className="textarea" rows="1" name="clue" value={clue || ""} onChange={this.handleChange}></textarea>                                                
                        </div>
                      </div>
                      <div className="field">
                        <label className="label"> 
                          Notes
                        </label>              
                        <div className="control">
                          <textarea className="textarea" rows="1" name="notes" value={notes || ""} onChange={this.handleChange}></textarea>                                                
                        </div>
                      </div>                                                                 
                    </div>
                  </div>
                  <div className="field">
                    <div className="buttons">
                      <button type="submit" className="button is-dark">Update</button>
                      <button className="button is-light" onClick={this.handleCancelFromTest}>Cancel</button>
                    </div>
                  </div>
                </form>
              </>            
            : <>        
                <h1 className="title mb-0">
                  Edit Mistake
                </h1>
                <p className="tertiary_gray mb-5">{topic}</p>            
                <div className="box">
                  <MistakeQuestion question={question}/>
                </div>
                <div className="box">
                  <form onSubmit={this.onSubmit}>    
                    <div className="columns">
                      <div className="column">
                        <div className="field">
                          <label className="label"> 
                            What did I do wrong?
                          </label>              
                          <div className="control">
                            <textarea className="textarea" rows="2" name="identify" value={identify || ""} onChange={this.handleChange}></textarea>                                                 
                          </div>
                        </div>
                        <div className="field">
                          <label className="label"> 
                            What should I have done instead?
                          </label>              
                          <div className="control">
                            <textarea className="textarea" rows="2" name="solution" value={solution || ""} onChange={this.handleChange}></textarea>                                                
                          </div>
                        </div>
                        <div className="field">
                          <label className="label"> 
                            What is the clue in this question that I missed?
                          </label>              
                          <div className="control">
                            <textarea className="textarea" rows="2" name="clue" value={clue || ""} onChange={this.handleChange}></textarea>                                                
                          </div>
                        </div>
                        <div className="field">
                          <label className="label"> 
                            Notes
                          </label>              
                          <div className="control">
                            <textarea className="textarea" rows="6" name="notes" value={notes || ""} onChange={this.handleChange}></textarea>                                                
                          </div>
                        </div>                                            
                      </div>
                    </div>
                    <div className="button_wrapper">
                      <div className="field">
                        <div className="buttons">
                          <button type="submit" className="button is-dark">Update</button>
                          <button className="button is-light" onClick={this.handleCancel}>Cancel</button>
                        </div>
                      </div>
                      <div className="field">
                        <div className="control">
                          <button className="button is-danger" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this entry?')) this.handleDelete(e) } }>Delete</button>
                        </div>
                      </div>
                    </div>            
                    {myErrors.newMistake && 
                      <div className="control">
                        <p className='help is-danger'>{myErrors.newMistake}</p>
                      </div>
                    }
                  </form>
                </div>
              </>
          }
        </>
      );
    }    

  }      
}

export default EditMistake;