const prod = {
  url: {
    STRIPE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID
  }
};
const dev = {
  url: {
    STRIPE_KEY: process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY,
    GA_TRACKING_ID: process.env.REACT_APP_GA_TRACKING_ID_SANDBOX
  }
};

export const config = process.env.NODE_ENV === 'development' ? dev : prod;