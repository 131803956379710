import React, { useState } from 'react';
import { updatePasswordReset } from './updatePasswordReset'
import PasswordStrength from "./PasswordStrength";
import { useForm } from 'react-hook-form'

// We use the Map construct for checkedTags for flexibility and convenience of setting and getting values
const PasswordReset = props => {
  const { register, errors, getValues, handleSubmit, reset } = useForm();

  const [password, setPassword] = useState("");  
  const [password_confirmation, setPasswordConfirmation] = useState("");  
  const [myErrors, setMyErrors] = useState({});

  const onSubmit = data => {
    // We retrieve the token from the URL
    let token = new URLSearchParams(window.location.search).get("token")
    const request = {"password": data.password, "password_confirmation": data.password_confirmation, "token": token};
    updatePasswordReset(request)
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors['responseErrors'] = res.error
        setMyErrors(myErrors)
      }else {
        props.history.push("/");
      }
    })
  } 


  return (
    <section className="section">
      <div className="columns">
        <div className="column is-one-third">
          <h1 className="title">Change Your Password</h1>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="field">
              <PasswordStrength 
                custref={register({ 
                  required: 'Password is required',
                  validate: {
                    isMediumStrength: (value) => {
                      return value.match(/^.*(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,}).*$/) || 'Password must include 1 lowercase letter, 1 uppercase letter, 1 number, and be 8 or more characters.'
                    },
                  }                
                })}                 
                onChange={e => setPassword(e.target.value)} 
                errorClass={errors.password ? "is-danger input" : "input"}
                name="password"
                label="Password">
              </PasswordStrength>
              {errors.password && (
                <p className="help is-danger">{errors.password.message}</p>
              )}                              
            </div>
            <div className="field">
              <label htmlFor="password_confirmation" className="label">Password Confirmation:</label>
              <input
              ref={register({
                required: 'Please confirm password!',
                validate: {
                  matchesPreviousPassword: (value) => {
                    const { password } = getValues();
                    return password === value || 'Passwords should match!';
                  },
                }
              })}              
              name="password_confirmation" id="password_confirmation" type="password" className={errors.password_confirmation ? "is-danger input" : "input"} onChange={e => setPasswordConfirmation(e.target.value)} />
              {errors.password_confirmation && (
                <p className="help is-danger">{errors.password_confirmation.message}</p>
              )}                              
            </div>                      
            <div className="field">
              <div className="control">
                <div className="buttons">
                  <button type="submit" className="button is-link">Update</button>
                </div>
              </div>
              {myErrors.responseErrors &&
                <p className="help is-danger">{myErrors.responseErrors}</p>
              }              
            </div>          
          </form>           
        </div>
      </div>
    </section>
  );      
}
export default PasswordReset;