import React from 'react';
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot} from '@fortawesome/free-solid-svg-icons';
import { faSmile, faFrown, faMeh } from '@fortawesome/free-regular-svg-icons';
import MistakeQuestion from "./MistakeQuestion";
import Loading from "./Loading";
import {handleResponse} from './Utils'
import moment from 'moment/moment.js';

// We use the Map construct for checkedTags for flexibility and convenience of setting and getting values
class Mistake extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      showModal: false,
      showAnswerHistory: false,
      showTutor: false,
      myErrors: {
        newMistake: ''
      },          
    };
    this.stripHtmlEntities = this.stripHtmlEntities.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt")
    const { match: { params: { id } } } = this.props;
    fetch(`/api/v1/mistakes/${id}`, { method: 'GET', headers: {'Authorization': token }})
    .then((mistake) => handleResponse(mistake))
    .then(response => this.setState({ ...response, isLoaded: true }))
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }

  stripHtmlEntities(str) {
    return String(str)
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  toggleModal(id, e) {
    e.preventDefault();
    const showModal = this.state.showModal === id ? null : id
    this.setState({showModal: showModal});
  };

  expandSection(e) {
    e.preventDefault();
    let target_name = e.target.name
    this.setState(prevState => ({
      [target_name]: !prevState[target_name]
    }));
  }

  submitPerformanceRating(rating, event) {
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem("jwt")
    let {id, next_mistake} = this.state

    fetch(`/api/v1/mistakes/${id}`, {
      method: 'put',
      body: JSON.stringify({mistake: {performance_rating: rating}}),
      headers: { "Authorization": token, 'Content-Type': 'application/json' },
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })    
    .then((response) => {
      if (next_mistake) {
        this.props.history.push(`/mistakes/${next_mistake.id}`);
        this.setState({isLoaded: false}, () => this.componentDidMount());   
      } else {
        this.props.history.push("/dashboard");
      }
    })
    .catch(error => console.log('error', error));
  }           

  render() {
    const {myErrors, id, identify, solution, clue, notes, question, rating_again, rating_hard, rating_good, rating_easy, status } = this.state
    if (this.state.isLoaded === false) {
      return (
        <Loading/>    
      )
    } else {    
      return (
        <>
          <div className="button_wrapper flashcards_level">
            <h1 className="title">Mistake</h1>
            <div className="buttons">
              <Link to={`/mistakes/${id}/edit`} className="button is-primary">Edit Mistake</Link>
            </div>
          </div>
          <div className="box">
            <MistakeQuestion question={question}/>
          </div>
          <div className="box">     
            <div className="columns">
              <div className="column">
                <div className="block">
                  <strong> 
                    What did I do wrong?
                  </strong>
                  <br/>
                  {identify}
                </div>
                <div className="block">
                  <strong>
                    What should I have done instead?
                  </strong>
                  <br/>
                  {solution}
                </div>
                <div className="block"> 
                  <strong>What is the clue in this question that I missed?</strong>
                  <br/>
                  {clue}
                </div>
                <div className="block">
                  <strong>Notes</strong>
                  <br/>
                  {notes}
                </div>
                {/*
                <div className="ai_recommendation message">
                  <span className="icon">
                    <FontAwesomeIcon icon={faRobot}/>
                  </span>          
                  <div>              
                    <p className="title is-5 has-text-weight-bold">
                      AI Recommendation
                    </p>
                    <p className="subtitle is-6">
                      {`Review in ${moment(rating_medium).diff(moment(), 'days')} days`}
                    </p>
                  </div>
                </div>
              */}
                <div className="buttons">
                  <button className="button" onClick={(e) => this.submitPerformanceRating("again", e)} value="again">
                    <span className="icon">
                      <FontAwesomeIcon icon={faFrown}/>
                    </span>
                    <span>{rating_again}</span>
                  </button>
                  {status === "learned" &&
                    <button className="button" onClick={(e) => this.submitPerformanceRating("hard", e)} value="hard">
                      <span className="icon">
                        <FontAwesomeIcon icon={faMeh}/>
                      </span>
                      <span>{rating_hard}</span>
                    </button>                  
                  }
                  <button className="button" onClick={(e) => this.submitPerformanceRating("good", e)} value="good">
                    <span className="icon">
                      <FontAwesomeIcon icon={faMeh}/>
                    </span>
                    <span>{rating_good}</span>
                  </button>
                  {status != "relearning" &&
                    <button className="button" onClick={(e) => this.submitPerformanceRating("easy", e)} value="easy">
                      <span className="icon">
                        <FontAwesomeIcon icon={faSmile}/>
                      </span>
                      <span>{rating_easy}</span>
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>
        </>
      );
    }    

  }      
}

export default Mistake;