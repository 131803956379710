import React, { Component } from 'react';
import '../stylesheets/application.scss';
import Home from "./Home";
import Tests from "./Tests";
import Test from "./Test";
import NewTest from "./NewTest";
import EditTest from "./EditTest";
import Signup from './Signup';
import Login from './Login';
import Logout from './Logout';
import PasswordForgot from './PasswordForgot';
import PasswordReset from './PasswordReset';
import TestAnalysis from './TestAnalysis';
import OverallPerformance from './OverallPerformance';
import Flashcards from './Flashcards';
import NewFlashcard from "./NewFlashcard";
import EditFlashcard from "./EditFlashcard";
import Flashcard from "./Flashcard";
import Mistakes from './Mistakes';
import Mistake from "./Mistake";
import NewMistake from "./NewMistake";
import EditMistake from "./EditMistake";
import Lesson from './Lesson';
import Lessons from './Lessons';
import EditUser from "./EditUser";
import ConfirmUser from "./ConfirmUser";
import ResendConfirmation from "./ResendConfirmation";
import Search from "./Search";
import Orders from './Orders';
import CheckoutPage from './CheckoutPage';
import UpgradePage from './UpgradePage';
import Cart from './Cart';
import ProductStudent from './ProductStudent';
import ProductData from './ProductData';
import ProductCms from './ProductCms';
import ProductSubject from './ProductSubject';
import ProductPrivacy from './ProductPrivacy';
import ProductTerms from './ProductTerms';
import About from './About';
import StudyPlan from './StudyPlan';
import NotFoundPage from './NotFoundPage';
import AppRoute from './AppRoute';
import AppRouteRestricted from './AppRouteRestricted';
import AppRouteSignedIn from './AppRouteSignedIn';
import AppRouteSignedInNotGuest from './AppRouteSignedInNotGuest';
import NavLayout from './NavLayout';
import MenuLayout from './MenuLayout';
import TestLayout from './TestLayout';
import { Router, Switch} from "react-router-dom";
import Layout from "./Layout"
import { createCart } from './createCart'
import { createAuthCart } from './createAuthCart'
import { removeFromCart } from './removeFromCart'
import {handleResponse, jwtValid} from './Utils'
import history from "./history";
import ScrollToTop from "./ScrollToTop";
import {initGA} from './Tracking';
import ReactGA from "react-ga";
import { config } from './Constants'

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {
      products: [],
      setNotification: null,
      user: {},
      isAuthenticating: true
    }
    this.setNotification = this.setNotification.bind(this);
    this.closeNotification = this.closeNotification.bind(this);
    this.addToCart = this.addToCart.bind(this);
    this.removeFromCart = this.removeFromCart.bind(this);
    this.setUserState = this.setUserState.bind(this);
  }

  componentDidMount() {
    const gaTrackingId = config.url.GA_TRACKING_ID
    initGA(gaTrackingId);
    // Initialize google analytics page view tracking
    history.listen(location => {
      ReactGA.set({ page: location.pathname }); // Update the user's current page
      ReactGA.pageview(location.pathname); // Record a pageview for the given page
    });

    if (jwtValid(localStorage.getItem('jwt'))) {
      let token = "Bearer " + localStorage.getItem("jwt")
      const url = "/api/v1/carts/auth-show";
      fetch(url, { method: 'GET', headers: {'Authorization': token }})
      .then((order) => handleResponse(order))
      .then(response => this.setState({ products: response }))     
      .then(response => this.setState({ isAuthenticating: false }))
      .catch(error => {
        history.push("/")
        this.setNotification({error: error})
      })
    } else {
      const url = "/api/v1/carts/show";
      fetch(url, { method: 'GET'})
      .then((order) => handleResponse(order))
      .then(response => this.setState({ products: response }))     
      .then(response => this.setState({ isAuthenticating: false }))
      .catch(error => {
        history.push("/")
        this.setNotification({error: error})
      })      
    }
  }

  // This shows alert message for forbidden pages, such as others' tests. We pass the prop in AppRoute below
  setNotification(message) {
    this.setState({
      setNotification: message
    });
  }

  closeNotification() {
    this.setState({setNotification: null})
  }

  getAlertMessage(notification) {   
    if (notification.error) {
      return(
        <div className={`modal ${notification ? "is-active" : ""}`}>
          <div className="modal-background"></div>
          <div className="modal-content">
            <article className="message notification_message">
              <div className="message-header has-background-danger">
                <p className="title mb-0 has-text-white">Error</p>
                <button className="delete" aria-label="delete" onClick={this.closeNotification}></button>
              </div>
              <div className="message-body">
                {`${notification.error && notification.error.status} ${notification.error && notification.error.statusText}`}
              </div>
            </article>
          </div>
        </div>                 
      )
    }
    if (notification.notice) {
      return(
        <div className={`modal ${notification ? "is-active" : ""}`}>
          <div className="modal-background"></div>
          <div className="modal-content">
            <article className="message notification_message">
              <div className="message-header has-background-info">
                <p className="title mb-0">Notice</p>
                <button className="delete" aria-label="delete" onClick={this.closeNotification}></button>
              </div>
              <div className="message-body">
                {notification.notice}
              </div>
            </article>
          </div>
        </div>             
      )
    }
  }

  addToCart(product_id, event) {
    event.preventDefault();
    const body = { cart: {product_id}  }

    if (jwtValid(localStorage.getItem('jwt'))) {
      createAuthCart(body)
      .then(res => {
        if(res.error) {
          let myErrors = {}
          myErrors.orders = res.error
          this.setState({myErrors: myErrors})
        } else {
          // JSON.stringify because localStorage only accepts strings
          localStorage.setItem('cart', JSON.stringify(res))
          this.setState({products: res})
          history.push("/cart")
        }
      })
    } else {
      createCart(body)
      .then(res => {
        if(res.error) {
          let myErrors = {}
          myErrors.orders = res.error
          this.setState({myErrors: myErrors})
        } else {
          // JSON.stringify because localStorage only accepts strings
          localStorage.setItem('cart', JSON.stringify(res))
          this.setState({products: res})
          history.push("/cart")
        }
      })
    }
  }

  removeFromCart(product_id, event) {
    event.preventDefault();
    const body = { cart: {product_id}  }

    removeFromCart(body)
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors.orders = res.error
        this.setState({myErrors: myErrors})
      } else {
        localStorage.removeItem('cart')
        this.setState({products: res})
      }
    })
    .catch(error => console.log(error.message));
  }

  setUserState(res) {
    this.setState({
      user: {
        id: res.id,
        first_name: res.first_name,
        last_name: res.last_name,
        email: res.email,
        test_date: res.test_date,
        study_start: res.study_start,
        topic: res.topic,
        created_at: res.created_at,
        diagnostic_status: res.diagnostic_status
      }
    });
  }                

  render() {
    const {user} = this.state;
    let products = JSON.parse(localStorage.getItem("cart")) || [];
    let notification = this.state.setNotification
    if (this.state.isAuthenticating) {
      return null
    } else {
      return (       
        <>        
          {notification &&
            this.getAlertMessage(notification)
          }         
          <Layout 
            pageMeta={{
              title: "Home",
              keywords: ["prepform", "test", "prep", "ai", "machine learning", "artificial intelligence", "LMS", "learning", "platform"],
              description: "The learning platform powered by AI."
            }}
          >
          </Layout>      
          <Router history={history}>
            <ScrollToTop />
            <Switch>
              <AppRoute exact path="/" setNotification={this.setNotification} component={Home} layout={NavLayout} products={products} addToCart={this.addToCart}/>
              <AppRoute exact path="/signup" setNotification={this.setNotification} component={Signup} layout={NavLayout} products={products}/>
              <AppRoute exact path="/login" setNotification={this.setNotification} component={Login} layout={NavLayout} products={products} user={user}/>
              <AppRoute exact path="/logout" setNotification={this.setNotification} component={Logout} layout={NavLayout} products={products}/>
              <AppRoute exact path="/forgot-password" setNotification={this.setNotification} component={PasswordForgot} layout={NavLayout} products={products}/>
              <AppRoute exact path="/reset-password" setNotification={this.setNotification} component={PasswordReset} layout={NavLayout} products={products}/>
              <AppRoute exact path="/confirm" setNotification={this.setNotification} component={ConfirmUser} layout={NavLayout} products={products}/>
              <AppRoute exact path="/resend-confirmation" setNotification={this.setNotification} component={ResendConfirmation} layout={NavLayout} products={products}/>
              <AppRoute exact path="/orders" setNotification={this.setNotification} component={Orders} layout={NavLayout} addToCart={this.addToCart} products={products}/>
              <AppRoute exact path="/cart" setNotification={this.setNotification} component={Cart} layout={NavLayout} removeFromCart={this.removeFromCart} products={products}/>
              {/*<AppRoute exact path="/student-driven" setNotification={this.setNotification} component={ProductStudent} layout={NavLayout} products={products}/>*/}
              <AppRoute exact path="/product" setNotification={this.setNotification} component={ProductData} layout={NavLayout} addToCart={this.addToCart} products={products}/>
              <AppRoute exact path="/for-business" setNotification={this.setNotification} component={ProductCms} layout={NavLayout} removeFromCart={this.removeFromCart} products={products}/>              
              <AppRoute exact path="/about" setNotification={this.setNotification} component={About} layout={NavLayout} removeFromCart={this.removeFromCart} products={products}/>
              <AppRoute exact path="/for-students" setNotification={this.setNotification} component={ProductSubject} layout={NavLayout} addToCart={this.addToCart} removeFromCart={this.removeFromCart} products={products} setUserState={this.setUserState}/>
              <AppRoute exact path="/privacy" setNotification={this.setNotification} component={ProductPrivacy} layout={NavLayout} removeFromCart={this.removeFromCart} products={products}/>
              <AppRoute exact path="/terms" setNotification={this.setNotification} component={ProductTerms} layout={NavLayout} removeFromCart={this.removeFromCart} products={products}/>
              <AppRouteSignedIn exact path="/upgrade" setNotification={this.setNotification} component={UpgradePage} layout={NavLayout} addToCart={this.addToCart} products={products} user={user}/>
              <AppRouteSignedInNotGuest exact path="/checkout" setNotification={this.setNotification} component={CheckoutPage} layout={NavLayout} removeFromCart={this.removeFromCart} products={products}/>
              <AppRouteRestricted exact path="/tests" setNotification={this.setNotification} component={Tests} layout={MenuLayout} user={user} setUserState={this.setUserState}/>
              <AppRouteRestricted exact path="/tests/new" setNotification={this.setNotification} component={NewTest} layout={MenuLayout} user={user} setUserState={this.setUserState}/>
              <AppRouteRestricted exact path="/tests/:id/result" setNotification={this.setNotification} component={Test} layout={MenuLayout} user={user} setUserState={this.setUserState}/>
              <AppRouteRestricted exact path="/tests/:id/analysis" setNotification={this.setNotification} component={TestAnalysis} layout={MenuLayout} user={user} setUserState={this.setUserState}/>
              <AppRouteRestricted exact path="/tests/:id" setNotification={this.setNotification} component={EditTest} layout={TestLayout} setUserState={this.setUserState}/>
              <AppRouteRestricted exact path="/dashboard" setNotification={this.setNotification} component={OverallPerformance} layout={MenuLayout} user={user} setUserState={this.setUserState}/>
              {/*<AppRouteRestricted exact path="/study-plan" setNotification={this.setNotification} component={StudyPlan} layout={MenuLayout} user={user} setUserState={this.setUserState}/>*/}
              <AppRouteRestricted exact path="/flashcards" setNotification={this.setNotification} component={Flashcards} layout={MenuLayout} user={user} setUserState={this.setUserState}/>
              <AppRouteRestricted exact path="/flashcards/new" setNotification={this.setNotification} component={NewFlashcard} layout={MenuLayout} user={user} setUserState={this.setUserState}/>
              <AppRouteRestricted exact path="/flashcards/:id" setNotification={this.setNotification} component={Flashcard} layout={MenuLayout} user={user} setUserState={this.setUserState}/>
              <AppRouteRestricted exact path="/flashcards/:id/edit" setNotification={this.setNotification} component={EditFlashcard} layout={MenuLayout} user={user} setUserState={this.setUserState}/>
              <AppRouteRestricted exact path="/mistakes" setNotification={this.setNotification} component={Mistakes} layout={MenuLayout} user={user} setUserState={this.setUserState}/>
              <AppRouteRestricted exact path="/mistakes/new" setNotification={this.setNotification} component={NewMistake} layout={MenuLayout} user={user} setUserState={this.setUserState}/>
              <AppRouteRestricted exact path="/mistakes/:id/edit" setNotification={this.setNotification} component={EditMistake} layout={MenuLayout} user={user} setUserState={this.setUserState}/>
              <AppRouteRestricted exact path="/mistakes/:id" setNotification={this.setNotification} component={Mistake} layout={MenuLayout} user={user} setUserState={this.setUserState}/>
              {/*<AppRouteRestricted exact path="/lessons" setNotification={this.setNotification} component={Lessons} layout={MenuLayout} user={user} setUserState={this.setUserState}/>*/}
              {/*<AppRouteRestricted exact path="/lessons/:id" setNotification={this.setNotification} component={Lesson} layout={MenuLayout} user={user} setUserState={this.setUserState}/>*/}
              {/*<AppRouteRestricted exact path="/search" setNotification={this.setNotification} component={Search} layout={MenuLayout} user={user} setUserState={this.setUserState}/>*/}
              <AppRouteRestricted exact path="/users/:id" setNotification={this.setNotification} component={EditUser} layout={MenuLayout} user={user} setUserState={this.setUserState}/>
              <AppRoute path="*" setNotification={this.setNotification} component={NotFoundPage} layout={NavLayout} removeFromCart={this.removeFromCart} products={products}/>
            </Switch>
          </Router>
        </>
      );      
    }  
  }
}

export default App;