import React from 'react';
import MistakeQuestion from "./MistakeQuestion";
import { createMistake } from './createMistake'
import {handleResponse} from './Utils'
import Loading from "./Loading";

// We use the Map construct for checkedTags for flexibility and convenience of setting and getting values
class NewMistake extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      showModal: false,
      showAnswerHistory: false,
      showTutor: false,
      myErrors: {
        newMistake: ''
      },          
    };      

    this.onSubmit = this.onSubmit.bind(this);
    this.stripHtmlEntities = this.stripHtmlEntities.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.clearState = this.clearState.bind(this);
  }

  // Get tags
  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt")
    // This ensures fetch runs with questionId if props.history is not set and location.search fails
    const query = this.props.location.search
    const url = `/api/v1/mistakes/new${query}`;
    fetch(url, { method: 'GET', headers: {'Authorization': token }})
    .then((mistake) => handleResponse(mistake))
    .then(response => this.setState({ ...response, identify: "", solution: "", clue: "", notes: "", isLoaded: true }))
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }

  stripHtmlEntities(str) {
    return String(str)
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  onSubmit(event) {
    event.preventDefault();
    const { identify, solution, clue, notes, question_id, next_mistake } = this.state;
    const body = { identify, solution, clue, notes, question_id }

    createMistake(body)
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors.newMistake = res.error
        this.setState({myErrors: myErrors})
      } else {
        if (next_mistake) {
          this.props.history.push(`/mistakes/new?question_id=${next_mistake}`);
          this.setState({isLoaded: false}, () => this.componentDidMount());   
        } else {
          this.props.history.push("/dashboard");
        }
      }
    })
    .catch(error => {
      this.props.setNotification({error: error.message}) 
    })
  } 

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleCancel(event) {
    event.preventDefault()
    this.clearState()
    this.props.history.push("/mistakes");
  }

  clearState(){
    this.setState({
      identify: "",
      solution: "",
      clue: "",
      notes: ""
    })    
  }  

  render() {
    const {myErrors, identify, solution, clue, notes, used_questions, question} = this.state
    const {topic} = this.props.user
    if (this.state.isLoaded === false) {
      return (
        <Loading/>     
      )
    } else {
      return (
        <>
          <h1 className="title mb-0">Log Mistake</h1>
          <p className="tertiary_gray mb-5">{topic}</p> 
          <div className="box">
            {question &&
              <MistakeQuestion question={question}/>               
            }
          </div>
          <div className="box">                    
            <form onSubmit={this.onSubmit}>
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label"> 
                      What did I do wrong?
                    </label>              
                    <div className="control">
                      <textarea className="textarea" rows="2" name="identify" value={identify} onChange={this.handleChange}></textarea>                                                 
                    </div>
                  </div>
                  <div className="field">
                    <label className="label"> 
                      What should I have done instead?
                    </label>              
                    <div className="control">
                      <textarea className="textarea" rows="2" name="solution" value={solution} onChange={this.handleChange}></textarea>                                                
                    </div>
                  </div>
                  <div className="field">
                    <label className="label"> 
                      What is the clue in this question that I missed?
                    </label>              
                    <div className="control">
                      <textarea className="textarea" rows="2" name="clue" value={clue} onChange={this.handleChange}></textarea>                                                
                    </div>
                  </div>
                  <div className="field">
                    <label className="label"> 
                      Notes
                    </label>              
                    <div className="control">
                      <textarea className="textarea" rows="6" name="notes" value={notes} onChange={this.handleChange}></textarea>                                                
                    </div>
                  </div>                                            
                </div>
              </div>
              <div className="field">
                <div className="buttons">
                  <button type="submit" className="button is-dark">Create</button>
                  <button className="button is-light" onClick={this.handleCancel}>Cancel</button>
                </div>
                <div className="control">
                  {myErrors.newMistake && 
                    <p className='help is-danger'>{myErrors.newMistake}</p>}                 
                </div>                    
              </div>
            </form>
          </div>
        </>
      );
    }    

  }      
}

export default NewMistake;