import React from 'react';
import Orders from "./Orders";

const UpgradePage = (props) => {
  const {products, addToCart} = props
  const localTopic = localStorage.getItem('localTopic')

  return (
    <section className="section">
      <div className="container">
        <h1 className="title">
          {localTopic}
        </h1>
      </div>
      <Orders topic={localTopic} addToCart={addToCart}/>
    </section>
  );
}

export default UpgradePage;