import React, { Component } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlay, faPause } from '@fortawesome/free-solid-svg-icons';

const Countdown = (props) => {

  const { countdown_time, timerStart, timerOn, startCountdown, stopTimer } = props;
  let seconds = ("0" + (Math.floor((countdown_time / 1000) % 60) % 60)).slice(-2);
  let minutes = ("0" + Math.floor((countdown_time / 60000) % 60)).slice(-2);
  let hours = ("0" + Math.floor((countdown_time / 3600000) % 60)).slice(-2);  
  return (
    <div className="tags has-addons">
      <span className="tag timer_content">
        {minutes}:{seconds}
      </span>
      {props.hideTimerControl === false &&
        <>
          {timerOn === false && (timerStart === 0 || countdown_time === timerStart) && (
            <span className="tag is-dark" onClick={startCountdown}>
              <FontAwesomeIcon icon={faPlay} size='xs'/>
            </span>
          )}
          {timerOn === true && countdown_time >= 1000 && (
            <span className="tag is-dark" onClick={stopTimer}>
              <FontAwesomeIcon icon={faPause} size='xs'/>
            </span>
          )}
          {timerOn === false && (timerStart !== 0 && timerStart !== countdown_time && countdown_time !== 0) && (
            <span className="tag is-dark" onClick={startCountdown}>
              <FontAwesomeIcon icon={faPlay} size='xs'/>
            </span>
          )}
        </>             
      }
    </div>
  );
}
export default Countdown;