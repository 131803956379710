import React from "react";

const LeaveTestModal = props => {    
  return (
    <div className={`modal ${props.showModal === "leave" ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <article className="message is-warning">
          <div className="message-header">
            <p>Warning</p>
            <button className="delete" aria-label="delete" onClick={props.toggleModal}></button>
          </div>
          <div className="message-body">
            <div className="level">
              Do you want to score the test or save without scoring?
            </div>
            <div className="buttons are-small is-right">
              <button className="button is-success" onClick={props.checkSkippedQuestions}>Score Test</button>
              <button className="button" onClick={props.leaveTestAndSave}>Save Without Scoring</button>
              <button className="button" onClick={props.leaveTestWithoutSaving}>Don't Save</button>
            </div>
          </div>
        </article>
      </div>
    </div>
  )
}


export default LeaveTestModal