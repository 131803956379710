export const createDiagnosticTest = (test) => {
  let token = "Bearer " + localStorage.getItem("jwt")
  return fetch("/api/v1/tests/create_diagnostic", {
    method: "POST",
    headers: {
      "Authorization": token,
      "Content-Type": "application/json",
      'Accept': 'application/json'
    },
    body: JSON.stringify(test)
  })
  .then(res => res.json())
}

