import React from "react";
import { Link } from "react-router-dom";

const TimeExpiredModal = props => {    
  return (
    <div className={`modal ${props.showModal === "timeExpired" ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <article className="message is-warning">
          <div className="message-header">
            <p>Warning</p>
          </div>
          <div className="message-body">
            <div className="level">
              Your time expired. 
            </div>
            <div className="buttons are-small is-right">
              <Link to={`/tests/${props.testId}/result`} className="button">Ok</Link>
            </div>
          </div>
        </article>
      </div>
    </div>
  )
}


export default TimeExpiredModal