import React from "react";

const SkippedQuestionsModal = props => {
  const {questions, taken_tracker} = props;
  const skippedQuestions = questions.filter(key => key.skipped === true || key.skipped === null).filter(key => taken_tracker[key.order] !== "taken")

  const skippedList = skippedQuestions.sort((a, b) => (a.order > b.order) ? 1 : -1).map((question) =>
    <p className="control" key={question.order}>
      <a className="button"  onClick={(e) => props.jumpToStep(question.order, e)}>
        {question.order + 1}
      </a>
    </p> 
  );  
  return (
    <div className={`modal ${props.showModal === "confirmSkipped" ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <article className="message is-warning">
          <div className="message-header">
            <p>Warning</p>
            <button className="delete" aria-label="delete" onClick={props.toggleModal}></button>
          </div>
          <div className="message-body">
            <div className="level">
              You skipped questions:
            </div>
            <div className="field is-grouped is-grouped-multiline">
              {skippedList}
            </div>              
            <div className="buttons are-small is-right">
              <button className="button is-success" onClick={props.endTest}>Score Test Anyway</button>
              <button className="button" onClick={props.toggleModal}>Return to Test</button>            
            </div>
          </div>
        </article>
      </div>
    </div>
  )
}


export default SkippedQuestionsModal