export const updateUserTopic = (user) => {
  let token = "Bearer " + localStorage.getItem("jwt")
  return fetch("/api/v1/users/update_topic", {
    method: 'PUT',
    body: JSON.stringify(user),     
    headers: {'Authorization': token,
              'Content-Type': 'application/json',
              'Accept': 'application/json',
    }
  })
}

