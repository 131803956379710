import React, { useLayoutEffect, useRef, useState } from "react";
import DemoEmail from "./DemoEmail"
import ImgWithFallback from './ImgWithFallback';
import cms from '../images/cms.webp';
import cms_png from '../images/cms.png';
import text_editor from '../images/text_editor.webp';
import text_editor_png from '../images/text_editor.png';

const ProductCms = (props) => {
  const [show, doShow] = useState({
    itemOne: false,
    itemTwo: false,
    itemThree: false             
  });


  const refOne = useRef(null),
        refTwo = useRef(null),
        refThree = useRef(null);   
  
  useLayoutEffect(() => {
    const topPos = element => element.getBoundingClientRect().top;

    const div1Pos = topPos(refOne.current),
          div2Pos = topPos(refTwo.current),
          div3Pos = topPos(refThree.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;

      if (div1Pos < scrollPos) {
        doShow(state => ({ ...state, itemOne: true }));
      } 
      if (div2Pos < scrollPos) {
        doShow(state => ({ ...state, itemTwo: true }));
      } 
      if (div3Pos < scrollPos) {
        doShow(state => ({ ...state, itemThree: true }));
      }                                      
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);   

  return (
    <>
      <section className="hero is-medium cms_hero">
        <div className="hero-body">
          <div className="container">
            <div className="block">
              <h1 className="title is-1 is-spaced">
                A new and better way to acquire, engage, and retain today's learners
              </h1>
              <h2 className="subtitle">
                Prepform creates white-label SaaS eLearning solutions with <span className="has-text-primary has-text-weight-bold">Blend AI</span> so your organization reaches the modern learner and grows faster.
              </h2>
            </div>
            <div className="block">
              <DemoEmail size="is-medium" buttonClass="is-black"/>            
            </div>
          </div>
        </div>
      </section>
      <section className="section pb-120">
        <div className="container">
          <div className="block has-text-centered">
            <h1 className="title is-2">AI has changed the learning landscape for good.</h1>
            <h2 className="subtitle is-4 mb-6">It's time for your business to change.</h2>
          </div>
          <div className="block">
            <div className="columns">
              <div className="column has-text-centered">
                <p className="title is-1 mb-0 has-text-primary">84%</p>
                <p className="block">expected impact of AI on profits in the Education Industry</p> 
                <p className="block is-italic">- Accenture Report</p>
              </div>   
              <div className="column has-text-centered">
                <p className="title is-1 mb-0 has-text-primary">70%</p>
                <p className="block">of respondents say new technologies have made it easier for them to take their business elsewhere.</p> 
                <p className="block is-italic">- Salesforce</p>
              </div>                       
              <div className="column has-text-centered">
                <p className="title is-1 mb-0 has-text-primary">$341B</p>
                <p className="block">expected revenue from EdTech by 2025.</p> 
                <p className="block is-italic">- HolonIQ</p>
              </div>
            </div>                              
          </div>
        </div>
      </section>
      <section className="section has-background-dark-green pt-120 pb-120">
        <div className="container block">
          <div className="columns">
            <div className="column is-5 pt-0">
              <h1 className="title is-1 has-text-white">
                Thrive in the era of AI education
              </h1>
            </div>
            <div className="column why_list is-offset-1">
              <article className="message">
                <div className="message-body">
                  <p className="title">Create a new revenue stream</p>
                  <p className="subtitle is_secondary_font">Whether your organization is B2C or B2B, Prepform has delivery options for your end user.</p>
                </div>
              </article>
              <article className="message">
                <div className="message-body">
                  <p className="title">Expand distribution and impact</p>
                  <p className="subtitle is_secondary_font">It's easy to produce and distribute content with Prepform, so you scale your business and keep costs down.</p>
                </div>
              </article>
              <article className="message">
                <div className="message-body">
                  <p className="title">Gain critical insights</p>
                  <p className="subtitle is_secondary_font">Create personalized service strategies using analytics from the integrated CMS.</p>
                </div>
              </article>
              <article className="message">
                <div className="message-body">
                  <p className="title">End-to-end support</p>
                  <p className="subtitle is_secondary_font">Implementation, account management, tech support, and customer support are all included when you partner with Prepform.</p>
                </div>
              </article>              
            </div>
          </div>
        </div>      
      </section>
      <section className="section works_hero has-background-light pt-120 pb-120">
        <div className="container">
          <div className="columns">
            <div className="column has-text-centered">
              <h1 className="title is-1 mb-6">
                So, how does it work?
              </h1>
            </div>
          </div>
          <div className="columns is-desktop">
            <div className="column">
              <div className="content">
                <ol start="1">
                  <li>
                    <div>
                      <h1 className="title is-spaced">
                        Discovery
                      </h1>
                      <p className="subtitle">
                        We want to know your needs and the goals you hope to achieve. 
                      </p>
                    </div>
                  </li>
                </ol>
              </div>                                     
            </div>
            <div className="column">
              <div className="content">
                <ol start="2">
                  <li>
                    <div>
                      <h1 className="title is-spaced">
                        Train
                      </h1>
                      <p className="subtitle">
                        We train our model to your content and users.
                      </p>
                    </div>
                  </li>
                </ol>
              </div>              
            </div>
            <div className="column">
              <div className="content">
                <ol start="3">
                  <li>
                    <div>
                      <h1 className="title is-spaced">
                        Beta
                      </h1>
                      <p className="subtitle">
                        We beta test to verify performance and improve the model.
                      </p>
                    </div>
                  </li>
                </ol>
              </div>              
            </div>            
            <div className="column">
              <div className="content">
                <ol start="4">
                  <li>
                    <div>
                      <h1 className="title is-spaced">
                        Launch
                      </h1>
                      <p className="subtitle">
                        Your platform is styled to your brand and ready for your users.
                      </p>
                    </div>
                  </li>
                </ol>
              </div>              
            </div>                                    
          </div>
        </div>           
      </section>                  
      <section className="section pt-120 has-background-dark-alt">
        <div className="container">
          <div className="columns is-align-items-center">
            <div className="column is-5">
              <p className="tertiary_title has-text-primary">CMS</p>
              <h1 className="title is-spaced has-text-white">Manage your content</h1>
              <h2 className="subtitle is_secondary_font">Create and update content with our intuitive CMS.</h2>
              <h2 className="subtitle is_secondary_font">Add dynamic blocks of content like images, embeds, and Markdown with our rich text editor.</h2>
              <h2 className="subtitle is_secondary_font">Built-in Latex support makes it easy to add mathematical notation.</h2>
            </div>
            <div ref={refOne} className={`column is-offset-1 no_opacity ${show.itemOne ? " add_opacity" : ""}`}>
              <div className="desktop_box">
                <div className="desktop_box_bg"></div>
                <ImgWithFallback
                  className="box"
                  src={text_editor}
                  fallback={text_editor_png}
                  alt="Manage your content"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section has-background-dark-alt">
        <div className="container">
          <div className="columns is-align-items-center is-hidden-mobile">
            <div ref={refTwo} className={`column is-6 no_opacity ${show.itemTwo ? " add_opacity" : ""}`}>
              <div className="desktop_box">
                <div className="desktop_box_bg"></div>
                <ImgWithFallback
                  className="box"
                  src={cms}
                  fallback={cms_png}
                  alt="Grow your business"
                />
              </div>
            </div>            
            <div className="column is-offset-1">
              <p className="tertiary_title has-text-primary">Tools</p>
              <h1 className="title is-spaced has-text-white">Grow your business</h1>
              <h2 className="subtitle is_secondary_font">Use real-time data to analyze your users and your company.</h2>
              <h2 className="subtitle is_secondary_font">Expand your business by tracking growth and traffic.</h2>
              <h2 className="subtitle is_secondary_font">Focus on students by seeing when they practice and how they perform over time.</h2>
            </div>
          </div>
          <div className="columns is-align-items-center is-hidden-tablet">
            <div className="column">
              <p className="tertiary_title has-text-primary">Tools</p>
              <h1 className="title is-spaced has-text-white">Grow your business</h1>
              <h2 className="subtitle is_secondary_font">Use real-time data to analyze your users and your company.</h2>
              <h2 className="subtitle is_secondary_font">Expand your business by tracking growth and traffic.</h2>
              <h2 className="subtitle is_secondary_font">Focus on students by seeing when they practice and how they perform over time.</h2>
            </div>            
            <div ref={refThree} className={`column no_opacity ${show.itemThree ? " add_opacity" : ""}`}>
              <div className="desktop_box">
                <div className="desktop_box_bg"></div>
                <ImgWithFallback
                  className="box"
                  src={cms}
                  fallback={cms_png}
                  alt="Grow your business"
                />
              </div>
            </div>
          </div>          
        </div>
      </section>
      <section className="section pb-120 has-background-dark-alt">
        <div className="container">
          <div className="columns is-variable is-8">
            <div className="column">
              <div className="green_hr"></div>
              <p className="title is-5 has-text-weight-bold has-text-white">
                Work with your team
              </p>
              <p className="subtitle is-6 is_secondary_font">
                Invite the whole team to collaborate with multiple roles and staff accounts.
              </p>              
            </div>
            <div className="column">
              <div className="green_hr"></div>
              <p className="title is-5 has-text-weight-bold has-text-white">
                Build revenue
              </p>
              <p className="subtitle is-6 is_secondary_font">
                Payment support for your gateway, including Stripe, Square, and PayPal.
              </p>              
            </div>
            <div className="column">
              <div className="green_hr"></div>
              <p className="title is-5 has-text-weight-bold has-text-white">
                Stay secure
              </p>
              <p className="subtitle is-6 is_secondary_font">
                Prepform creates isolated applications with SSL and spam protection.
              </p>              
            </div>                        
          </div>          
        </div>
      </section> 
      <section className="section pt-120">
        <div className="container">
          <div className="columns">
            <div className="column pt-0">
              <h1 className="title is-1">
                Made for your company
              </h1>
              <p className="subtitle company_list_complement is_secondary_font">Our modular toolkit makes it easy to plug-and-play the features you need. Whether it's API integration, data collection, or a full-featured learning platform, we make it easy to add an AI tutor to your business.</p>            
            </div>
            <div className="column company_list is-offset-1" id="pricing">
              <p className="title is-4 company_list_item">Predict student knowledge and ability</p>
              <p className="title is-4 company_list_item">Find optimal learning path</p>
              <p className="title is-4 company_list_item">Predict score</p>
              <p className="title is-4 company_list_item">Determine spaced-repetition interval</p>
            </div>
          </div>
        </div>      
      </section>
      <section className="section has-background-white">
        <div className="container has-text-centered">
          <div className="box has-background-gradient p-6">          
            <div className="block">
              <h2 className="title is-2 has-text-white">
                Ready to reach today's learners?
              </h2>
            </div>
            <div className="block">
              <div className="buttons is-justify-content-center">
                <DemoEmail size="is-medium" justify="is-justify-content-center" buttonClass="is-outlined"/>
              </div>                
            </div>
          </div>
        </div>
      </section>                                
    </>
  );
}

export default ProductCms;