import React from "react";
import ReactHtmlParser from 'react-html-parser';

const PassageModal = props => {     
  return (
    <>
      <div className={props.showModal === props.id ? "modal passage_modal is-active" : "modal passage_modal"}>
        <div className="modal-background" onClick={props.closeModal}></div>
        <div className="modal-content">
          <div className="box">
            <div className="level">
              <div className="content">
                {ReactHtmlParser(props.value.content)}
              </div>
            </div>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={props.closeModal}></button>
      </div>
    </>
  )
}


export default PassageModal