import React from "react";

const Loading = props => {
  const { messages } = props;

  const [messageIndex, setMessageIndex] = React.useState(0);

  React.useEffect(() => {
    // Move on to the next message every `n` milliseconds
    let timeout;
    if (messageIndex < messages.length - 1) {
      timeout = setTimeout(() => setMessageIndex(messageIndex + 1), 1500);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [messages, messageIndex]);

  return (
    <div className="centered has-text-centered">
      {/*<FontAwesomeIcon icon={faCircleNotch} spin size="2x" />*/}
      <div className="load_spinner"></div>
      <p>
        {messages[messageIndex]}
      </p>
    </div>
  )
}

Loading.defaultProps = {
  messages: []    
}


export default Loading