import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import QuestionModal from "./QuestionModal";
import ReactPaginate from 'react-paginate';
import moment from 'moment/moment.js'
import {handleResponse} from './Utils'
import Loading from "./Loading";

class Mistakes extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loggedMistakes: [],
      mistakesToLog: [],
      isLoaded: false,
      showQuestion: true,
      showIdentify: true,
      showUpdatedAt: true,
      showSolution: false,
      showClue: false,
      showNotes: false,
      showAction: true,
      showDisplayPanel: false,
      showModal: false,
      itemOffsetMistakesToLog: 0,
      itemOffsetLoggedMistakes: 0,
      pageCountMistakesToLog: 0,
      pageCountLoggedMistakes: 0,
      countMistakesToLog: 0,
      countLoggedMistakes: 0,      
      itemsPerPage: 25
    };
    this.toggleDisplayPanel = this.toggleDisplayPanel.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
    this.handlePageClickMistakesToLog = this.handlePageClickMistakesToLog.bind(this);
    this.handlePageClickLoggedMistakes = this.handlePageClickLoggedMistakes.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt")
    const url = "/api/v1/mistakes/index";
    fetch(url, { method: 'GET', headers: {'Authorization': token }})
    .then((mistake) => handleResponse(mistake))
    .then(response => this.setState({
      loggedMistakes: response.logged_mistakes,
      mistakesToLog: response.mistakes_to_log,
      pageCountMistakesToLog: response.total_questions_to_log_page_count,
      pageCountLoggedMistakes: response.total_logged_mistakes_page_count,
      countMistakesToLog: response.total_questions_to_log_count,
      countLoggedMistakes: response.total_logged_mistakes_count,  
      itemsPerPage: response.items_per_page,
      isLoaded: true 
    }))     
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }

  toggleDisplayPanel() {
    this.setState(prevState => ({
      showDisplayPanel: !prevState.showDisplayPanel
    }));    
  }  

  toggleVisibility(event) {
    event.persist();
    this.setState(prevState => ({ 
      [event.target.name]: !prevState[event.target.name]
    }));
  }


  toggleModal(id, e) {
    e.preventDefault()
    const showModal = this.state.showModal === id ? null : id
    this.setState({showModal: showModal});
  };

  // Invoke when user click to request another page.
  handlePageClickMistakesToLog(event){
    const {mistakesToLog, itemOffsetMistakesToLog, pageCountMistakesToLog, itemsPerPage, countMistakesToLog} = this.state
    const page = event.selected

    const newOffsetToLog = (page * itemsPerPage) % countMistakesToLog;

    let token = "Bearer " + localStorage.getItem("jwt")
    const url = `/api/v1/mistakes/index?newOffsetToLog=${newOffsetToLog}&itemsPerPage=${itemsPerPage}`;
    fetch(url, { method: 'GET', headers: {'Authorization': token }})
    .then((mistake) => handleResponse(mistake))
    .then(response => this.setState({
      mistakesToLog: response.mistakes_to_log,
      itemOffsetMistakesToLog: newOffsetToLog,
      pageCountMistakesToLog: response.total_questions_to_log_page_count,
    })) 
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })      
  };

  handlePageClickLoggedMistakes(event){
    const {loggedMistakes, itemOffsetLoggedMistakes, pageCountLoggedMistakes, itemsPerPage, countLoggedMistakes} = this.state
    const page = event.selected

    const newOffsetLoggedMistakes = (page * itemsPerPage) % countLoggedMistakes;

    let token = "Bearer " + localStorage.getItem("jwt")
    const url = `/api/v1/mistakes/index?newOffsetLoggedMistakes=${newOffsetLoggedMistakes}&itemsPerPage=${itemsPerPage}`;
    fetch(url, { method: 'GET', headers: {'Authorization': token }})
    .then((mistake) => handleResponse(mistake))
    .then(response => this.setState({
      loggedMistakes: response.logged_mistakes,
      itemOffsetLoggedMistakes: newOffsetLoggedMistakes,    
      pageCountLoggedMistakes: response.total_logged_mistakes_page_count
    }))
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })      
  };   


  render() {
    const {loggedMistakes, mistakesToLog, showQuestion, showIdentify, showUpdatedAt, showSolution, showClue, showNotes, showAction, showDisplayPanel, showModal, itemOffsetMistakesToLog, itemOffsetLoggedMistakes, pageCountMistakesToLog, pageCountLoggedMistakes, itemsPerPage} = this.state
    const {topic} = this.props.user   

    const loggedMistakesTable = loggedMistakes.map((card, index) => (
      <tr key={index}>
        <td></td>
        {showQuestion &&
          <td>
            <a href="#" onClick={(e) => this.toggleModal(card.question_id, e)}>{card.question.id}</a>
          </td>
        }        
        {showIdentify &&
          <td>{card.identify}</td>
        }
        {showUpdatedAt &&
          <td>{moment(card.updated_at).format('MMMM Do YYYY, h:mm')}</td>
        }        
        {showSolution &&
          <td>{card.solution}</td>
        }
        {showClue &&
          <td>{card.clue}</td>
        }
        {showNotes &&
          <td>{card.notes}</td>
        }
        {showAction &&
          <td>
            <Link to={`/mistakes/${card.id}/edit`}>Edit</Link>
          </td>
        }
        <td>
          <QuestionModal index={index} value={card.question} id={card.question_id} showModal={showModal} closeModal={(e) => this.toggleModal(card.question_id, e)}/>
        </td>               
      </tr>
    ));

    const mistakesToLogTable = mistakesToLog.map((card, index) => (
      <tr key={index}>
        <td>
          <a href="#" onClick={(e) => this.toggleModal(card.question.id, e)}>{card.question.id}</a>
        </td>     
        <td>
          <Link to={`/mistakes/new?question_id=${card.question.id}`}>Log Mistake</Link>
        </td>
        <td>
          <QuestionModal index={index} value={card.question} id={card.question.id} showModal={showModal} closeModal={(e) => this.toggleModal(card.question.id, e)}/>
        </td>               
      </tr>
    ));

    if (this.state.isLoaded === false) {
      return (
        <Loading/>     
      )
    } else {
      return (
        <>
          <h1 className="title mb-0">Mistake Journal</h1>
          <p className="tertiary_gray mb-5">{topic}</p>        
          {showDisplayPanel &&
            <nav className="panel display_panel">
              <label className="panel-block">
                <input type="checkbox" checked={showQuestion} name="showQuestion" onChange={this.toggleVisibility}/>
                Question
              </label>              
              <label className="panel-block">
                <input type="checkbox" checked={showIdentify} name="showIdentify" onChange={this.toggleVisibility}/>
                Mistake
              </label>
              <label className="panel-block">
                <input type="checkbox" checked={showUpdatedAt} name="showUpdatedAt" onChange={this.toggleVisibility}/>
                Updated
              </label>               
              <label className="panel-block">
                <input type="checkbox" checked={showSolution} name="showSolution" onChange={this.toggleVisibility}/>
                Solution
              </label>
              <label className="panel-block">
                <input type="checkbox" checked={showClue} name="showClue" onChange={this.toggleVisibility}/>
                Clue
              </label>
              <label className="panel-block">
                <input type="checkbox" checked={showNotes} name="showNotes" onChange={this.toggleVisibility}/>
                Notes
              </label>                                                               
              <div className="panel-block">
                <button className="button is-link is-outlined is-small" onClick={this.toggleDisplayPanel}>
                  Done
                </button>
              </div>
            </nav>          
          }
          <div className="columns">
            <div className="column is-4">
              <div className="box">
                <p className="title is-6">Mistakes to Log</p>
                {mistakesToLog.length > 0
                  ?
                    <>
                      <div className="table-container">
                        <table className="table is-narrow is-fullwidth">
                          <thead>
                            <tr>
                              <th>Question</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {mistakesToLogTable}
                          </tbody>
                        </table>
                      </div>
                      {pageCountMistakesToLog > 1 &&
                        <nav className="pagination" role="navigation" aria-label="pagination">
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel={
                              <FontAwesomeIcon icon={faChevronRight} />
                            }
                            onPageChange={this.handlePageClickMistakesToLog}
                            pageRangeDisplayed={2}
                            pageCount={pageCountMistakesToLog}
                            previousLabel={
                              <FontAwesomeIcon icon={faChevronLeft} />
                            }
                            renderOnZeroPageCount={null}
                            className="pagination-list"
                            pageLinkClassName="pagination-link"
                            activeLinkClassName="pagination-link is-current"
                            previousLinkClassName="pagination-previous"
                            nextLinkClassName="pagination-next"
                            breakLinkClassName="pagination-ellipsis"
                          />
                        </nav>
                      }
                    </>
                  :
                    <article className="message is-dark">
                      <div className="message-body">
                        There are no mistakes to log.
                      </div>
                    </article>             
                }                 
              </div>
            </div>
            <div className="column">
              <div className="box">
                <p className="title is-6">Logged Mistakes</p>
                {loggedMistakes.length > 0
                  ?
                    <>
                      <div className="table-container">
                        <table className="table is-narrow is-fullwidth">
                          <thead>
                            <tr>
                              <th onClick={this.toggleDisplayPanel}><FontAwesomeIcon icon={faEye}/></th>
                              {showQuestion &&
                                <th>Question</th>
                              }                      
                              {showIdentify &&
                                <th>Mistake</th>
                              }
                              {showIdentify &&
                                <th>Updated</th>
                              }                              
                              {showSolution &&
                                <th>Solution</th>
                              }
                              {showClue &&
                                <th>Clue</th>
                              }
                              {showNotes &&
                                <th>Notes</th>
                              }
                              {showAction &&
                                <th>Action</th>
                              }
                            </tr>
                          </thead>
                          <tbody>
                            {loggedMistakesTable}
                          </tbody>
                        </table>
                      </div>
                      {pageCountLoggedMistakes > 1 &&
                        <nav className="pagination" role="navigation" aria-label="pagination">
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel={
                              <FontAwesomeIcon icon={faChevronRight} />
                            }
                            onPageChange={this.handlePageClickLoggedMistakes}
                            pageRangeDisplayed={5}
                            pageCount={pageCountLoggedMistakes}
                            previousLabel={
                              <FontAwesomeIcon icon={faChevronLeft} />
                            }
                            renderOnZeroPageCount={null}
                            className="pagination-list"
                            pageLinkClassName="pagination-link"
                            activeLinkClassName="pagination-link is-current"
                            previousLinkClassName="pagination-previous"
                            nextLinkClassName="pagination-next"
                            breakLinkClassName="pagination-ellipsis"
                          />
                        </nav>
                      }
                    </>                   
                  :
                    <article className="message is-dark">
                      <div className="message-body">
                        You haven't logged any mistakes.
                      </div>
                    </article>             
                } 
              </div>
            </div>            
          </div>      
        </>
      );
    }    
  }    

}
export default Mistakes;