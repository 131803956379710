import React from "react";
import { CardElement } from "@stripe/react-stripe-js";
import CardSection from "./CardSection";
import {centsToDollars} from './Utils'
import RadioButton from './RadioButton';
import { createOrder } from './createOrder'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons';

class CheckoutForm extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      termsAndConditions: false,
      payment_gateway: "stripe",
      myErrors: {
        checkout: ''
      },
      errors: {
        stripe: ''
      },
      isLoaded: true         
    };
    this.handleInputChange = this.handleInputChange.bind(this);
  }

  handleInputChange(event) {
    const target = event.target;
    const checkbox_values = ["termsAndConditions"];
    const value = checkbox_values.includes(target.name) ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    });
  }     

  handleSubmit = async event => {
    event.persist();
    event.preventDefault();

    this.setState({
      isLoaded: false
    });

    const { stripe, elements } = this.props;
    if (!stripe || !elements) {
      return;
    }

    const card = elements.getElement(CardElement);
    const result = await stripe.createToken(card);

    const history = this.props.history

    const {errors} = this.state

    // For testing. Only handle one product for now
    const products = this.props.products
    const product_id = products[0].id

    if (result.error) {
      errors.stripe = result.error.message
      this.setState({
        errors: errors,
        isLoaded: true
      })
    } else {
      const { payment_gateway } = this.state;
      const body = { orders: {payment_gateway: payment_gateway, token: result.token.id, product_id: product_id } }

      createOrder(body)
      .then(res => {
        if(res.error) {
          let myErrors = {}
          myErrors.checkout = res.error
          this.setState({
            myErrors: myErrors,
            isLoaded: true
          })
        } else {
          for (let i = 0; i < products.length; i++) {
            this.props.removeFromCart(products[i].id, event)
          }
          this.setState({
            isLoaded: true
          });

          localStorage.setItem('valid_plans', JSON.stringify(res.topics_list_include_free))
          history.push("/dashboard");
        }
      })
      .catch(error => {
        errors.stripe = error.message
        this.setState({
          errors: errors,
          isLoaded: true
        }); 
      })
    }
  };

  render() {
    const products = this.props.products
    const {payment_gateway, termsAndConditions, myErrors, errors, isLoaded} = this.state
    let subTotal = 0
    for (let i = 0; i < products.length; i++) {
      subTotal += products[i].price;
    }    
    
    let disable_button = false;
    if (!this.props.stripe || termsAndConditions == false) {
      disable_button = true;
    }    
    return (     
      <div className="columns">
        <div className="column is-6">
          {products.length > 0
            ?
              <>
                {isLoaded === false && 
                  <div className="overlay">
                    <FontAwesomeIcon icon={faCircleNotch} spin />
                  </div>
                }    
                <table className="table is-fullwidth">
                  <thead>
                    <tr>
                      <th>Plan Name</th>
                      <th>Price</th>
                    </tr>
                  </thead>
                  <tbody>
                    {products.map((product, index) => (
                      <React.Fragment key={product.id}>
                        <tr>
                          <td>
                            <p className="has-text-weight-bold">
                              {product.name}
                            </p>
                            <p className="has-text-grey is-size-7">
                              {"Expires " + product.current_period_end}
                            </p>                            
                          </td>
                          <td className="has-text-weight-bold">
                            {centsToDollars(product.price)}
                          </td>
                        </tr>
                      </React.Fragment>                    
                    ))}
                    <tr>
                      <td>
                        <p className="has-text-weight-bold">
                          Subtotal
                        </p>
                        <p className="has-text-grey">
                          Tax
                        </p>
                      </td>
                      <td>
                        <p className="has-text-weight-bold">
                          {centsToDollars(subTotal)}
                        </p>
                        <p className="has-text-grey">
                          $0.00
                        </p>                            
                      </td>
                    </tr> 
                    <tr>
                      <td className="has-text-weight-bold">Total Due Today</td>
                      <td className="has-text-weight-bold">{centsToDollars(subTotal)}</td>
                    </tr>                         
                  </tbody>
                </table>
                {/*
                <div className="field">
                  <RadioButton 
                    changed={ this.handleInputChange } 
                    id="payment_gateway_stripe"
                    isSelected={ payment_gateway === "stripe" } 
                    label="Stripe"
                    value="stripe"
                    name="payment_gateway"
                  />
                </div>
                <div className="field">
                  <RadioButton 
                    changed={ this.handleInputChange } 
                    id="payment_gateway_paypal"
                    isSelected={ payment_gateway === "paypal" } 
                    label="Paypal"
                    value="paypal"
                    name="payment_gateway"
                  />
                </div>
                */}                
                <form onSubmit={this.handleSubmit}>
                  <CardSection />
                  <input className="is-checkradio" onChange={this.handleInputChange} type="checkbox" checked={termsAndConditions} id="termsAndConditions" name="termsAndConditions" />
                  <label htmlFor="termsAndConditions">
                    By placing your order, you agree to our 
                    <a href="/terms" target="_blank"> Terms of Service </a>
                    and
                    <a href="/privacy" target="_blank"> Privacy Policy</a>
                    .
                  </label>                  
                  <button disabled={disable_button} className="button is-primary block">
                    Place Your Order
                  </button>
                  <div className="block level-left">
                    <p className="level-item stripe_level">
                      <a href="https://stripe.com" className="stripe_logo_wrapper has-text-grey" target="_blank">
                        {"Powered by "}
                        <svg className="stripe_logo" focusable="false" width="33" height="15" role="img" aria-labelledby="stripe-title"><title id="stripe-title">Stripe</title><g fillRule="evenodd"><path d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z"></path></g></svg>
                      </a>
                    </p>
                    <p className="level-item">
                      <a href="https://stripe.com/legal/end-users" className="stripe_logo_wrapper has-text-grey" target="_blank">
                        Terms
                      </a>
                    </p>
                    <p className="level-item">
                      <a href="https://stripe.com/privacy" className="stripe_logo_wrapper has-text-grey" target="_blank">
                        Privacy
                      </a>
                    </p>
                  </div>                  
                  {errors.stripe.length > 0 &&
                    <p className='help is-danger'>{errors.stripe}</p>}                  
                  {myErrors.checkout &&
                    <p className="help is-danger">{myErrors.checkout}</p>
                  }                  
                </form>
              </>
            : <p>No items in cart.</p>
          }
        </div>
      </div>
    );
  }
}

export default CheckoutForm;