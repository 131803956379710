export const createAuthCart = (order) => {
  let token = "Bearer " + localStorage.getItem("jwt")
  return fetch("/api/v1/carts/auth-create", {
    method: "POST",
    headers: {
      "Authorization": token,
      "Content-Type": "application/json",
      'Accept': 'application/json'
    },
    body: JSON.stringify(order)
  })
  .then(res => res.json())
}

