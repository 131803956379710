import React from "react";
import { Link } from "react-router-dom";
import Option from "./Option";
import Tutor from "./Tutor";
import PassageModal from "./PassageModal";
import moment from 'moment/moment.js';
import Latex from "./Latex";

const studentProducedResponse = "Student-produced response"
class MistakeQuestion extends React.Component  {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      showAnswerHistory: false,
      showTutor: false,         
    };
  }

  toggleModal(id, e) {
    e.preventDefault();
    const showModal = this.state.showModal === id ? null : id
    this.setState({showModal: showModal});
  };

  expandSection(e) {
    e.preventDefault();
    let target_name = e.target.name
    this.setState(prevState => ({
      [target_name]: !prevState[target_name]
    }));
  }   

  render() {
    const {showModal, showAnswerHistory, showTutor } = this.state

    const isGridIn = this.props.question.question_type === studentProducedResponse ? true : false
    
    const answerExplanation = this.props.question.answer_explanation
    const timeSpentAverage = this.props.question.question_time_spent_average
    const percentageCorrect = (this.props.question.question_percent_correct_average * 100).toFixed()
    let correctOption
    if (this.props.question.question_type === studentProducedResponse) {
      correctOption = this.props.question.correct_grid_in_answer
    } else {
      correctOption = this.props.question.options.find(option => option.correct === true).letter
    }   
    return (
      <Latex>
        <h1 className="is_tertiary_font question_content_wrapper content">
          <div dangerouslySetInnerHTML={{__html: this.props.question.content}}></div>
        </h1>
        {!isGridIn &&
          <div className="options_wrapper is_tertiary_font">
            {this.props.question.options.sort((a, b) => (a.letter > b.letter) ? 1 : -1).map((option, index) => {
              return (
                <div key={option.id} className="field">
                  <div className="control">
                    <Option
                      readOnly={true}
                      disabled={true}
                      id={index}
                      label={option.content}
                      value={option.letter}/>
                  </div>
                </div>
              )
            })}
          </div>
        }
        {this.props.question.passage &&
          <>
            <p className="has-text-weight-bold">
              <a href="#" onClick={(e) => this.toggleModal(this.props.question.passage.id, e)}>Show Passage</a>
            </p>
            <PassageModal value={this.props.question.passage} id={this.props.question.passage.id} showModal={showModal} closeModal={(e) => this.toggleModal(this.props.question.passage.id, e)}/>  
          </>        
        }
        <p className="has-text-weight-bold">            
          <a href={void(0)} name="showTutor" onClick={(e) => this.expandSection(e)}>{`Correct Answer ${showTutor ? "-" : "+"}`}</a>
        </p>
        {showTutor &&
          <Tutor isAverage={true} answerExplanation={answerExplanation} correctOption={correctOption} timeSpent={timeSpentAverage} percentageCorrect={percentageCorrect}/>
        }
        {this.props.question.answer_history.length > 0 &&
          <>
            <p className="has-text-weight-bold">
              <a href={void(0)} name="showAnswerHistory" onClick={(e) => this.expandSection(e)}>{`Answer History ${showAnswerHistory ? "-" : "+"}`}</a>
            </p>
            {showAnswerHistory &&
              <div className="table-container">
                <table className="table">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Test Id</th>
                      <th>Answer</th>
                    </tr>
                  </thead>
                  <tbody>
                    {this.props.question.answer_history.map((a, index) => {
                      return (
                        <tr key={index}>
                          <td>{moment(a.date_taken).format('M/D/YY, h:mma')}</td>
                          <td>
                            <Link to={`/tests/${a.test_id}`}>{a.test_id}</Link>
                          </td>
                          <td>{a.answer_attributes.letter ? a.answer_attributes.letter : "Skipped"}</td>
                        </tr>
                      )
                    })}
                  </tbody>
                </table>
              </div>
            }
          </>
        }
      </Latex>
    );
  }
}

export default MistakeQuestion;