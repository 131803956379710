import React from "react";
import { Link } from "react-router-dom";
import moment from 'moment/moment.js'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faChevronRight, faChevronLeft } from '@fortawesome/free-solid-svg-icons';
import {handleResponse} from './Utils'
import Tabs from "./Tabs";
import Loading from "./Loading";
import ReactPaginate from 'react-paginate';

class Tests extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tests: [],
      filtered_tests: [],
      itemOffset: 0,
      pageCount: 0,
      itemCount: 0,
      itemsPerPage: 25,
      isLoaded: false
    };
    this.handlePageClick = this.handlePageClick.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt")
    const url = "/api/v1/tests/index";
    fetch(url, { method: 'GET', headers: {'Authorization': token }})
    .then((test) => handleResponse(test))
    .then(response => this.setState({ 
      tests: response.tests,
      filtered_tests: response.tests,
      pageCount: response.total_page_count,
      itemCount: response.total_count,
      itemsPerPage: response.items_per_page,
      isLoaded: true 
    }, () => {
      window.setMobileTable('.wide_table')
    }))      
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }

  handlePageClick(event){
    const {tests, itemOffset, pageCount, itemsPerPage, itemCount} = this.state
    const page = event.selected

    const newOffset = (page * itemsPerPage) % itemCount;

    let token = "Bearer " + localStorage.getItem("jwt")
    const url = `/api/v1/tests/index?newOffset=${newOffset}&itemsPerPage=${itemsPerPage}`;
    fetch(url, { method: 'GET', headers: {'Authorization': token }})
    .then((mistake) => handleResponse(mistake))
    .then(response => this.setState({
      tests: response.tests,
      filtered_tests: response.tests,
      itemOffset: newOffset,
      pageCount: response.total_page_count,
    })) 
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })      
  };    

  render() {
    const {tests, filtered_tests, itemOffset, pageCount, itemsPerPage} = this.state;
    const {topic} = this.props.user

    const allTests = filtered_tests.map((test, index) => (
      <tr key={index}>
        <td>{test.score !== null ? Math.round((test.score + Number.EPSILON) * 100) / 100 + "%" : "Test not scored"}</td>
        <td>{test.id}</td>
        <td>
          {(test.timed) 
            ? <FontAwesomeIcon icon={faCheck}/>
            : <FontAwesomeIcon icon={faTimes}/>
          }          
        </td>
        <td>
          {(test.tutor) 
            ? <FontAwesomeIcon icon={faCheck}/>
            : <FontAwesomeIcon icon={faTimes}/>
          }          
        </td>
        {/*
        {(activeTab === "Math" || activeTab === "All") &&
          <td>
            {(test.calculator) 
              ? <FontAwesomeIcon icon={faCheck}/>
              : <FontAwesomeIcon icon={faTimes}/>
            }
          </td>
        }
      */}
        <td>{test.question_mode}</td>
        <td>{test.number_of_questions}</td>
        <td>{moment(test.created_at).format('MMMM Do YYYY, h:mm')}</td>
        <td>
          {test.score === null
            ?
             <Link to={`/tests/${test.id}`}>Resume</Link> 
            :
              <>
                <Link to={`/tests/${test.id}`}>View</Link>
                <span>-</span>
                <Link to={`/tests/${test.id}/result`}>Results</Link>
                <span>-</span>
                <Link to={`/tests/${test.id}/analysis`}>Analysis</Link>
              </>
          }
        </td>
      </tr>
    ));
    if (this.state.isLoaded === false) {
      return (
        <Loading/>   
      )
    } else {
      return (
        <>
          <h1 className="title mb-0">Tests</h1>
          <p className="tertiary_gray mb-5">{topic}</p>          
          {tests.length > 0
            ?
              <div className="box">
                {filtered_tests.length > 0
                  ?
                    <>
                      <div className="table-container wide_table">
                        <table className="table is-fullwidth">
                          <thead>
                            <tr>
                              <th>Score</th>
                              <th>id</th>
                              <th>Timed</th>
                              <th>Tutor</th>
                              {/*
                              {(activeTab === "Math" || activeTab === "All") &&
                                <th>Calculator</th>
                              }
                            */}
                              <th>Question Mode</th>
                              <th>Question Count</th>
                              <th>Created At</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {allTests}
                          </tbody>
                        </table>
                      </div>
                      {pageCount > 1 &&
                        <nav className="pagination" role="navigation" aria-label="pagination">
                          <ReactPaginate
                            breakLabel="..."
                            nextLabel={
                              <FontAwesomeIcon icon={faChevronRight} />
                            }
                            onPageChange={this.handlePageClick}
                            pageRangeDisplayed={5}
                            pageCount={pageCount}
                            previousLabel={
                              <FontAwesomeIcon icon={faChevronLeft} />
                            }
                            renderOnZeroPageCount={null}
                            className="pagination-list"
                            pageLinkClassName="pagination-link"
                            activeLinkClassName="pagination-link is-current"
                            previousLinkClassName="pagination-previous"
                            nextLinkClassName="pagination-next"
                            breakLinkClassName="pagination-ellipsis"
                          />
                        </nav>
                      }
                    </>
                  :
                    <article className="message">
                      <div className="message-body">{`No tests to show.`}</div>
                    </article>                  
                }               
              </div>
            :
              <article className="message is-dark">
                <div className="message-body">
                  You have not taken any tests.
                </div>
              </article>
          }
        </>
      );
    }    
  }    

}
export default Tests;