import React from "react";
import ReactHtmlParser from 'react-html-parser'; 

const Option = props => {

  return (
    <label className={props.readOnly ? props.correctOption === props.value ? "checkbox modal_option correct_modal_option" : "checkbox modal_option" : "checkbox"}>
      <input readOnly={props.readOnly ? props.readOnly : false} className="option button" id={props.id} onChange={props.changed} value={props.value} type="checkbox" checked={props.isSelected} disabled={props.disabled ? props.disabled : false}/>
      <span>{props.value}</span>
      {ReactHtmlParser(props.label)}
    </label>
  );
}

export default Option