import React from 'react';
import {handleResponse, centsToDollars} from './Utils'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// We use the Map construct for checkedTags for flexibility and convenience of setting and getting values
class Orders extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      myErrors: {
        orders: ''
      }
    };
    this.stripHtmlEntities = this.stripHtmlEntities.bind(this);
  }

  // Get tags
  // Response.products_purchase[0].id is just to have the first radio button selected
  componentDidMount() {
    const {topic} = this.props;
    fetch(`/api/v1/orders/${topic}/filtered-products`, { method: 'GET'})
    .then((order) => handleResponse(order))
    .then(response => this.setState({ ...response, isLoaded: true }))
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }

  stripHtmlEntities(str) {
    return String(str)
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  render() {
    if (this.state.isLoaded === false) {
      return (
        <div className="centered">
        </div>     
      )
    } else {
      const {myErrors, products_purchase, products_subscription, educator} = this.state
      const productsPurchase = products_purchase.map((product, index) => (
        <div className={`pricing-plan column ${product.price_anchor ? "is-active" : ""}`} key={`pricing_plan_${product.id}`}>
          <div className="plan-header">
            <span className="icon-text">
              <span>{product.name}</span>
            </span>          
          </div>
          <div className="plan-price"><span className="plan-price-amount has-text-dark">{centsToDollars(product.price)}</span></div>
          <div className="plan-header">{product.plan_length} Days</div>
          <div className="plan-items" dangerouslySetInnerHTML={{__html: product.body}}>
          </div>
          <div className="plan-footer">
            <button className="button is-fullwidth is-black" onClick={(e) => this.props.addToCart(product.id, e)}>Buy</button>
          </div>
        </div>
      ));
      {/*
      const productsSubscription = products_subscription.map((product, index) => (
        <div className="pricing-plan" key={`pricing_plan_${product.id}`}>
          <div className="plan-header">{product.name}</div>
          <div className="plan-header">{product.plan_length} Days</div>
          <div className="plan-price"><span className="plan-price-amount">{centsToDollars(product.price)}</span></div>
          <div className="plan-items">
            <div className="plan-item">-</div>
            <div className="plan-item">-</div>
            <div className="plan-item">-</div>
            <div className="plan-item">-</div>
          </div>
          <div className="plan-footer">
            <button className="button is-fullwidth is-dark" onClick={(e) => this.props.addToCart(product.id, e)}>Add to cart</button>
          </div>
        </div>
      ));
      */}          
      return (
        <section className="section">
          <div className="container">     
            <div className="pricing-table columns">
              {productsPurchase}
            </div>
            {/*
            <p className="title">Subscriptions</p>
            <div className="pricing-table">
              {productsSubscription}
            </div>
            */}
            {this.myErrors && 
              <p className='help is-danger'>{this.myErrors.orders}</p>
            }
          </div>
        </section>
      );
    }    

  }      
}

export default Orders;