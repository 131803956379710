import React from "react";
import { Link } from "react-router-dom";

const NotFoundPage = (props) => {

  return (
    <section className="hero is-bold">
      <div className="hero-body">
        <div className="container has-text-centered">
          <h1 className="title is-1 not_found_title">
            404
          </h1>
          <h2 className="subtitle">
            The page you're looking for doesn't exist!
          </h2>
          <Link to="/" className="button is-outlined">To the Homepage</Link>
        </div>
      </div>
    </section>
  );
}

export default NotFoundPage;