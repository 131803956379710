import React from "react";

const Checkbox = props => {
  return (
    <>
      <input className="is-checkradio" onChange={props.handleInputChange} type="checkbox" checked={props.checked} value={props.value} id={props.name} name={props.name} />
      <label htmlFor={props.name}>
        {props.label}
        {(props.pill || props.pill >= 0) &&
          <span className="tag is-light is-rounded">{props.pill}</span> 
        }
      </label>
    </>
  )
}


export default Checkbox