import React, { Component } from "react";
import PropTypes from "prop-types";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faPlus, faMinus } from '@fortawesome/free-solid-svg-icons';
import Latex from "./Latex";

class StudyPlanAccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  }; 

  render() {
    const { onClick, props: { isOpen, label, total_length, strong_fraction, moderate_fraction } } = this;

    return (
      <>
        <h2 className="subtitle is-6" onClick={onClick} style={{ cursor: "pointer" }}>
          {isOpen 
            ?
              <span>{` - `}</span>
            : 
              <span>{` + `}</span>
          }
          <span>{label}</span>
          <strong>{` (${total_length} topics)`}</strong>
          <div className="progress">
            <div className="progress_fill" style={{width: `${strong_fraction}%`}}></div>
            <div className="progress_fill" style={{width: `${strong_fraction + moderate_fraction}%`}}></div>
          </div>
        </h2>
        {isOpen && (
          <Latex>
            {this.props.children}
          </Latex>
        )}            

      </>
    );
  }
}

export default StudyPlanAccordionSection;