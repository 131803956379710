import React, { Component } from "react";
import PropTypes from "prop-types";

import AccordionSection from "./AccordionSection";

class Accordion extends Component {
  static propTypes = {
    allowMultipleOpen: PropTypes.bool,
    children: PropTypes.instanceOf(Object).isRequired,
  };

  constructor(props) {
    super(props);

    const openSections = {};
    
    this.props.children.forEach(child => {
      if (child.props.isOpen) {
        openSections[child.props.label] = true;
      }
    });

    this.state = { openSections };
  }

  onClick = label => {
    const { state: { openSections } } = this;

    const isOpen = !!openSections[label];

    this.setState({
      openSections: {
        ...openSections,
        [label]: !isOpen
      }
    }, () => {
        window.setMobileTable('.wide_table')
    });
  };

  render() {
    const { 
      onClick,
      props: { children },
      state: { openSections },
    } = this;

    return (
      <>
        {children.map(child => (
          <AccordionSection
            key={child.props.label}
            isOpen={!!openSections[child.props.label]}
            label={child.props.label}
            correct={child.props.correct}
            incorrect={child.props.incorrect}
            skipped={child.props.skipped}
            prediction={child.props.prediction}
            totalQuestions={child.props.totalQuestions}
            dataset={child.props.dataset}
            onClick={onClick}
          >
            {child.props.children}
          </AccordionSection>
        ))}
      </>
    );
  }
}

export default Accordion;