import React from "react";
import {handleResponse, centsToDollars} from './Utils'
import Loading from "./Loading";
import moment from 'moment/moment.js'

class PaymentEvents extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      payment_events: [],
      isLoaded: false,
    };
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt")
    const url = "/api/v1/payment_events/index";
    fetch(url, { method: 'GET', headers: {'Authorization': token }})
    .then((payment_event) => handleResponse(payment_event))
    .then(response => this.setState({ payment_events: response, isLoaded: true }))     
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }       

  render() {
    const {payment_events} = this.state

    const allPaymentEvents = payment_events.map((payment, index) => (
      <tr key={index}>
        <td>{moment(new Date(payment.created * 1000).toString()).format("M/D/YY")}</td>
        <td>{(payment.event_type.charAt(0).toUpperCase() + payment.event_type.slice(1)).replace(/_/g, ' ').replace(/\./g,' ')}</td>
        <td>{centsToDollars(payment.amount_due)}</td>
        <td>{centsToDollars(payment.amount_paid)}</td>
        <td>{centsToDollars(payment.amount_remaining)}</td>
        <td>{moment(new Date(payment.period_start * 1000).toString()).format("M/D/YY")}</td>
        <td>{moment(new Date(payment.period_end * 1000).toString()).format("M/D/YY")}</td>
        <td><a rel="noopener noreferrer" href={payment.hosted_invoice_url} target="_blank">View Details</a></td>
      </tr>
    ));
    if (this.state.isLoaded === false) {
      return (
        <Loading/>     
      )
    } else {
      return (
        <>
          {payment_events.length > 0
            ?
              <div className="table-container">
                <table className="table is-narrow">
                  <thead>
                    <tr>
                      <th>Date</th>
                      <th>Type</th>
                      <th>Due</th>
                      <th>Paid</th>
                      <th>Remaining</th>
                      <th>Start</th>
                      <th>End</th>
                      <th>Action</th>                      
                    </tr>
                  </thead>
                  <tbody>
                    {allPaymentEvents}
                  </tbody>
                </table>            
              </div>
            :
              <article className="message is-dark">
                <div className="message-body">
                  There are no payments.
                </div>
              </article>             
          }        
        </>
      );
    }    
  }    

}
export default PaymentEvents;