export const updatePasswordChange = (user) => {
  let token = "Bearer " + localStorage.getItem("jwt")
  return fetch("/api/v1/password/update", {
    method: 'PUT',
    body: JSON.stringify(user),     
    headers: {'Authorization': token,
              'Content-Type': 'application/json',
              'Accept': 'application/json',
    }
  })
  .then(res => res.json())
}

