import React from 'react';
import RadioButton from './RadioButton';
import Checkbox from './Checkbox'
import { createTest } from './createTest'
import Loading from "./Loading";
import {handleResponse} from './Utils'
import Tabs from "./Tabs";

const validateForm = (errors) => {
  let valid = true;
  Object.values(errors).forEach(
    // if we have an error string set valid to false
    (val) => val.length > 0 && (valid = false)
  );
  return valid;
}

class NewTest extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      name: "",
      score: "",
      section: "",
      number_of_questions: "",
      calculator: false,
      no_calculator: false,
      question_mode: "All",
      difficulty_low: false,
      difficulty_medium: false,
      difficulty_high: false,
      timed: false,
      tutor: false,
      tags: [],
      tag_ids: [],
      checkedTags: new Map(),
      isLoaded: false,
      has_passage: false,
      base_filter: [],
      errors: {
        number_of_questions: ''
      },
      myErrors: {
        number_of_questions: '',
        custom_test: ''
      }     
    };      

    // These handle updating the state, form submissions, and special characters.
    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitCustom = this.onSubmitCustom.bind(this);
    this.stripHtmlEntities = this.stripHtmlEntities.bind(this);
    this.radioChangeHandler = this.radioChangeHandler.bind(this);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleNumberOfQuestions = this.handleNumberOfQuestions.bind(this);
    this.handleChecked = this.handleChecked.bind(this);
    this.handleCustom = this.handleCustom.bind(this);
  }

  // Get tags
  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt")
    const url = "/api/v1/tests/new";
    fetch(url, { method: 'GET', headers: {'Authorization': token }})
    .then((test) => handleResponse(test))
    .then(response => this.setState({
      ...response,
      base_filter: response.test_questions.questions,
      base_category_questions: response.test_questions.questions,
      calculator_questions: response.test_questions.questions.filter(question => question.calculator === true),
      no_calculator_questions: response.test_questions.questions.filter(question => question.calculator === false),
      difficulty_low_questions: response.test_questions.questions.filter(question => question.difficulty === "Low"),
      difficulty_med_questions: response.test_questions.questions.filter(question => question.difficulty === "Medium"),
      difficulty_high_questions: response.test_questions.questions.filter(question => question.difficulty === "High"),
      marked: response.test_questions.questions.filter(question => response.test_questions.marked.includes(question.id.toString())),
      incorrect: response.test_questions.questions.filter(question => response.test_questions.incorrect.includes(question.id.toString())),
      unused: response.test_questions.questions.filter(question => !response.test_questions.used.includes(question.id.toString())),
      tags: response.test_questions.tags,
      tag_filter: response.test_questions.tag_filter,
      max_number_of_questions: response.test_questions.max_number_of_questions,
      has_passage: response.test_questions.has_passage,
      isLoaded: true 
    }))     
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }  

  buildFilter(filter) {
    let query = {};
    for (let keys in filter) {
      if (filter[keys].constructor === Array && filter[keys].length > 0) {
        query[keys] = filter[keys];
      }
    }
    return query;
  }

  filterData(data, query){
    const filteredData = data.filter( (item) => {
      for (let key in query) {
        if (item[key] === undefined || !query[key].includes(item[key])) {
          return false;
        }
      }
      return true;
    });
    return filteredData;
  };  

  calcQuestionLengths() {
    let {base_filter, difficulty_low, difficulty_medium, difficulty_high, calculator, no_calculator, tag_ids, tags, calculator_questions, no_calculator_questions, difficulty_low_questions, difficulty_med_questions, difficulty_high_questions } = this.state

    let total_difficulty = null
    let total_calculator = null
    let total_tag_count = null    

    // These are used to dynamically change the counts of calculator, difficulty, and tags
    const calc_hash = {calculator: []} 
    const diff_hash = {difficulty: []}
    const tag_hash = {calculator: [], difficulty: []}

    if (calculator === true) {
      total_calculator += calculator_questions.length
      calc_hash.calculator.push(true)
      tag_hash.calculator.push(true)
    }
    if (no_calculator === true) {
      total_calculator += no_calculator_questions.length 
      calc_hash.calculator.push(false)    
      tag_hash.calculator.push(false)   
    }
    if (difficulty_low === true) {
      total_difficulty += difficulty_low_questions.length    
      diff_hash.difficulty.push("Low")
      tag_hash.difficulty.push("Low")
    }
    if (difficulty_medium === true) {
      total_difficulty += difficulty_med_questions.length 
      diff_hash.difficulty.push("Medium")
      tag_hash.difficulty.push("Medium")
    } 
    if (difficulty_high === true) {
      total_difficulty += difficulty_high_questions.length
      diff_hash.difficulty.push("High")
      tag_hash.difficulty.push("High") 
    }

    const diff_query = this.buildFilter(diff_hash);
    const diff_result = this.filterData(base_filter, diff_query);

    const calc_query = this.buildFilter(calc_hash);
    const calc_result = this.filterData(base_filter, calc_query);

    const tag_query = this.buildFilter(tag_hash);
    const tag_result = this.filterData(base_filter, tag_query);

    // These are already using master set of questions so no need to call master_object
    let new_calculator_questions = diff_result.filter(question => question.calculator === true)
    let new_no_calculator_questions = diff_result.filter(question => question.calculator === false)
    let new_difficulty_low_questions = calc_result.filter(question => question.difficulty === "Low")
    let new_difficulty_med_questions = calc_result.filter(question => question.difficulty === "Medium")
    let new_difficulty_high_questions = calc_result.filter(question => question.difficulty === "High")

    // This gets ids of all tags
    // let mapIds = tags.map(a => a.id);
    let mapIds = Object.values(tags).flat().map((tag) => tag.id)
    // This excludes parent tags

    if (tag_ids.length !== 0) {
      // This gets length of child tags
      tag_ids.forEach(function(value){
        total_tag_count += (tag_result.filter(question => question.tag_id === value).length);
      })
    }


    let max = base_filter.length
    // This filters null values, and sets max to 20
    let cumulative = [total_difficulty, total_calculator, total_tag_count, max, 20].filter((obj) => obj != null );
    // This is so we don't get Infinity running Math.min on empty array
    if (cumulative.length === 0) {
      cumulative.push(0)
    }
    let max_number_of_questions = Math.min(...cumulative);
    this.setState({
      tag_filter: tag_result,
      max_number_of_questions: max_number_of_questions,
      calculator_questions: new_calculator_questions,
      no_calculator_questions: new_no_calculator_questions,
      difficulty_low_questions: new_difficulty_low_questions,
      difficulty_med_questions: new_difficulty_med_questions,
      difficulty_high_questions: new_difficulty_high_questions   
    })
  }

  // Handles radios buttons
  radioChangeHandler = (event) => {
    const value = event.target.value

    let {base_filter, base_category_questions, marked, incorrect, unused} = this.state

    if (value === "Marked") {
      base_filter = marked
    } else if (value === "Incorrect") {
      base_filter = incorrect
    } else if (value === "Unused") {
      base_filter = unused   
    } else if (value === "All") {
      base_filter = base_category_questions
    }  

    this.setState({
      question_mode: value,
      base_filter: base_filter   
    }, () => {
      this.calcQuestionLengths()
    });
  }

  handleCustom(event) {
    const value = event.target.value;

    this.setState({
      question_mode: value
    });
  }    

  handleInputChange(event) {
    const target = event.target;
    const checkbox_values = ["timed", "tutor", "calculator", "no_calculator", "difficulty_low", "difficulty_medium", "difficulty_high"];
    const value = checkbox_values.includes(target.name) ? target.checked : target.value;
    const name = target.name;

    this.setState({
      [name]: value
    }, () => {
      this.calcQuestionLengths()
    });
  }

  handleNumberOfQuestions(event) {
    const value = event.target.value;    
    let {max_number_of_questions, errors} = this.state

    if (value < "1") {
      errors.number_of_questions = "Please choose 1 or more questions."
    } else if (!Number(value)){
      errors.number_of_questions = 'Please enter a number.'          
    } else if (value.length > 2) {
      errors.number_of_questions = `The max is ${max_number_of_questions} questions.`
    } else if (max_number_of_questions === 0){
      errors.number_of_questions = "No questions match your criteria."
    } else if (value > max_number_of_questions){
      errors.number_of_questions = `The max is ${max_number_of_questions} questions.`
    } else {
      errors.number_of_questions = ''
    }

    // This prevents user from typing more than 2 characters
    if (event.target.value.length > event.target.maxLength) {
      event.target.value = event.target.value.slice(0, event.target.maxLength);
      errors.number_of_questions = `The max is ${max_number_of_questions} questions.`
    }
    // This prevents user from entering non-numbers     
    this.setState({errors, number_of_questions: event.target.value.replace(/\D/,'')})    
  }

  handleChecked = (event) => {
    event.persist();
    const tag_ids = this.state.tag_ids;
    const isChecked = event.target.checked;
    let check = event.target
    let parentNode = check.parentNode;

    this.setState(state => {
      // We use this in render in the checked prop to determine if checked / unchecked.
      const cbDescendants = parentNode.querySelectorAll('input[type="checkbox"]');
      for (let y = 0; y < cbDescendants.length; y++) {
        checkedTags: state.checkedTags.set(parseInt(cbDescendants[y].value), isChecked);
        cbDescendants[y].indeterminate = false;
      }
      while (["ul", "li"].indexOf(parentNode.nodeName.toLowerCase()) >= 0) {
        const mainCb = parentNode.querySelector(':scope > input[type="checkbox"]');
        if (mainCb && mainCb != this) {
          // We reuse this
          const mainCbValue = mainCb.value;

          checkedTags: state.checkedTags.set(mainCbValue, isChecked);
          const mainCbChildren = mainCb.parentNode.querySelectorAll('input[type="checkbox"]');
          const numTotal = mainCbChildren.length;
          let numChecked = 0;
          for (let z = 0; z < mainCbChildren.length; z++) {
            if (this.state.checkedTags.get(parseInt(mainCbChildren[z].value))) {
              numChecked++;
            }
          }
          if (numTotal === numChecked) {
            mainCb.indeterminate = false;
            checkedTags: state.checkedTags.set(mainCbValue, true);

          } else {
            if (numChecked === 0) {
              mainCb.indeterminate = false;
              checkedTags: state.checkedTags.set(mainCbValue, false);
            } else {
              mainCb.indeterminate = true;
              checkedTags: state.checkedTags.set(mainCbValue, false);
            }
          }
        }
        parentNode = parentNode.parentNode;
      }

      // We set state for tag_ids here
      tag_ids.length = 0;
      this.state.checkedTags.forEach(function(value, key){
        if (value === true) {
          tag_ids.push(key);
        }
      })
      {
        return tag_ids
      }
    }, () => {
      this.calcQuestionLengths()
    });
  }

  stripHtmlEntities(str) {
    return String(str)
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  questionsWithTag = (children, tag_filter) => {
    let tagIds = children.map(c => c.id);
    let questions = []
    for (let i = 0; i < tag_filter.length; i++) {
      if (tagIds.includes(tag_filter[i].tag_id)) {
        questions.push(tag_filter[i])
      }
    }
    return questions
  }  

  passageLength = (questions) => {
    let passageIds = []
    for (let i = 0; i < questions.length; i++) {
      if (!passageIds.includes(questions[i].passage_id) && questions[i].passage_id !== null) {
        passageIds.push(questions[i].passage_id)
      }
    }
    return passageIds.length
  }

  onSubmit(event) {
    event.preventDefault();
    if(validateForm(this.state.errors)) {
      this.setState({
        isLoaded: false
      });       
      if (!this.state.number_of_questions) {
        const {errors, has_passage} = this.state
        let resource = has_passage ? "passages" : "questions"

        errors.number_of_questions = `Please choose 1 or more ${resource}.`
        this.setState({
          isLoaded: true,
          errors: errors
        })
      } else {
        const { name, score, section, number_of_questions, calculator, no_calculator, question_mode, difficulty_low, difficulty_medium, difficulty_high, timed, tutor, tag_ids, max_number_of_questions, custom_question_ids, custom_test_id, has_passage} = this.state;

        // if (name.length == 0 || score.length == 0 || section.length == 0 || number_of_questions.length == 0 || question_mode.length == 0 || difficulty.length == 0 || mode.length == 0)
        //   return;

        const body = {
          name,
          score,
          section,
          number_of_questions,
          calculator,
          no_calculator,
          question_mode,
          difficulty_low,
          difficulty_medium,
          difficulty_high,
          timed,
          tutor,
          tag_ids,
          max_number_of_questions,
          custom_question_ids,
          custom_test_id
        };

        createTest(body)
        .then(res => {
          if(res.error) {
            let myErrors = {}
            myErrors.number_of_questions = res.error
            this.setState({
              isLoaded: true,
              myErrors: myErrors
            })
          }else {
            this.props.history.push(`/tests/${res.id}`);
          }
        })
        .catch(error => console.log(error.message));
      }    

    }  
  }

  onSubmitCustom(event) {
    event.preventDefault();
    const { name, score, section, number_of_questions, calculator, no_calculator, question_mode, difficulty_low, difficulty_medium, difficulty_high, timed, tutor, tag_ids, max_number_of_questions, custom_question_ids, custom_test_id} = this.state;

    // if (name.length == 0 || score.length == 0 || section.length == 0 || number_of_questions.length == 0 || question_mode.length == 0 || difficulty.length == 0 || mode.length == 0)
    //   return;

    const body = {name, score, section, number_of_questions, calculator, no_calculator, question_mode, difficulty_low, difficulty_medium, difficulty_high, timed, tutor, tag_ids, max_number_of_questions, custom_question_ids, custom_test_id};

    createTest(body)
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors.custom_test = res.error
        this.setState({myErrors: myErrors})
      }else {
        this.props.history.push(`/tests/${res.id}`);
      }
    })
    .catch(error => console.log(error.message));
  }    

  render() {
    let {question_mode, max_number_of_questions, errors, myErrors, tag_filter, has_passage, base_category_questions, calculator_questions, no_calculator_questions, difficulty_low_questions, difficulty_med_questions, difficulty_high_questions, unused, incorrect, marked, tags, tag_ids, checkedTags } = this.state
    const {topic} = this.props.user
    if (this.state.isLoaded === false) {
      return (
        <Loading messages={["Getting test material...", "Loading account..."]}/>    
      )
    } else {
      return (
        <>
          <h1 className="title mb-0">Create Test</h1>
          <p className="tertiary_gray mb-5">{topic}</p>
          <div className="box">         
            <form onSubmit={this.onSubmit}>
              <div className="field">
                <label className="label">Test Modes</label>
                <Checkbox handleInputChange={this.handleInputChange} checked={this.state.timed} name="timed" label="Timed" />                           
                <Checkbox handleInputChange={this.handleInputChange} checked={this.state.tutor} name="tutor" label="Tutor" />
              </div>
              {/*
              {activeTab === "Math" &&
                <div className="field">
                  <label className="label">Calculator Sections</label>             
                  <Checkbox handleInputChange={this.handleInputChange} checked={this.state.calculator} name="calculator" label="Calculator" pill={calculator_questions.length}/>                          
                  <Checkbox handleInputChange={this.handleInputChange} checked={this.state.no_calculator} name="no_calculator" label="No Calculator" pill={no_calculator_questions.length} />
                </div>
              }
              */}
              <div className="field">
                <label className="label">Question Type</label>
                <div className="is-flex is-flex-wrap-wrap">
                  <div className="flex_item">
                    <RadioButton 
                      changed={ this.radioChangeHandler } 
                      id="question_mode_unused" 
                      isSelected={ question_mode === "Unused" } 
                      label="Unused" 
                      value="Unused"
                      pill={has_passage ? `${this.passageLength(unused)}P/${unused.length}Q` : unused.length}
                      name="question_mode"
                    />
                  </div>
                  <div className="flex_item">
                    <RadioButton 
                      changed={ this.radioChangeHandler } 
                      id="question_mode_incorrect" 
                      isSelected={ question_mode === "Incorrect" } 
                      label="Incorrect" 
                      value="Incorrect"
                      pill={has_passage ? `${this.passageLength(incorrect)}P/${incorrect.length}Q` : incorrect.length}
                      name="question_mode"
                    />
                  </div>
                  <div className="flex_item">
                    <RadioButton 
                      changed={ this.radioChangeHandler } 
                      id="question_mode_marked" 
                      isSelected={ question_mode === "Marked" } 
                      label="Starred" 
                      value="Marked"
                      pill={has_passage ? `${this.passageLength(marked)}P/${marked.length}Q` : marked.length}
                      name="question_mode"
                    />
                  </div>
                  <div className="flex_item">
                    <RadioButton 
                      changed={ this.radioChangeHandler } 
                      id="question_mode_all" 
                      isSelected={ question_mode === "All" } 
                      label="All" 
                      value="All"
                      pill={has_passage ? `${this.passageLength(base_category_questions)}P/${base_category_questions.length}Q` : base_category_questions.length}
                      name="question_mode"
                    />
                  </div>
                  {/*
                  <div className="flex_item">
                    <RadioButton 
                      changed={ this.radioChangeHandler } 
                      id="question_mode_custom" 
                      isSelected={ question_mode === "Recommendation" } 
                      label="Recommendation" 
                      value="Recommendation"
                      name="question_mode" 
                    />
                  </div>
                  */}
                </div>
              </div>
              {question_mode === "Recommendation" &&
                <div className="field custom_test_wrapper">
                  <div className="columns">
                    <div className="column">
                      <label className="label">Custom Test</label>
                      <p>
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
                      </p>
                    </div>
                    <div className="column">
                      <div className="field">
                        <label className="label">Test Id</label>
                        <div className="control">
                          <input name="custom_test_id" onChange={this.handleInputChange} className="input" type="text"/>
                        </div>
                      </div>                 
                      {/*<div className="is-divider" data-content="OR"></div>
                      <div className="field">
                        <label className="label">Question Ids</label>
                        <div className="control">
                          <textarea name="custom_question_ids" onChange={this.handleInputChange} className="textarea" placeholder="Comma-separated list"></textarea>
                        </div>
                      </div>
                      */}
                      <div className="control">
                        <button onClick={this.onSubmitCustom} className="button is-link">Create Custom Test</button>
                        {myErrors.custom_test && 
                          <p className='help is-danger'>{myErrors.custom_test}</p>}                                               
                      </div>
                    </div>
                  </div>
                </div>                
              }
              <div className="field">
                <label className="label">Difficulty</label>
                <div className="is-flex is-flex-wrap-wrap">
                  <Checkbox handleInputChange={this.handleInputChange} checked={this.state.difficulty_low} name="difficulty_low" label="Low" pill={has_passage ? `${this.passageLength(difficulty_low_questions)}P/${difficulty_low_questions.length}Q` : difficulty_low_questions.length}/>
                  <Checkbox handleInputChange={this.handleInputChange} checked={this.state.difficulty_medium} name="difficulty_medium" label="Medium" pill={has_passage ? `${this.passageLength(difficulty_med_questions)}P/${difficulty_med_questions.length}Q` : difficulty_med_questions.length}/>
                  <Checkbox handleInputChange={this.handleInputChange} checked={this.state.difficulty_high} name="difficulty_high" label="High" pill={has_passage ? `${this.passageLength(difficulty_high_questions)}P/${difficulty_high_questions.length}Q` : difficulty_high_questions.length}/>
                </div>
              </div>
              <div className="field">
                <label className="label">Subjects</label>

                <ul className="columns_2">
                  {Object.entries(tags).map(([key, tag]) => {
                    let questionsWithTag = this.questionsWithTag(tag, tag_filter)
                    return (
                      <li key={key}>
                        <input className="is-checkradio" id={`tag_${key}`} name={key} type="checkbox" checked={checkedTags.get(key) || ''} onChange={this.handleChecked} value={key}/>
                        <label className="has-text-weight-bold" htmlFor={`tag_${key}`}>
                          {key}
                          <span className="tag is-light is-rounded">{has_passage ? `${this.passageLength(questionsWithTag)}P/${questionsWithTag.length}Q` : questionsWithTag.length}</span>
                        </label>
                        <ul>
                          {
                            tag.map((child) => {
                              let childWithTag = tag_filter.filter(question => question.tag_id === child.id)
                              return (
                                <li key={child.name}>
                                  <input className="is-checkradio" id={`tag_${child.id}`} name={child.name} type="checkbox" checked={checkedTags.get(child.id) || ''} onChange={this.handleChecked} value={child.id}/>
                                  <label htmlFor={`tag_${child.id}`}>
                                    {child.name}
                                    <span className="tag is-light is-rounded">{has_passage ? `${this.passageLength(childWithTag)}P/${childWithTag.length}Q` : childWithTag.length}</span>
                                  </label>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </li>
                    );
                  })}
                </ul>                    
              </div>          
              <div className="field">
                <label className="label"> 
                  Number of Questions
                  <span className="tag is-light is-rounded">Max: {max_number_of_questions}</span>
                </label>
                <div className="control number_of_questions_control">
                  <input name="number_of_questions" value={this.state.number_of_questions} onChange={this.handleNumberOfQuestions} className={errors.number_of_questions.length > 0 ? "is-danger input" : "input"} type="text" required maxLength="2" min="1" max={max_number_of_questions}/>
                  {errors.number_of_questions.length > 0 &&
                    <p className='help is-danger'>{errors.number_of_questions}</p>}
                  {myErrors.number_of_questions && 
                    <p className='help is-danger'>{myErrors.number_of_questions}</p>}                 
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-link">
                    Create Test
                  </button>
                </div>
              </div>
            </form>
          </div>
        </>
      );
    }    

  }      
}

export default NewTest;