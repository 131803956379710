import React from "react";

const strongRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})");
const mediumRegex = new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.{8,})");

class PasswordStrength extends React.Component {

  constructor() {
    super();
    this.state = {
      passwordColor: "",
      strength: ""
    }
    this.analyze = this.analyze.bind(this);
  }

  analyze(event) {
    if(strongRegex.test(event.target.value)) {
      this.setState({ passwordColor: "is-success" });
      this.setState({ strength: "100" });
    } else if(mediumRegex.test(event.target.value)) {
      this.setState({ passwordColor: "is-warning" });
      this.setState({ strength: "66" });
    } else {
      this.setState({ passwordColor: "is-danger" });
      this.setState({ strength: "33" });
    }  

  }

  render() {
    const {passwordColor, strength} = this.state
    return (
      <>
        <label htmlFor={this.props.name} className="label">{this.props.label}*</label>
        <input name={this.props.name} ref={this.props.custref} id={this.props.name} type="password" className={this.props.errorClass} onChange={e => {this.analyze(e); this.props.onChange(e)}} />
        { strength &&
          <>
            <p className="help">Password strength:</p>
            <progress className={`progress is-small ${passwordColor}`} value={strength} max="100">90%</progress>
          </>
        }      
      </>
    );
  }

}

export default PasswordStrength;