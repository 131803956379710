import React from "react";
import {centsToDollars} from './Utils'
import {Link} from "react-router-dom";

class Cart extends React.Component { 
  constructor(props) {
    super(props);
    this.state = {
      myErrors: {
        cart: ''
      }      
    };
    this.handleChange = this.handleChange.bind(this);
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }     

  render() {
    const products = this.props.products
    const {myErrors} = this.state
    return (
      <section className="section">
        <div className="container">
          <h1 className="title">
            Cart
          </h1>
          <div className="columns">
            <div className="column is-6">
              {products.length > 0
                ?
                  <>
                    <table className="table is-bordered">
                      <thead>
                        <tr>
                          <th>Plan Name</th>
                          <th>Length</th>
                          <th>Price</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {products.map((product, index) => (
                          <tr key={product.id}>
                            <td>{product.name}</td>
                            <td>{product.plan_length} Days</td>
                            <td>{centsToDollars(product.price)}</td>
                            <td><a onClick={(e) => this.props.removeFromCart(product.id, e)}>Remove</a></td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    <Link to="/checkout" className="button is-primary"><strong>Continue To Checkout</strong></Link>
                    {myErrors.cart &&
                      <p className="help is-danger">{myErrors.cart}</p>
                    }                  
                  </>
                : <p>No items in cart.</p>
              }
            </div>
          </div>
        </div>
      </section>
    ); 
  }
}

export default Cart;