import React from 'react';
import { ElementsConsumer } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";

const InjectedCheckoutForm = (props) => (
  <ElementsConsumer>
    {({stripe, elements}) => (
      <CheckoutForm stripe={stripe} elements={elements} products={props.products} removeFromCart={props.removeFromCart} history={props.history}/>
    )}
  </ElementsConsumer>
);

export default InjectedCheckoutForm;