import React from "react";
import Latex from "./Latex";
import ReactHtmlParser from 'react-html-parser';

const Tutor = props => {
  return (
    <div className="tutor_wrapper block">
      <nav className="level tutor">
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Correct Answer</p>
            <p className="title">{props.correctOption}</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            <p className="heading">Answered Correctly</p>
            <p className="heading">(All Users)</p>
            <p className="title">{props.percentageCorrect}%</p>
          </div>
        </div>
        <div className="level-item has-text-centered">
          <div>
            {props.isAverage 
              ?
                <>
                  <p className="heading">Avg. Time Spent</p>
                  <p className="heading">(Yours)</p>
                </>
              :
                <p className="heading">Time Spent</p>
            }
            <p className="title">{props.timeSpent}s</p>
          </div>
        </div>
        {/*
          <div className="level-item has-text-centered">
            <div>
              <p className="heading">Last Updated</p>
              <p className="title">12/3/2020</p>
            </div>
          </div>
        */}
      </nav>
      {props.answerExplanation &&
        <>
          <div className="tabs">
            <ul>
              <li className="is-active"><a>Explanation</a></li>
            </ul>
          </div>
          <Latex>
            <div className="is_tertiary_font content">
              {ReactHtmlParser(props.answerExplanation)}
            </div>
          </Latex>
        </>
      }
    </div>
  )
}


export default Tutor