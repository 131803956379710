import React from "react";

const CustomPrompt = props => {

  return (
    <div className={`modal ${props.visible ? "is-active" : ""}`}>
      <div className="modal-background"></div>
      <div className="modal-content">
        <article className="message is-warning">
          <div className="message-header">
            <p>Warning</p>
            <button className="delete" aria-label="delete" onClick={(e) => { props.onCancel(); props.stopTimerIfModal();}}></button>
          </div>
          <div className="message-body">
            <div className="level">
              Any unsaved changes will be lost, are you sure you want to leave?
            </div>
            <div className="buttons are-small is-right">
              <button className="button is-success" onClick={(e) => { props.onCancel(); props.checkSkippedQuestions(e);}}>Score Test</button>
              <button className="button" onClick={props.leaveTestAndSave}>Save Without Scoring</button>
              <button className="button" onClick={props.onConfirm}>Don't Save</button>
            </div>
          </div>
        </article>
      </div>
    </div>
  );
}

export default CustomPrompt