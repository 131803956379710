import React from "react";

const QuestionListModal = props => {
  const {questions} = props;

  // This adds commas to list
  const questionIds = questions.map((question, i) => <span key={i}>
    {i > 0 && ", "}
    {question.ancestry}
  </span>
  );  
  return (
    <div className={`modal custom_prompt_modal ${props.showModal === "questionList" ? "is-active" : ""}`}>
      <div className="modal-background" onClick={props.toggleModal}></div>
      <div className="modal-content">
        <article className="message is-light">
          <div className="message-header">
            <p>Question List</p>
          </div>
          <div className="message-body">
            {questionIds}
          </div>
        </article>
      </div>
      <button className="modal-close is-large" aria-label="close" onClick={props.toggleModal}></button>
    </div>
  )
}


export default QuestionListModal