import React, { useState, useEffect } from 'react';
import { updateUser } from './updateUser'
import PasswordChange from "./PasswordChange";
// import EditBilling from "./EditBilling";
import PaymentEvents from "./PaymentEvents";
import { useForm } from 'react-hook-form'
import {handleResponse} from './Utils'
import Loading from "./Loading";
import { useHistory } from 'react-router-dom';

// We use the Map construct for checkedTags for flexibility and convenience of setting and getting values
const EditUser = props => {
  const { register, errors, getValues, handleSubmit, reset } = useForm();

  const [id, setId] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [test_date, setTestDate] = useState("");
  const [study_start, setStudyStart] = useState("");
  const [password, setPassword] = useState("");
  const [billing, setBilling] = useState("");
  const [myErrors, setMyErrors] = useState({});
  const [activeTab, setActiveTab] = useState("Profile");

  const history = useHistory()

  useEffect(() => {
    async function fetchData() {
      let token = "Bearer " + localStorage.getItem("jwt");
      const result = await fetch('/api/v1/users/current', { method: 'GET', headers: {'Authorization': token }})
      .then((user) => handleResponse(user))
      .catch(error => {
        props.history.push("/")
        props.setNotification({error: error})
      }) 
      reset(result);
    }
    fetchData();
  }, [reset]);  

  const onSubmit = data => {
    const request = {"user": {"first_name": data.first_name, "last_name": data.last_name, "test_date": data.test_date, "password": data.password, "study_start": data.study_start}};
    updateUser(request, data.id)
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors['responseErrors'] = res.error
        setMyErrors(myErrors)
      }else {
        props.setUserState(res)
        props.setNotification({notice: "Your profile has been updated."})
        history.push("/dashboard")
      }
    })
  }

  if (getValues().id === undefined) {
    return (
      <Loading/>   
    )
  } else {
    return (
      <>
        <h1 className="title">My Account</h1>
        <div className="box">
          <div className="tabs">
            <ul>
              <li onClick={() => setActiveTab("Profile")} className={activeTab === "Profile" ? "is-active" : ""}><a>Profile</a></li>
              <li onClick={() => setActiveTab("Password")} className={activeTab === "Password" ? "is-active" : ""}><a>Change Password</a></li>
              {/*<li onClick={() => setActiveTab("Payments")} className={activeTab === "Payments" ? "is-active" : ""}><a>Payments</a></li>*/}
              {billing === true &&
                <li onClick={() => setActiveTab("Billing")} className={activeTab === "Billing" ? "is-active" : ""}><a>Billing</a></li>
              }
            </ul>
          </div>
          {activeTab === "Profile" &&
            <div className="columns">
              <div className="column is-one-third">
                <div className="field">
                  <label className="label">Email</label>
                  <span>{props.user.email}</span>
                </div>
                <form onSubmit={handleSubmit(onSubmit)}>
                  <input ref={register} name="id" id="id" type="hidden"/>
                  <div className="field">
                    <label htmlFor="first_name" className="label">First Name</label>
                    <input ref={register({ 
                      required: 'First name is required',
                      validate: {
                        isAlphanumeric: (value) => {
                          return value.match(/^\w+$/) || 'Please use alphanumeric characters only'
                        },
                      }
                      })} 
                      name="first_name" id="first_name" type="text" className={errors.first_name ? "is-danger input" : "input"} onChange={e => setFirstName(e.target.value)}/>
                    {errors.first_name && (
                      <p className="help is-danger">{errors.first_name.message}</p>
                    )}                              
                  </div>
                  <div className="field">
                    <label htmlFor="last_name" className="label">Last Name</label>
                    <input ref={register({ 
                      required: 'Last name is required',
                      validate: {
                        isAlphanumeric: (value) => {
                          return value.match(/^\w+$/) || 'Please use alphanumeric characters only'
                        },
                      }                
                      })} 
                    name="last_name" id="last_name" type="text" className={errors.last_name ? "is-danger input" : "input"} onChange={e => setLastName(e.target.value)} />
                    {errors.last_name && (
                      <p className="help is-danger">{errors.last_name.message}</p>
                    )}                              
                  </div>
                  {/*
                  <div className="field">
                    <label htmlFor="study_start" className="label">Study Plan Start Date</label>
                    <input ref={register} name="study_start" id="study_start" type="date" className={errors.study_start ? "is-danger input" : "input"} onChange={e => setStudyStart(e.target.value)}/>
                    {errors.study_start && (
                      <p className="help is-danger">{errors.study_start.message}</p>
                    )}
                  </div> 
                  */}                 
                  <div className="field">
                    <label htmlFor="test_date" className="label">Test Date</label>
                    <input ref={register({ 
                      required: 'Test date is required'
                      })}  
                    name="test_date" id="test_date" type="date" className={errors.test_date ? "is-danger input" : "input"} onChange={e => setTestDate(e.target.value)}/>
                    {errors.test_date && (
                      <p className="help is-danger">{errors.test_date.message}</p>
                    )}
                  </div>
                  <div className="field">
                    <label htmlFor="password_field" className="label">Current Password*</label>
                    <input name="password" ref={register({ required: 'Password is required'})} id="password_field" type="password" className="input" onChange={e => setPassword(e.target.value)} />                              
                    {errors.password && (
                      <p className="help is-danger">{errors.password.message}</p>
                    )}
                  </div>                
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-link">Update</button>
                    </div>
                    {myErrors.responseErrors &&
                      <p className="help is-danger">{myErrors.responseErrors}</p>
                    }              
                  </div>  
                </form>                                               
              </div>
            </div>
          }
          {activeTab === "Password" &&
            <PasswordChange id={getValues().id} history={props.history} setNotification={props.setNotification}></PasswordChange>           
          }
          {activeTab === "Payments" &&
            <PaymentEvents history={props.history} setNotification={props.setNotification} user={props.user}></PaymentEvents>           
          }
        </div>
      </>
    );
  }      
}
export default EditUser;