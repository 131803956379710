export const createFlashcard = (flashcard) => {
  let token = "Bearer " + localStorage.getItem("jwt")
  return fetch("/api/v1/flashcards/create", {
    method: "POST",
    headers: {
      "Authorization": token,
      "Content-Type": "application/json",
      'Accept': 'application/json'
    },
    body: JSON.stringify(flashcard)
  })
  .then(res => res.json())
}

