import React, { useState } from 'react';
import DemoForm from './DemoForm'
import { useForm } from 'react-hook-form'

// We use the Map construct for checkedTags for flexibility and convenience of setting and getting values
const DemoEmail = (props) => {
  const { register, errors, handleSubmit } = useForm();

  const [email, setEmail] = useState("");
  const [myErrors, setMyErrors] = useState({});
  const [showModal, setShowModal] = useState("");
  const [showSuccessModal, setShowSuccessModal] = useState("");  

  const onSubmit = data => { 
    setShowModal("is-active")
  }

  return (
    <>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className={`field has-addons ${props.justify}`}>
{/*          <div className="control">
            <input ref={register({ 
              required: 'Please enter a valid email',
              pattern: {
                value: /^(('[\w-\s]+')|([\w-]+(?:\.[\w-]+)*)|('[\w-\s]+')([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                message: "Invalid email format"
              }
            })} 
            name="email" value={email} type="email" className={`input is-rounded ${props.size} ${props.inputClass} ${errors.email ? "is-danger input" : "input"}`} onChange={e => setEmail(e.target.value)} placeholder="Email"/>
            {myErrors.email &&
              <p className="help is-danger">{myErrors.email}</p>
            }
            {errors.email && (
              <p className="help is-danger">{errors.email.message}</p>
            )}
          </div>*/}
          <div className="control">
            <button type="submit" className={`button is-rounded demo_button ${props.size} ${props.buttonClass ? props.buttonClass : "is-black"}`}>Request a demo</button>
          </div>
        </div>
      </form>
      <div className={`modal demo_modal ${showModal}`}>
        <div className="modal-background" onClick={e => setShowModal("")}></div>
        <div className="modal-content">
          <DemoForm email={email} setShowModal={setShowModal} setEmail={setEmail} setShowSuccessModal={setShowSuccessModal}/>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={e => setShowModal("")}></button>
      </div>
      <div className={`modal has-text-left ${showSuccessModal}`}>
        <div className="modal-background" onClick={e => setShowSuccessModal("")}></div>
        <div className="modal-content">
          <div className="box">
            <div className="section">
              <h1 className="title is-spaced">
                Thank you for your demo request! 
              </h1>
              <div className="content">
                <p>Please check your inbox for a confirmation email.</p>
              </div>
              <button className="button is-dark" onClick={e => setShowSuccessModal("")}>Close</button>
            </div>
          </div>
        </div>
        <button className="modal-close is-large" aria-label="close" onClick={e => setShowSuccessModal("")}></button>
      </div>       
    </>   
  );
}

export default DemoEmail;