import React from "react"
import {Helmet} from "react-helmet"
import CookieConsent from "react-cookie-consent"

export default ({ pageMeta, children }) => (
  <>
    <Helmet defer={false}>
      <title>{`Prepform | ${pageMeta.title}`}</title>
      <meta name="description" content={pageMeta.description} />
      <meta name="keywords" content={pageMeta.keywords.join(',')} />
    </Helmet>
    <CookieConsent
      buttonText="Got it!"
      style={{ background: "#1E252D" }}
      buttonStyle={{ background: "#ffffff", borderRadius: "9999px" }}
    >    
      This site uses cookies to ensure you get the best experience.
    </CookieConsent>
  </>
)