export const updatePasswordReset = (request) => {
  return fetch("/api/v1/password/reset", {
    method: 'POST',
    body: JSON.stringify(request),     
    headers: {'Content-Type': 'application/json',
              'Accept': 'application/json',
    }
  })
  .then(res => res.json())
}

