import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye } from '@fortawesome/free-solid-svg-icons';
import Loading from "./Loading";
import {handleResponse} from './Utils'

class Flashcards extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      flashcards: [],
      isLoaded: false,
      showFront: true,
      showBack: false,
      showTags: true,
      showAction: true,
      showDisplayPanel: false
    };
    this.toggleDisplayPanel = this.toggleDisplayPanel.bind(this);
    this.toggleVisibility = this.toggleVisibility.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt")
    const url = "/api/v1/flashcards/index";
    fetch(url, { method: 'GET', headers: {'Authorization': token }})
    .then((flashcard) => handleResponse(flashcard))
    .then(response => this.setState({ flashcards: response.user_flashcards, first_flashcard_to_review_id: response.first_flashcard_to_review_id, isLoaded: true }))     
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }

  toggleDisplayPanel() {
    this.setState(prevState => ({
      showDisplayPanel: !prevState.showDisplayPanel
    }));    
  }  

  toggleVisibility(event) {
    event.persist();
    this.setState(prevState => ({ 
      [event.target.name]: !prevState[event.target.name]
    }));
  }         

  render() {
    const {flashcards, first_flashcard_to_review_id, showFront, showBack, showTags, showAction, showDisplayPanel} = this.state
    const {topic} = this.props.user

    const allFlashcards = flashcards.map((card, index) => (
      <tr key={index}>
        <td></td>
        {showFront &&
          <td>{card.front}</td>
        }
        {showBack &&
          <td>{card.back}</td>
        }
        {showTags &&
          <td>{card.tags.join(', ')}</td>
        }
        {showAction &&
          <td>
            <Link to={`/flashcards/${card.id}/edit`}>Edit</Link>         
          </td>
        }
      </tr>
    ));
    if (this.state.isLoaded === false) {
      return (
        <Loading/>    
      )
    } else {
      return (
        <>
          <div className="button_wrapper flashcards_level">
            <div>
              <h1 className="title mb-0">Flashcards</h1>
              <p className="tertiary_gray mb-5">{topic}</p>
            </div>
            <div className="buttons">
              <Link to="/flashcards/new" className="button is-primary">Create Flashcard</Link>
              {first_flashcard_to_review_id
                ?
                  <Link to={`/flashcards/${first_flashcard_to_review_id}`} className="button">Review</Link>
                :
                  <button to={`/flashcards/${first_flashcard_to_review_id}`} className="button" disabled>No Flashcards to Review</button>
              }              
            </div>
          </div>
          {showDisplayPanel &&
            <nav className="panel display_panel">
              <label className="panel-block">
                <input type="checkbox" checked={showFront} name="showFront" onChange={this.toggleVisibility}/>
                Front
              </label>
              <label className="panel-block">
                <input type="checkbox" checked={showBack} name="showBack" onChange={this.toggleVisibility}/>
                Back
              </label>
              <label className="panel-block">
                <input type="checkbox" checked={showTags} name="showTags" onChange={this.toggleVisibility}/>
                Tags
              </label>
              <label className="panel-block">
                <input type="checkbox" checked={showAction} name="showAction" onChange={this.toggleVisibility}/>
                Action
              </label>                                                                
              <div className="panel-block">
                <button className="button is-link is-outlined is-small is-fullwidth" onClick={this.toggleDisplayPanel}>
                  Done
                </button>
              </div>
            </nav>          
          }
          {flashcards.length > 0
            ?
              <div className="box">
                <div className="table-container">
                  <table className="table is-narrow is-fullwidth">
                    <thead>
                      <tr>
                        <th onClick={this.toggleDisplayPanel}><FontAwesomeIcon icon={faEye}/></th>
                        {showFront &&
                          <th>Front</th>
                        }
                        {showBack &&
                          <th>Back</th>
                        }
                        {showTags &&
                          <th>Tags</th>
                        }
                        {showAction &&
                          <th>Action</th>
                        }
                      </tr>
                    </thead>
                    <tbody>
                      {allFlashcards}
                    </tbody>
                  </table>            
                </div>
              </div>
            :
              <article className="message is-dark">
                <div className="message-body">
                  There are no flashcards.
                </div>
              </article>             
          }      
        </>
      );
    }    
  }    

}
export default Flashcards;