import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown, faCheckCircle, faTachometerAlt, faPen, faStar } from '@fortawesome/free-solid-svg-icons';
import { faCircle } from '@fortawesome/free-regular-svg-icons';
import {handleResponse} from './Utils'
import Accordion from "./Accordion";
import Tabs from "./Tabs";
import Graphs from "./Graphs";
import { createDiagnosticTest } from './createDiagnosticTest'
import { createTest } from './createTest'
import moment from 'moment/moment.js'
import Loading from "./Loading";

import { VictoryStack, VictoryBar, VictoryPie, VictoryLabel } from 'victory';

class OverallPerformance extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      showAltMessage: false,
      itemsToShow: 5,
      expanded: false
    };
    this.toggleDiagnosticModal = this.toggleDiagnosticModal.bind(this);
    this.showMore = this.showMore.bind(this);
    this.onSubmitDailyPractice = this.onSubmitDailyPractice.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt");
    const questions_url = "/api/v1/questions/index";
    const graphs_url = "/api/v1/tests/graphs";

    Promise.all([fetch(questions_url, { method: 'GET', headers: {'Authorization': token }}), fetch(graphs_url, { method: 'GET', headers: {'Authorization': token }})])
    .then(([res1, res2]) => Promise.all([handleResponse(res1), handleResponse(res2)]))
    .then(([res1, res2]) => this.setState({ ...res1, activeQuestions: res1.questions, tests: res2}, () => {this.checkDiagnostic();}))
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })    
  }

  onStartDiagnosticTest(questions, event) {
    event.preventDefault();
    const body = {question_mode: "Diagnostic", custom_question_ids: questions};

    this.setState({
      isLoaded: false,
      showAltMessage: true
    }, () => 
      createDiagnosticTest(body)
      .then(res => {
        if(res.error) {
          let myErrors = {}
          myErrors.practice_test = res.error
          this.setState({myErrors: myErrors})
        }else {
          this.toggleDiagnosticModal();
          this.props.history.push(`/tests/${res.id}`);
        }
      })
      .catch(error => console.log(error.message))
    );
  }  

  checkDiagnostic() {
    const {diagnostic_status, topic} = this.props.user
    if (diagnostic_status[topic] === -1) {
      this.setState({
        showDiagnosticModal: true,
        isLoaded: true
      }, () => {
        window.setMobileTable('.wide_table')
      })
    } else {
      this.setState({
        isLoaded: true
      }, () => {
        window.setMobileTable('.wide_table')
      })      
    } 
  }

  onSubmitDailyPractice(question_ids, event, tag_name) {
    event.preventDefault();
    const body = {question_mode: "Recommendation", custom_question_ids: question_ids, tag_name: tag_name};

    this.setState({
      isLoaded: false,
      showAltMessage: true
    }, () => 
      createTest(body)
      .then(res => {
        if(res.error) {
          let myErrors = {}
          myErrors.custom_test = res.error
          this.setState({myErrors: myErrors})
        }else {
          this.props.history.push(`/tests/${res.id}`);
        }
      })
      .catch(error => console.log(error.message))
      );
  }  

  toggleDiagnosticModal() {
    this.setState(prevState => ({
      showDiagnosticModal: !prevState.showDiagnosticModal
    }));
  }

  renderTable(){
    const {itemsToShow} = this.state
    const {tagBreakdown} = this.state.activeQuestions

    return(
      <Accordion>
        {Object.entries(tagBreakdown).slice(0, itemsToShow).map(([k, v]) => {
          const groupCorrect = v.correct || 0
          const groupIncorrect = v.incorrect || 0
          const groupSkipped = v.skipped || 0
          const groupPrediction = v.prediction || 0
          const groupTotalQuestions = groupCorrect + groupIncorrect + groupSkipped        
          const groupDataset = this.transformData(v.barChartData); 
          return (
            <tr 
              key={k}
              label={k}
              correct={groupCorrect}
              incorrect={groupIncorrect}
              skipped={groupSkipped}
              prediction={groupPrediction}
              totalQuestions={groupTotalQuestions}
              dataset={groupDataset}
            > 
              {
                Object.entries(v.tags).map(([key,value]) => {
                  const correct = value.correct || 0
                  const incorrect = value.incorrect || 0
                  const skipped = value.skipped || 0
                  const prediction = value.prediction
                  const totalQuestions = correct + incorrect + skipped
                  if (totalQuestions === 0) {
                    return (
                      <tr key={key}>
                        <td></td>
                        <td>{key}</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                      </tr>                  
                    )
                  } else {
                    const dataset = this.transformData(value.barChartData); 
                    return (
                      <tr key={key} style={{ backgroundColor: "#E6E7E9" }}>
                        <td></td>       
                        <td>
                          {key}        
                          <VictoryStack
                            horizontal
                            height={6}
                            width={100}
                            padding={0}
                            colorScale={["#00b45a", "#363636", "#7a7a7a"]}
                          >
                            {dataset.map((data, i) => {
                              return <VictoryBar barWidth={3} data={data} key={i}/>;
                            })}
                          </VictoryStack>            
                        </td>
                        <td>{correct} ({this.toPercent(correct, totalQuestions)})</td>
                        <td>{incorrect} ({this.toPercent(incorrect, totalQuestions)})</td>
                        <td>{skipped} ({this.toPercent(skipped, totalQuestions)})</td>
                        <td className="has-text-weight-bold">
                          {`${((prediction)*100).toFixed(1)}%`}
                        </td>
                      </tr> 
                    )                 
                  }
                })
              }
            </tr>
          )
        })}
      </Accordion>
    )   
  }

  showMore() {
    const {itemsToShow} = this.state
    const {tagBreakdown} = this.state.activeQuestions
    let tagBreakdownLength = Object.keys(tagBreakdown).length
    itemsToShow === 5 ? (
      this.setState({ 
        itemsToShow: tagBreakdownLength,
        expanded: true 
      }, () => {
        window.setMobileTable('.wide_table')
      })
    ) : (
      this.setState({
        itemsToShow: 5,
        expanded: false
      }, () => {
        window.setMobileTable('.wide_table')
      })
    )
  }   

  transformData(dataset) {
    const totals = dataset[0].map((data, i) => {
      return dataset.reduce((memo, curr) => {
        return memo + curr[i].y;
      }, 0);
    });
    return dataset.map((data) => {
      return data.map((datum, i) => {
        return { x: datum.x, y: (datum.y / totals[i]) * 100  };
      });
    });
  }

  toPercent(length, totalLength) {
    return (
      ((length/totalLength) * 100).toFixed() + "%"
    )
  }

  render() {
    if (this.state.isLoaded === false) {
      return (
        <Loading messages={this.state.showAltMessage ? ["Finding optimal questions...", "Creating practice test..."] : ["Gathering data...", "Loading acount..."]}/>   
      )
    } else {
      const {average_time_all, average_time_calc, average_time_no_calc, account_average_time_all, account_average_time_calc, account_average_time_no_calc, correct_to_incorrect, incorrect_to_correct, incorrect_to_incorrect, questionCount, tagBreakdown } = this.state.activeQuestions
      const {tests, studyStart, showDiagnosticModal, diagnostic_test, expanded, questions_to_log_length, first_question_to_log_id, mistakes_to_review_length, first_mistake_to_review_id, lessons_to_review_length, first_lesson_to_review_id, resume_diagnostic_id, user_name, todo_length, questions} = this.state
      const {test_date, diagnostic_status, topic} = this.props.user
      let tagBreakdownLength = Object.keys(tagBreakdown).length

      const correctLength = questionCount.correct
      const incorrectLength = questionCount.incorrect
      const skippedLength = questionCount.skipped
      const questionLength = correctLength + incorrectLength + skippedLength
      return (
        <div id="dashboard">
          <div className={`modal diagnostic_modal ${showDiagnosticModal ? "is-active" : ""}`}>
            <div className="modal-background" onClick={this.toggleDiagnosticModal}></div>
            <div className="modal-content">
              <div className="box">
                <div className="content has-text-centered">
                  <h2 className="has-text-weight-light">Welcome to <span className="title_font">Prepform</span></h2>
                  <div className="block">
                    <p>To create your personalized study plan, let's start with a diagnostic test. It will take about 30 minutes to complete, and for best results, you should treat it like a real exam.</p>
                  </div>
                  <button className="button is-primary" onClick={(e) => this.onStartDiagnosticTest(diagnostic_test, e)}>Start Diagnostic</button>
                </div>
              </div>
            </div>
            <button className="modal-close is-large" onClick={this.toggleDiagnosticModal} aria-label="close"></button>
          </div>       
          <div className="level">
            <div>
              <h1 className="title mb-0">Dashboard</h1>
              <p className="tertiary_gray mb-5">{topic}</p>             
            </div>
            {/*
            <div>
              {moment(test_date).diff(moment(), 'days') > 0
                ?
                  <p className="subtitle"><span className="has-text-weight-bold"> {moment(test_date).diff(moment(), 'days')} </span> days until test</p>
                :
                  <p className="subtitle"><span className="has-text-weight-bold"> 0 </span> days until test</p>
              }
            </div>            
            */}
          </div>
          {user_name === "Guest" &&
            <div className="columns">
              <div className="column">
                <div className="box is-medium has-background-warning has-text-white">     
                  <div className="flex_item">
                    <p className="has-text-weight-bold has-text-black">
                      You're currently signed in as a Guest. To save your progress,&nbsp;
                      <Link to="/signup" className="signup_link"><span className="has-text-weight-bold is-primary">sign up for a free account!</span></Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          }                                    
          <div className="columns">
            <div className="column">
              <div className="box has-background-dark-green">
                <p className="title is-6 has-text-white">AI Recommendation</p>
                {diagnostic_status[topic] === 1 
                  ?
                    questions_to_log_length > 0 
                      ?
                        <>
                          <p className="block has-text-light">
                            {"Log " + questions_to_log_length + " Mistakes"}
                          </p>
                          <Link className="button is-primary" to={`/mistakes/new?question_id=${first_question_to_log_id}`}>
                            Start
                          </Link>
                        </>
                      :
                        mistakes_to_review_length > 0 
                          ?
                            <>
                              <p className="block has-text-light">
                                {"Review " + mistakes_to_review_length + " Mistakes"}
                              </p>
                              <Link className="button is-primary" to={`/mistakes/${first_mistake_to_review_id}`}>
                                Start
                              </Link>                                                          
                            </>
                          :
                            questions.questions_to_retry_ids.length > 0 
                              ?
                                <>
                                  <p className="block has-text-light">
                                    {"Retry " + questions.questions_to_retry_ids.length + " Mistakes"}
                                  </p>
                                  <button className="button is-primary" onClick={(e) => this.onSubmitDailyPractice(questions.questions_to_retry_ids, e, "Retry Mistakes")}>
                                    Start
                                  </button>                                                          
                                </>
                              :
                                questions.more_diagnostic.length > 0 
                                  ?
                                    <>
                                      <p className="block has-text-light">
                                        More Diagnostic
                                      </p>
                                      <button className="button is-primary" onClick={(e) => this.onSubmitDailyPractice(questions.more_diagnostic, e, "More Diagnostic")}>
                                        Start
                                      </button>                                                          
                                    </>
                                  :
                                    <>
                                      <p className="block has-text-light">
                                        {questions.ai_practice_test_tag.name}
                                      </p>
                                      <button className="button is-primary" onClick={(e) => this.onSubmitDailyPractice(questions.ai_practice_test_ids, e, questions.ai_practice_test_tag.name)}>
                                        Start
                                      </button>                                                          
                                    </>
                  :
                    diagnostic_status[topic] === -1 
                      ?
                        <>
                          <p className="block has-text-light">Let's start with a diagnostic test. It will take about 30 minutes to complete, and for best results, you should treat it like a real exam.</p>
                          <button className="button is-primary" onClick={(e) => this.onStartDiagnosticTest(diagnostic_test, e)}>Start Diagnostic</button>
                        </>
                      :
                        <>
                          <p className="block has-text-light">Let's start with a diagnostic test. It will take about 30 minutes to complete, and for best results, you should treat it like a real exam.</p>
                          <Link to={`/tests/${resume_diagnostic_id}`} className="button is-warning">Resume Diagnostic</Link> 
                        </>
                }                          
              </div>
            </div>
            {topic === "Math" &&
              <>
                <div className="column">
                  <div className="box has-background-dark-green">
                    <p className="title is-6 has-text-white">AI Recommendation REVIEW MISTAKES</p>
                    {diagnostic_status[topic] === 1 
                      ?
                        mistakes_to_review_length > 0 
                          ?
                            <>
                              <p className="block has-text-light">
                                {"Review " + mistakes_to_review_length + " Mistakes"}
                              </p>
                              <Link className="button is-primary" to={`/mistakes/${first_mistake_to_review_id}`}>
                                Start
                              </Link>                                                          
                            </>
                          :
                            questions.questions_to_retry_ids.length > 0 
                              ?
                                <>
                                  <p className="block has-text-light">
                                    {"Retry " + questions.questions_to_retry_ids.length + " Mistakes"}
                                  </p>
                                  <button className="button is-primary" onClick={(e) => this.onSubmitDailyPractice(questions.questions_to_retry_ids, e, "Retry Mistakes")}>
                                    Start
                                  </button>                                                          
                                </>
                              :
                                questions.more_diagnostic.length > 0 
                                  ?
                                    <>
                                      <p className="block has-text-light">
                                        More Diagnostic
                                      </p>
                                      <button className="button is-primary" onClick={(e) => this.onSubmitDailyPractice(questions.more_diagnostic, e, "More Diagnostic")}>
                                        Start
                                      </button>                                                          
                                    </>
                                  :
                                    <>
                                      <p className="block has-text-light">
                                        {questions.ai_practice_test_tag.name}
                                      </p>
                                      <button className="button is-primary" onClick={(e) => this.onSubmitDailyPractice(questions.ai_practice_test_ids, e, questions.ai_practice_test_tag.name)}>
                                        Start
                                      </button>                                                          
                                    </>
                      :
                        diagnostic_status[topic] === -1 
                          ?
                            <>
                              <p className="block has-text-light">Let's start with a diagnostic test. It will take about 30 minutes to complete, and for best results, you should treat it like a real exam.</p>
                              <button className="button is-primary" onClick={(e) => this.onStartDiagnosticTest(diagnostic_test, e)}>Start Diagnostic</button>
                            </>
                          :
                            <>
                              <p className="block has-text-light">Let's start with a diagnostic test. It will take about 30 minutes to complete, and for best results, you should treat it like a real exam.</p>
                              <Link to={`/tests/${resume_diagnostic_id}`} className="button is-warning">Resume Diagnostic</Link> 
                            </>
                    }                          
                  </div>
                </div>
                <div className="column">
                  <div className="box has-background-dark-green">
                    <p className="title is-6 has-text-white">AI Recommendation RETRY QUESTIONS</p>
                    {diagnostic_status[topic] === 1 
                      ?
                        questions.questions_to_retry_ids.length > 0 
                          ?
                            <>
                              <p className="block has-text-light">
                                {"Retry " + questions.questions_to_retry_ids.length + " Mistakes"}
                              </p>
                              <button className="button is-primary" onClick={(e) => this.onSubmitDailyPractice(questions.questions_to_retry_ids, e, "Retry Mistakes")}>
                                Start
                              </button>                                                          
                            </>
                          :
                            questions.more_diagnostic.length > 0 
                              ?
                                <>
                                  <p className="block has-text-light">
                                    More Diagnostic
                                  </p>
                                  <button className="button is-primary" onClick={(e) => this.onSubmitDailyPractice(questions.more_diagnostic, e, "More Diagnostic")}>
                                    Start
                                  </button>                                                          
                                </>
                              :
                                <>
                                  <p className="block has-text-light">
                                    {questions.ai_practice_test_tag.name}
                                  </p>
                                  <button className="button is-primary" onClick={(e) => this.onSubmitDailyPractice(questions.ai_practice_test_ids, e, questions.ai_practice_test_tag.name)}>
                                    Start
                                  </button>                                                          
                                </>
                      :
                        diagnostic_status[topic] === -1 
                          ?
                            <>
                              <p className="block has-text-light">Let's start with a diagnostic test. It will take about 30 minutes to complete, and for best results, you should treat it like a real exam.</p>
                              <button className="button is-primary" onClick={(e) => this.onStartDiagnosticTest(diagnostic_test, e)}>Start Diagnostic</button>
                            </>
                          :
                            <>
                              <p className="block has-text-light">Let's start with a diagnostic test. It will take about 30 minutes to complete, and for best results, you should treat it like a real exam.</p>
                              <Link to={`/tests/${resume_diagnostic_id}`} className="button is-warning">Resume Diagnostic</Link> 
                            </>
                    }                          
                  </div>
                </div>
                <div className="column">
                  <div className="box has-background-dark-green">
                    <p className="title is-6 has-text-white">AI Recommendation MORE DIAGNOSTIC OR NEW TOPIC</p>
                    {diagnostic_status[topic] === 1 
                      ?
                        questions.more_diagnostic.length > 0 
                          ?
                            <>
                              <p className="block has-text-light">
                                More Diagnostic
                              </p>
                              <button className="button is-primary" onClick={(e) => this.onSubmitDailyPractice(questions.more_diagnostic, e, "More Diagnostic")}>
                                Start
                              </button>                                                          
                            </>
                          :
                            <>
                              <p className="block has-text-light">
                                {questions.ai_practice_test_tag.name}
                              </p>
                              <button className="button is-primary" onClick={(e) => this.onSubmitDailyPractice(questions.ai_practice_test_ids, e, questions.ai_practice_test_tag.name)}>
                                Start
                              </button>                                                          
                            </>
                      :
                        diagnostic_status[topic] === -1 
                          ?
                            <>
                              <p className="block has-text-light">Let's start with a diagnostic test. It will take about 30 minutes to complete, and for best results, you should treat it like a real exam.</p>
                              <button className="button is-primary" onClick={(e) => this.onStartDiagnosticTest(diagnostic_test, e)}>Start Diagnostic</button>
                            </>
                          :
                            <>
                              <p className="block has-text-light">Let's start with a diagnostic test. It will take about 30 minutes to complete, and for best results, you should treat it like a real exam.</p>
                              <Link to={`/tests/${resume_diagnostic_id}`} className="button is-warning">Resume Diagnostic</Link> 
                            </>
                    }                          
                  </div>
                </div>
              </>
            }
            <div className="column">
              <div className="box has-background-white">
                <p className="title is-6">Predicted Score</p>
                {questions.predicted_score
                  ?
                    <p>
                      <span className="title is-1">{questions.predicted_score}</span>
                      / {questions.perfect_score}
                    </p>
                  :
                    <p>
                      No data to display.
                    </p>
                }
              </div>             
            </div>
          </div>
          {/*
          <div className="columns is-mobile is-multiline mb-0">
            <div className="column is-half-mobile">
              <div>
                <p className="heading">Predicted Score</p>
                <p className="title is-1">1480</p>
              </div>            
            </div>
            <div className="column is-half-mobile">
              <div>
                <p className="heading">Predicted Math</p>
                <p className="title is-1">800</p>
              </div>            
            </div>
            <div className="column is-half-mobile">
              <div>
                <p className="heading">Predicted Reading & Writing</p>
                <p className="title is-1">680</p>
              </div>            
            </div>
            <div className="column is-half-mobile">
              <div>
                <p className="heading">{`Days Until Test (${moment(test_date).format("M/D/Y")})`}</p>
                {moment(test_date).diff(moment(), 'days') > 0
                  ?
                    <p className="title is-1">{moment(test_date).diff(moment(), 'days')}</p>
                  :
                    <p className="title is-1">0</p>
                }
              </div>            
            </div>                                    
          </div>
          */}
       
          {questionLength > 0
            ?
              <>
                <div className="box has-background-white">
                  <h6 className="title is-6">Performance by Subject</h6>
                  <div className="table-container wide_table">
                    <table className="table is-fullwidth accordion_table is-hoverable">
                      <thead>
                        <tr>
                          <th></th>
                          <th>Name</th>
                          <th>Correct</th>
                          <th>Incorrect</th>
                          <th>Skipped</th>
                          <th>Mastery</th>
                        </tr>
                      </thead>
                      <tbody>
                        {this.renderTable()}
                      </tbody>
                    </table>
                    {tagBreakdownLength > 5 &&
                      expanded ? (
                        <button className="button is-primary is-small" onClick={this.showMore}>
                          <span>Show less</span>
                          <span className="icon">
                            <FontAwesomeIcon icon={faAngleUp}/>
                          </span>
                        </button>
                      ) : (
                        <button className="button is-primary is-small" onClick={this.showMore}>
                          <span>Show more</span>
                          <span className="icon">
                            <FontAwesomeIcon icon={faAngleDown}/>
                          </span>
                        </button>
                      )
                    }
                  </div> 
                </div>                     
                <div className="columns is-mobile is-multiline">
                  <div className="column is-full-mobile is-full-tablet is-one-third-desktop">
                    <div className="box has-background-white">
                      <h6 className="title is-6">Percentage Breakdown</h6>
                      <svg viewBox="0 0 400 400" className="pie_svg">
                        <VictoryPie
                          standalone={false}
                          width={400}
                          height={400}
                          innerRadius={130}
                          style={{ labels: { display: "none"} }}
                          colorScale={["#00b45a", "#363636", "#7a7a7a"]}
                          data={[
                            { x: this.toPercent(correctLength, questionLength), y: correctLength },
                            { x: this.toPercent(incorrectLength, questionLength), y: incorrectLength },
                            { x: this.toPercent(skippedLength, questionLength), y: skippedLength }
                          ].filter(Boolean)}                                      
                        />
                        <VictoryLabel
                          textAnchor="middle"
                          style={[{ fontSize:60, fontFamily: "Helvetica, sans-serif"}, {fontSize:30, fontFamily: "Helvetica, sans-serif"}]}
                          x={200} y={200}
                          text={[questionLength, "Questions"]}
                        />
                      </svg>
                      <nav className="level is-mobile">
                        <div className="level-item has-text-centered correct_level">
                          <div>
                            <p className="title">{this.toPercent(correctLength, questionLength)}</p>
                            <p className="heading">{`Correct (${correctLength})`}</p>
                          </div>
                        </div>
                        <div className="level-item has-text-centered incorrect_level">
                          <div>
                            <p className="title">{this.toPercent(incorrectLength, questionLength)}</p>
                            <p className="heading">{`Incorrect (${incorrectLength})`}</p>
                          </div>
                        </div>
                        <div className="level-item has-text-centered skipped_level">
                          <div>
                            <p className="title">{this.toPercent(skippedLength, questionLength)}</p>
                            <p className="heading">{`Skipped (${skippedLength})`}</p>
                          </div>
                        </div>
                      </nav>
                    </div>
                  </div>
                  {/*
                  <div className="is-divider-vertical"></div>
                */}

                  <div className="column">
                    <div className="box has-background-white">
                      <h6 className="title is-6">Average Time</h6>
                      <table className="table is-fullwidth">
                        <thead>
                          <tr>
                            <th>Question Type</th>
                            <th>You</th>
                            <th>Others</th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td>Overall</td>
                            <td>{average_time_all}s</td>
                            <td>{account_average_time_all}s</td>
                          </tr>
                          {/*
                          {activeTab === "Math" &&
                            <>
                              <tr>
                                <td>With Calculator</td>
                                <td>{average_time_calc}s</td>
                                <td>{account_average_time_calc}s</td>
                              </tr>
                              <tr>
                                <td>Without Calculator</td>
                                <td>{average_time_no_calc}s</td>
                                <td>{account_average_time_no_calc}s</td>
                              </tr>
                            </>
                          }
                        */}
                        </tbody>
                      </table>
                    </div>
                  </div>
                  {/*
                  <div className="is-divider-vertical"></div>
                */}
                  <div className="column">
                    <div className="box has-background-white">
                      <h6 className="title is-6">Answer Changes</h6>
                      <table className="table is-fullwidth">
                        <tbody>
                          <tr>
                            <td>Correct to Incorrect</td>
                            <td><span className="tag is-light">{correct_to_incorrect}</span></td>
                          </tr>
                          <tr>
                            <td>Incorrect to Correct</td>
                            <td><span className="tag is-light">{incorrect_to_correct}</span></td>
                          </tr>
                          <tr>
                            <td>Incorrect to Incorrect</td>
                            <td><span className="tag is-light">{incorrect_to_incorrect}</span></td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </>
            :
              <article className="message">
                <div className="message-body">
                  No data to display.
                </div>                  
              </article>
          }                                      
          <div className="columns">
            <div className="column">
              <Graphs tests={tests}/>
            </div>
          </div>
        </div>
      );
    }    
  }    

}
export default OverallPerformance;