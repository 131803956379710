import React from "react";

const RadioButton = (props) => {
  return (
    <>
      <input className="is-checkradio" name={props.name} id={props.id} onChange={props.changed} value={props.value} type="radio" checked={props.isSelected} />
      <label htmlFor={props.id}>
        {props.label}
        {(props.pill || props.pill >= 0) &&
          <span className="tag is-light is-rounded">{props.pill}</span> 
        }
      </label>
    </>
  );
}

export default RadioButton;