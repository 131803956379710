import React, { useState } from 'react';
import { createProspect } from './createProspect'
import { useForm } from 'react-hook-form'
import { useHistory } from "react-router-dom";

// We use the Map construct for checkedTags for flexibility and convenience of setting and getting values
const DemoForm = props => {
  const { register, errors, handleSubmit } = useForm();

  let history = useHistory();

  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [company_name, setCompanyName] = useState("");
  const [myErrors, setMyErrors] = useState({});   

  const onSubmit = data => { 
    const request = {"prospect": {"first_name": data.first_name, "last_name": data.last_name, "email": data.email, "company_name": data.company_name}}
    createProspect(request)
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors['email'] = res.error
        setMyErrors(myErrors)
      }else {
        props.setShowModal("");
        props.setEmail("");
        props.setShowSuccessModal("is-active");
        setFirstName("");
        setLastName("");
        setCompanyName("");
      }
    })
  } 

  return (
    <div className="box">
      <div className="columns has-text-left">
        <div className="column">
          <section className="section">
            <div className="title_font block">Prepform</div>
            <h1 className="title">Interested in learning more?</h1>
            <div className="content">
              <p>Tell us a little about yourself to arrange a 20-minute intro call.</p>
            </div>
          </section>
        </div>
        <div className="column has-background-light">
          <div className="box demo_form_wrapper">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="field">
                <div className="control">
                  <label htmlFor="first_name" className="label is-small">First Name*</label>
                  <input ref={register({ 
                    required: 'Please enter a first name',

                    })} 
                    name="first_name" value={first_name} type="text" className={`input is-small ${errors.first_name ? "is-danger" : ""}`} onChange={e => setFirstName(e.target.value)}/>
                  {errors.first_name && (
                    <p className="help is-danger">{errors.first_name.message}</p>
                  )}                              
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label htmlFor="last_name" className="label is-small">Last Name*</label>
                  <input ref={register({ 
                    required: 'Please enter a last name',
                    validate: {
                      isAlphanumeric: (value) => {
                        return value.match(/^\w+$/) || 'Please use alphanumeric characters only'
                      },
                    }                
                    })} 
                  name="last_name" value={last_name} type="text" className={`input is-small ${errors.last_name ? "is-danger" : ""}`} onChange={e => setLastName(e.target.value)} />
                  {errors.last_name && (
                    <p className="help is-danger">{errors.last_name.message}</p>
                  )}                              
                </div>
              </div>                          
              <div className="field">
                <div className="control">
                  <label htmlFor="email" className="label is-small">Email*</label>
                  <input ref={register({ 
                    required: 'Please enter an email',
                    pattern: {
                      value: /^(('[\w-\s]+')|([\w-]+(?:\.[\w-]+)*)|('[\w-\s]+')([\w-]+(?:\.[\w-]+)*))(@((?:[\w-]+\.)*\w[\w-]{0,66})\.([a-z]{2,6}(?:\.[a-z]{2})?)$)|(@\[?((25[0-5]\.|2[0-4][0-9]\.|1[0-9]{2}\.|[0-9]{1,2}\.))((25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\.){2}(25[0-5]|2[0-4][0-9]|1[0-9]{2}|[0-9]{1,2})\]?$)/i,
                      message: "Invalid email format"
                    }
                  })} 
                  name="email" value={props.email} type="email" className={`input is-small ${errors.email ? "is-danger" : ""}`} onChange={e => props.setEmail(e.target.value)} />
                  {myErrors.email &&
                    <p className="help is-danger">{myErrors.email}</p>
                  }
                  {errors.email && (
                    <p className="help is-danger">{errors.email.message}</p>
                  )}              
                </div>
              </div>
              <div className="field">
                <div className="control">
                  <label htmlFor="company_name" className="label is-small">Company Name*</label>
                  <input ref={register({ 
                    required: 'Please enter a company name'                
                    })} 
                  name="company_name" value={company_name} type="text" className={`input is-small ${errors.company_name ? "is-danger" : ""}`} onChange={e => setCompanyName(e.target.value)} />
                  {errors.company_name && (
                    <p className="help is-danger">{errors.company_name.message}</p>
                  )}                              
                </div>
              </div>                
              <div className="field">
                <div className="control">
                  <button type="submit" className="button is-black">Submit Request</button>
                </div>
              </div>                     
            </form>
          </div>
        </div>
      </div>
    </div>
  );      
}
export default DemoForm;