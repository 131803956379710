import React from "react";
import { Link } from "react-router-dom";
import Navigation from './Navigation';
import DemoEmail from "./DemoEmail"
import ImgWithFallback from './ImgWithFallback';
import logo from '../images/logo.webp';
import logo_png from '../images/logo.png';
import TryNow from "./TryNow"
import moment from 'moment/moment.js'

export default ({children}) => (
  <div className="nav_layout">
    <Navigation products={children.props.products} match={children.props.match} logo={logo}/>
    {children}
    <footer className="footer">
      <div className="container">
        <div className="logo_wrapper mb-5">
          <ImgWithFallback
            src={logo}
            fallback={logo_png}
            alt="Prepform"
          />
        </div>
        <h1 className="title is-1 has-text-black">Let's get started</h1>
        <div className="footer_email">
          <div className="buttons">
            <Link to="/signup" className="button is-primary is-rounded is-medium">Sign Up</Link>
            <TryNow buttonClass="is-medium">Try now</TryNow>
          </div>
        </div>
        <div className="footer_item_wrapper">
          <div className="footer_item">
            &#169;&nbsp;
            {moment().year()} Prepform
          </div>
          <div className="footer_item"><a className="email_link" href="mailto:support@prepform.com">support@prepform.com</a></div>
          <div className="footer_item"><Link to="/privacy" className="email_link">Privacy Policy</Link></div>
          <div className="footer_item"><Link to="/terms" className="email_link">Terms of Service</Link></div>
        </div>
      </div>
    </footer>    
  </div>
);