export const createCartTransfer = () => {
  let token = "Bearer " + localStorage.getItem("jwt")
  return fetch("/api/v1/carts/transfer_cart", {
    method: "PUT",
    headers: {
      "Authorization": token,
      "Content-Type": "application/json",
      'Accept': 'application/json'
    }
  })
  .then(res => res.json())
}