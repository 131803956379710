import React from "react";
import Tabs from './Tabs'
import { VictoryChart, VictoryLine, VictoryAxis, VictoryLegend, VictoryScatter, VictoryTooltip, VictoryGroup, VictoryVoronoiContainer } from 'victory';
import moment from 'moment/moment.js'

const dateTabList = [
  {
    name: "1W"
  }, {
    name: "2W"
  }, {
    name: "1M"
  }, {
    name: "3M"
  }, {
    name: "All"
  }
];
class Graphs extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: "Test",
      dateActiveTab: "All"
    };
    this.changeActiveTab = this.changeActiveTab.bind(this);
    this.changeDateActiveTab = this.changeDateActiveTab.bind(this);
  }

  renderScoreDate(owner, determinant) {
    let {dateActiveTab} = this.state
    let {tests} = this.props
    const testData = []

    if (dateActiveTab === "1W") {
      tests = tests.filter(t => new Date(t.created_at) > new Date(new Date().setDate(new Date().getDate() - 7)))
    } else if (dateActiveTab === "2W") {
      tests = tests.filter(t => new Date(t.created_at) > new Date(new Date().setDate(new Date().getDate() - 14)))
    } else if (dateActiveTab === "1M") {
      tests = tests.filter(t => new Date(t.created_at) > new Date(new Date().setDate(new Date().getDate() - 30)))
    } else if (dateActiveTab === "3M") {
      tests = tests.filter(t => new Date(t.created_at) > new Date(new Date().setDate(new Date().getDate() - 90)))
    }

    if (tests.length !== 0) {
      for (var x = 0; x < tests.length; x++) {
        // Make sure tests is taken
        const subTestData = {}
        if (determinant === "date") {
          subTestData["x"] = new Date(tests[x].created_at).toString()
        } else if (determinant === "testId") {
          subTestData["x"] = tests[x].id.toString()
        }
        if (owner === "yours") {
          subTestData["y"] = tests[x].score
        } else if (owner === "average") {
          subTestData["y"] = tests[x].account_average_score * 100
        }
        testData.push(subTestData)
      }
    }

    return(testData)
  }

  changeActiveTab(tab, e) {
    e.preventDefault();
    this.setState({ 
      activeTab: tab
    });
  }  

  changeDateActiveTab(tab, e) {
    e.preventDefault();
    this.setState({ 
      dateActiveTab: tab
    });
  }      

  render() {
    const {activeTab, dateActiveTab} = this.state
    const {tests} = this.props
    const ids = tests.map((test) => test.id)
    return (
      <>                        
        {tests.length > 0
          ?
            <div className="box">
              <div className="buttons">
                <Tabs tabList={dateTabList}
                      activeTab={dateActiveTab}
                      changeActiveTab={this.changeDateActiveTab}
                />
                <div className="tabs is-toggle mb-5 ml-2 is-small">
                  <ul>
                    <li className={activeTab === "Test" ? "is-active" : ""}>
                      <a onClick={(e) => this.changeActiveTab("Test", e)}>
                        <span>By Test</span>
                      </a>
                    </li>
                    <li className={activeTab === "Date" ? "is-active" : ""}>
                      <a onClick={(e) => this.changeActiveTab("Date", e)}>
                        <span>By Date</span>
                      </a>
                    </li>
                  </ul>
                </div> 
              </div>                            
              {activeTab === "Test"
                ?
                  <>
                    <VictoryChart
                      height={140}
                      padding={{ top: 2, bottom: 30, right: 10, left: 24 }}
                      containerComponent={<VictoryVoronoiContainer/>}
                    >
                      <VictoryGroup
                        labels={({ datum }) => `${datum.x}: ${datum.y.toFixed()}%`}
                        labelComponent={
                          <VictoryTooltip
                            style={{ fontSize: 6, fontFamily: "Helvetica, sans-serif"}}
                            flyoutStyle={{
                              stroke: "none",
                              fill: "#E9EDF0"
                            }}                            
                          />
                        }
                        data={this.renderScoreDate("average", "testId")}
                      >                               
                        <VictoryLine
                          style={{
                            data: { stroke: "#0D0D0D", strokeWidth: 1 }
                          }}
                        />
                        <VictoryScatter
                          size={2}
                          style={{ data: { fill: "#0D0D0D" } }}
                        />
                      </VictoryGroup>                      
                      <VictoryGroup
                        labels={({ datum }) => `${datum.x}: ${datum.y.toFixed()}%`}
                        labelComponent={
                          <VictoryTooltip
                            style={{ fontSize: 6, fontFamily: "Helvetica, sans-serif"}}
                            flyoutStyle={{
                              stroke: "none",
                              fill: "#E9EDF0"
                            }}                            
                          />
                        }
                        data={this.renderScoreDate("yours", "testId")}
                      >              
                        <VictoryLine
                          style={{
                            data: { stroke: "#00b45a", strokeWidth: 1 }
                          }}
                        />
                        <VictoryScatter
                          size={2}
                          style={{ data: { fill: "#00b45a" } }}                  
                        />
                      </VictoryGroup>       
                      <VictoryAxis
                        dependentAxis
                        style={{
                          axis: {stroke: "#756f6a"},
                          ticks: {stroke: "grey", size: 5},
                          tickLabels: {fontSize: 6, padding: 5, fontFamily: "Helvetica, sans-serif"}
                        }}
                        domain={[-1,101]}
                      />
                      <VictoryAxis
                        fixLabelOverlap={true}
                        style={{
                          axis: {stroke: "#756f6a"},
                          ticks: {stroke: "grey", size: 5},
                          tickLabels: {fontSize: 6, padding: 5, fontFamily: "Helvetica, sans-serif"},
                        }}
                        // tickFormat={t => t.toFixed()}
                        tickValues={this.renderScoreDate("yours", "testId").length > 0 ? ids : [null]}
                        tickCount={10}
                      />                                                            
                    </VictoryChart>
                    <div className="block victory_legend">
                      <span className="your_score is-size-7">Your Score</span>
                      <span className="average_score is-size-7">Average Score</span>
                    </div>
                  </>      
                :
                  <>
                    <VictoryChart
                      height={140}
                      padding={{ top: 2, bottom: 30, right: 10, left: 24 }}
                      containerComponent={<VictoryVoronoiContainer/>}
                    >
                      <VictoryGroup
                        labels={({ datum }) => `${datum.y.toFixed()}% ${moment(new Date(datum.x)).format('M/D/YY, h:mm A')}`}
                        labelComponent={
                          <VictoryTooltip
                            style={{ fontSize: 6, fontFamily: "Helvetica, sans-serif" }}
                            flyoutStyle={{
                              stroke: "none",
                              fill: "#E9EDF0"
                            }}                            
                          />
                        }
                        data={this.renderScoreDate("average", "date")}
                      >                               
                        <VictoryLine
                          style={{
                            data: { stroke: "#0D0D0D", strokeWidth: 1 }
                          }}
                        />
                        <VictoryScatter
                          size={2}
                          style={{ data: { fill: "#0D0D0D" } }}
                        />
                      </VictoryGroup>                              
                      <VictoryGroup
                        labels={({ datum }) => `${datum.y.toFixed()}% ${moment(new Date(datum.x)).format('M/D/YY, h:mm A')}`}
                        labelComponent={
                          <VictoryTooltip
                            style={{ fontSize: 6, fontFamily: "Helvetica, sans-serif" }}
                            flyoutStyle={{
                              stroke: "none",
                              fill: "#E9EDF0"
                            }}                            
                          />
                        }
                        data={this.renderScoreDate("yours", "date")}
                      >              
                        <VictoryLine
                          style={{
                            data: { stroke: "#00b45a", strokeWidth: 1 }
                          }}
                        />
                        <VictoryScatter
                          size={2}
                          style={{ data: { fill: "#00b45a" } }}                  
                        />
                      </VictoryGroup>
                      <VictoryAxis
                        dependentAxis
                        style={{
                          axis: {stroke: "#756f6a"},
                          ticks: {stroke: "grey", size: 5},
                          tickLabels: {fontSize: 6, padding: 5, fontFamily: "Helvetica, sans-serif"}
                        }}
                        domain={[-1,101]}
                      />
                      <VictoryAxis
                        fixLabelOverlap={true}
                        style={{
                          axis: {stroke: "#756f6a"},
                          ticks: {stroke: "grey", size: 5},
                          tickLabels: {fontSize: 6, padding: 5, fontFamily: "Helvetica, sans-serif"}
                        }}
                        tickValues={this.renderScoreDate("yours", "date").length === 0 ? [null] : []}
                        tickFormat={t => t === null ? "" : moment(new Date(t)).format('M/D/YY[\n]h:mm A')}
                      />                                                                           
                    </VictoryChart>
                    <div className="block victory_legend">
                      <span className="your_score is-size-7">Your Score</span>
                      <span className="average_score is-size-7">Average Score</span>
                    </div>                    
                  </>
              }
            </div>        
          :
            <article className="message">
              <div className="message-body">
                No graphs to display.
              </div>
            </article>            
        }
      </>
    );    
  }    

}
export default Graphs;