import React, { Component } from "react"
import { createConfirmUser } from './createConfirmUser'
import Loading from "./Loading";

class ConfirmUser extends Component {
  constructor() {
    super()
    this.state = {
      error: null,     
    };
  } 

  componentDidMount () {
    let token = new URLSearchParams(window.location.search).get("token")
    const body = { token }

    createConfirmUser(body)
    .then(res => {
      if(res.error) {
        this.props.history.push("/");
        this.props.setNotification({error: res.error})        
      }else {
        this.props.history.push("/login");
        this.props.setNotification({notice: "Great! You've confirmed your account. Please sign in below."})
      }
    })
    .catch(error => this.setState({ error }));      
  }      

  render() {
    return (
      <Loading/>     
    )
  }
}

export default ConfirmUser;