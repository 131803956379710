import React, { useLayoutEffect, useRef, useState } from "react";
import { VictoryBar, VictoryChart, VictoryLabel } from 'victory';
import ImgWithFallback from './ImgWithFallback';
import dashboard from '../images/dashboard.webp';
import dashboard_png from '../images/dashboard.png';
import adaptive_plan from '../images/adaptive_plan.webp';
import adaptive_plan_png from '../images/adaptive_plan.png';

const ProductData = (props) => {
  const [show, doShow] = useState({
    itemOne: false,
    itemTwo: false,
    itemThree: false             
  });


  const refOne = useRef(null),
        refTwo = useRef(null),
        refThree = useRef(null);   
  
  useLayoutEffect(() => {
    const topPos = element => element.getBoundingClientRect().top;

    const div1Pos = topPos(refOne.current),
          div2Pos = topPos(refTwo.current),
          div3Pos = topPos(refThree.current);

    const onScroll = () => {
      const scrollPos = window.scrollY + window.innerHeight;

      if (div1Pos < scrollPos) {
        doShow(state => ({ ...state, itemOne: true }));
      } 
      if (div2Pos < scrollPos) {
        doShow(state => ({ ...state, itemTwo: true }));
      } 
      if (div3Pos < scrollPos) {
        doShow(state => ({ ...state, itemThree: true }));
      }                                      
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);  
  
  return (
    <>
      <section className="hero is-medium ai_hero">
        <div className="hero-body">
          <div className="container">
            <h1 className="title is-1 is-spaced">
              A tutor for every student
            </h1>
            <h2 className="subtitle">
              It's difficult for students to know what to study, how long to study, and whether they've mastered a concept. <strong className="has-text-primary">Blend AI</strong> is their guide.
            </h2>
          </div>
        </div>
      </section>
      <section className="section auc_section">
        <div className="container">
          <div className="columns is-align-items-center">
            <div className="column is-5 pt-0">
              <h1 className="title is-spaced">
                Blend AI predicts the skills a student should target to improve their score
              </h1>
              <p className="subtitle is_secondary_font">
                The Blend AI model accounts for forgetting, guessing, the order of answers, and a student's baseline knowledge. It has the highest predictive accuracy among competing models, with an AUC of 0.7892.
              </p>              
            </div>
            <div className="column is-offset-1">
              <div>
                <VictoryChart
                  domainPadding={{ x: 40, y: 0 }}
                >
                  <VictoryLabel
                          x={225}
                          y={25}
                          textAnchor="middle"
                          text="AUC"
                        />                
                  <VictoryBar
                    domain={{y: [0.7, 0.8]}}
                    style={{data: {fill: ({ datum }) => datum.fill}}}
                    labels={({ datum }) => datum.y}
                    data={[
                      { x: "PFA", y: 0.7093, fill: "#1E252D" },
                      { x: "PFAI", y: 0.7174, fill: "#1E252D" },
                      { x: "PFAL", y: 0.7259, fill: "#1E252D" },
                      { x: "Gong", y: 0.7512, fill: "#1E252D" },
                      { x: "GongT", y: 0.7558, fill: "#1E252D" },
                      { x: "PFAT", y: 0.7609, fill: "#1E252D" },
                      { x: "BPAI", y: 0.7892, fill: "#00b45a" }                    
                    ]}
                  />                 
                </VictoryChart>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="section has-background-light pt-120 pb-120">
        <div className="container">
          <div className="columns">
            <div className="column pt-0">
              <h1 className="title mb-4">
                Promotes self-directed learning
              </h1>
            </div>
          </div>
          <div className="columns is-variable is-8">
            <div className="column">
              <div className="green_hr"></div>
              <p className="title is-5 has-text-weight-bold mb-2">
                Personalize education
              </p>
              <p className="is_secondary_font">
                Blend AI adapts to each student and predicts the questions, lessons, and hints that will improve their score.
              </p>          
            </div>
            <div className="column">
              <div className="green_hr"></div>
              <p className="title is-5 has-text-weight-bold mb-2">
                Increase motivation
              </p>
              <p className="is_secondary_font">
                Spaced-repetition helps students retain information and stay motivated.
              </p> 
            </div>
            <div className="column">
              <div className="green_hr"></div>
              <p className="title is-5 has-text-weight-bold mb-2">
                Improve speed of learning
              </p>
              <p className="is_secondary_font">
                The optimal path for each student means they reach their goals in less time.
              </p>
            </div>                        
          </div>
        </div>      
      </section> 
      <section className="section has-background-dark-green  pt-120">
        <div className="container">
          <div className="columns is-align-items-center">
            <div className="column is-5">
              <p className="tertiary_title has-text-primary">Data-driven</p>
              <h1 className="title is-spaced has-text-white">Score prediction and data visualization</h1>
              <h2 className="subtitle is_secondary_font">Helps students identify their strengths, weaknesses, and repeated mistakes.</h2>
              <h2 className="subtitle is_secondary_font">Tracks time for students to improve their speed.</h2>
              <h2 className="subtitle is_secondary_font">Compares performance with other students' results.</h2>
            </div>                     
            <div ref={refOne} className={`column is-offset-1 no_opacity ${show.itemOne ? " add_opacity" : ""}`}>
              <div className="desktop_box">
                <div className="desktop_box_bg"></div>
                <ImgWithFallback
                  className="box"
                  src={dashboard}
                  fallback={dashboard_png}
                  alt="Score prediction and data visualization"
                />
              </div>
            </div>          
          </div>         
        </div>
      </section>
      <section className="section has-background-dark-green  pb-120">
        <div className="container">
          <div className="columns is-align-items-center is-hidden-mobile">
            <div ref={refTwo} className={`column is-6 no_opacity ${show.itemTwo ? " add_opacity" : ""}`}>
              <div className="desktop_box">
                <div className="desktop_box_bg"></div>
                <ImgWithFallback
                  className="box"
                  src={adaptive_plan}
                  fallback={adaptive_plan_png}
                  alt="Schedule reviews"
                />
              </div>
            </div>
            <div className="column is-offset-1">
              <p className="tertiary_title has-text-primary">Spaced-repetition</p>
              <h1 className="title is-spaced has-text-white">Schedule reviews</h1>
              <h2 className="subtitle is_secondary_font">Reviewing questions is one of the most effective test prep strategies.</h2>
              <h2 className="subtitle is_secondary_font">Mindlessly cramming questions is ineffective, so Blend AI structures a cadence for reviews.</h2>
              <h2 className="subtitle is_secondary_font">Spaced-repetition helps students internalize what they've learned.</h2>
            </div>           
          </div>
          <div className="columns is-align-items-center is-hidden-tablet">
            <div className="column">
              <p className="tertiary_title has-text-primary">Spaced-repetition</p>
              <h1 className="title is-spaced has-text-white">Schedule reviews</h1>
              <h2 className="subtitle is_secondary_font">Reviewing questions is one of the most effective test prep strategies.</h2>
              <h2 className="subtitle is_secondary_font">Mindlessly cramming questions doesn't work, so Blend AI structures a cadence for reviews.</h2>
              <h2 className="subtitle is_secondary_font">Spaced-repetition helps students internalize what they've learned.</h2>
            </div>
            <div ref={refThree} className={`column no_opacity ${show.itemThree ? " add_opacity" : ""}`}>
              <div className="desktop_box">
                <div className="desktop_box_bg"></div>
                <ImgWithFallback
                  className="box"
                  src={adaptive_plan}
                  fallback={adaptive_plan_png}
                  alt="Schedule reviews"
                />
              </div>
            </div>            
          </div>          
        </div>
      </section>                                                              
      <section className="section pt-120 pb-120">
        <div className="container">
          <div className="columns is-4 is-variable is-align-items-center">
            <div className="column is-8">
              <h2 className="title">A New Approach to Intelligent Tutoring</h2>           
              <div className="block">
                <p>
                  Blend AI emerged from two student-modeling methods: Learning Factor Analysis and Performance Factor Analysis. LFA determines a student's mastery of a subject based on the amount of practice, while PFA accounts for student performance and tracks correct and incorrect answers.
                </p>
              </div>
              <div className="block">
                <p>
                  LFA's model is more consistent with real-world data than industry standards like Bayesian Knowledge Tracing. In one study, LFA applied to an award-winning BKT system saved students up to 30% tutoring time without hurting performance.
                </p>
              </div>              
              <div className="block pr-6 pl-6">
                <p className="title is-4">Students save up to 30% tutoring time without hurting performance.</p>
              </div>
              <div className="block">
                <p>
                  PFA also outperforms BKT on a variety of metrics. Its predictive accuracy is higher, and its parameter estimates more realistic, since it accounts for questions that require multiple skills to solve. Blend AI builds on this to account for forgetting, guessing, the order of answers, and the baseline knowledge of each student.
                </p>
              </div>              
              {/*<div className="block">
                <p>
                  Blend AI is built on two student-modeling methods: Learning Factor Analysis (LFA) and Performance Factor Analysis (PFA). LFA models a student's mastery of a subject as a power function of the amount of practice they have (
                  <a target="_blank" href="https://www.researchgate.net/publication/225127457_Learning_Factors_Analysis_-_A_General_Method_for_Cognitive_Model_Evaluation_and_Improvement">Cen et al., 2006</a>
                  ). PFA expands LFA to account for student performance by considering correct and incorrect answers (
                  <a target="_blank" href="https://www.researchgate.net/publication/221297435_Performance_Factors_Analysis_-_A_New_Alternative_to_Knowledge_Tracing">Pavlik et al., 2009</a>
                  ).
                </p>
              </div>
              <div className="block">
                <p>
                  In contrast, many Intelligent Tutoring Systems (ITS) use Bayesian Knowledge Tracing (BKT) to estimate student learning (
                  <a target="_blank" href="https://link.springer.com/article/10.1007%2FBF01099821">Corbett et al., 1994</a>
                  ). While BKT is a powerful tool, LFA's power function model is more consistent with real-world data than BKT's geometric function model (
                  <a target="_blank" href="https://books.google.com/books?id=wfxYPwQ3A20C&lpg=PA103&lr&pg=PA106#v=onepage&q&f=false">Nichols et al., 1995</a>
                  ). In one study, LFA applied to an award-winning, BKT-based ITS saved students 10-30% of time without hurting performance (
                  <a target="_blank" href="http://www.cs.cmu.edu/afs/cs/user/hcen/www/thesis_0.93.pdf">Cen, 2009</a>
                  ).
                </p>
              </div>              
              <div className="block pr-6 pl-6">
                <p className="title is-4">Students saved 10-30% of time without hurting performance.</p>
              </div>
              <div className="block">
                <p>
                  In addition, PFA "substantially outperforms" BKT on a variety of metrics (
                  <a target="_blank" href="https://www.researchgate.net/publication/220049829_How_to_Construct_More_Accurate_Student_Models_Comparing_and_Optimizing_Knowledge_Tracing_and_Performance_Factor_Analysis">Gong et al., 2011</a>
                  ). Not only is its predictive accuracy "considerably higher", its parameter estimates are more realistic, since it understands questions may require more than one skill to solve. Blend AI expands PFA to account for forgetting, guessing, the order of answers, and the baseline knowledge of each student.
                </p>
              </div>
            */}
              <div className="block pr-6 pl-6">
                <p className="title is-4">Blend AI accounts for forgetting, guessing, the order of answers, and the baseline knowledge of each student.</p>
              </div>
              <div className="block">
                <p>We compared our model with several competing models: the original PFA model, Gong's PFA model (which uses a decay factor to penalize older answers), a variation of Gong's PFA model that accounts for forgetting, and several versions of Blend AI that account for forgetting and student response time.</p>
              </div>
              <div className="block">
                <p>
                  We ran our tests using 
                  <a target="_blank" href="https://github.com/riiid/ednet"> EdNet</a>
                  , the largest publicly-available education dataset as of 2021, which has over 130 million student interactions.
                </p>
              </div>              
              {/*<div className="block">
                <p>
                  Blend AI uses the Elo rating system to determine the baseline knowledge of each student. Elo is a popular way to rank teams in sports according to their win percentage and the strength of their opponents. It has also shown to be an effective way to model a student's prior knowledge of a subject.
                </p>
              </div> 
              <div className="block">
                <p>
                  We use the sigmoid function to predict whether a student correctly answers a question. In our system, we modify this function to account for multiple-choice questions and the probability of guessing the correct answer.
                </p>
              </div>
              <div className="block">
                <p>
                  The parameter K determines how quickly the ratings change after new answers. To account for the changing number of answers over time, our system uses an uncertainty function (write out function) to use the number of total answers in the system to make the predictions more stable and accurate (cite the reference.)
                </p>
              </div>              
              <div className="block">
                <p>
                  Blend uses Gradient Descent to optimize parameters and maximize the predictive ability of our student models. Gradient Descent looks for a minimum by taking steps in the direction of the negative gradient. 
                </p>
              </div>
              */}                                                       
            </div>           
          </div>
        </div>
      </section>          
    </>
  );
}

export default ProductData;