import React from 'react';
import { updateUserTopic } from './updateUserTopic'
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEquals, faShapes, faSquareRootAlt, faPlay, faCalculator } from '@fortawesome/free-solid-svg-icons';
import {handleResponse, centsToDollars, jwtValid} from './Utils'

class ProductSubject extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      productsTopics: [],
      isLoaded: false,
      myErrors: {
        productSubject: ''
      }       
    }; 
    this.stripHtmlEntities = this.stripHtmlEntities.bind(this);
    this.updateUserTopic = this.updateUserTopic.bind(this);
  }

  componentDidMount() {
    if (jwtValid(localStorage.getItem('jwt'))) {
      let token = "Bearer " + localStorage.getItem("jwt")
      const url = "/api/v1/orders/auth-index";
      fetch(url, { method: 'GET', headers: {'Authorization': token }})
      .then((order) => handleResponse(order))
      .then(response => this.setState({ ...response, productsTopics: response.product_topics, isLoaded: true }))     
      .catch(error => {
        this.props.history.push("/")
        this.props.setNotification({error: error})
      })
    } else {
      const url = "/api/v1/orders/index";
      fetch(url, { method: 'GET'})
      .then((order) => handleResponse(order))
      .then(response => this.setState({ ...response, productsTopics: response.product_topics, isLoaded: true }))     
      .catch(error => {
        this.props.history.push("/")
        this.props.setNotification({error: error})
      })
    }
  }    

  stripHtmlEntities(str) {
    return String(str)
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  updateUserTopic(event, topic, subscribed=true) {
    event.preventDefault();
    
    /*This is for maintaining user topic after user logs in*/
    this.props.setUserState({topic: topic});
    
    /*This saves the new topic to user record*/
    const request = {"user": {"topic": topic}};

    // This is so UpgradePage maintains state on refresh
    localStorage.removeItem('localTopic');
    localStorage.setItem('localTopic', topic)
    updateUserTopic(request)
    .then(response => {
      if (response.error) {
        throw new Error("Network response was not ok.");
      } else {
        return response.json();
      }
    })    
    .then((response) => {
      if (subscribed) {
        this.props.history.push("/dashboard");
      } else {
        this.props.history.push("/upgrade");
      }
    })
    .catch(error => {
      this.props.history.push("/login");     
    });
  }  

  render() {
    const {myErrors, productsTopics} = this.state
    /*Checking for isLoaded here was making the page flash*/
    if (productsTopics.lenth === 0) {
      return (
        <p>Loading...</p>     
      )
    } else {
      return (    
        <>
          <section className="hero is-medium student_hero">
            <div className="hero-body">
              <div className="container">
                <h1 className="title is-spaced is-1">
                  Designed for you
                </h1>
                <h2 className="subtitle">
                  Adaptive learning and spaced-repetition help you learn more in less time.
                </h2>
              </div>
            </div>
          </section>    
          <section className="section">
            <div className="container">
              <div className="block">
                <div className="content">
                  <h3>NY Regents Mathematics</h3>
                  <p>Thousands of actual questions from the gold standard of high school exit exams, the NY Regents Exams.</p>
                </div>
                <div className="columns">
                  <div className="column">
                    <a className="bordered_box bb_primary" onClick={e => this.updateUserTopic(e, "Algebra 1")}>
                      <header className="bordered_box_header">
                        <span className="icon">
                          <FontAwesomeIcon icon={faEquals}/>
                        </span>          
                        <p className="bordered_box_title">Algebra 1</p>
                      </header>
                      <p className="has-text-black">1800+ questions</p>
                    </a>                
                  </div>
                  <div className="column">
                    <a className="bordered_box bb_primary" onClick={e => this.updateUserTopic(e, "Geometry")}>
                      <header className="bordered_box_header">
                        <span className="icon">
                          <FontAwesomeIcon icon={faShapes}/>
                        </span>          
                        <p className="bordered_box_title">Geometry</p>
                      </header>
                      <p className="has-text-black">900+ questions</p>
                    </a>                
                  </div>
                  <div className="column">
                    <a className="bordered_box bb_primary" onClick={e => this.updateUserTopic(e, "Algebra 2")}>
                      <header className="bordered_box_header">
                        <span className="icon">
                          <FontAwesomeIcon icon={faSquareRootAlt}/>
                        </span>          
                        <p className="bordered_box_title">Algebra 2</p>
                      </header>
                      <p className="has-text-black">1600+ questions</p>
                    </a>                
                  </div>
                  <div className="column">
                    <a className="bordered_box bb_primary" onClick={e => this.updateUserTopic(e, "Trigonometry")}>
                      <header className="bordered_box_header">
                        <span className="icon">
                          <FontAwesomeIcon icon={faPlay}/>
                        </span>          
                        <p className="bordered_box_title">Trigonometry</p>
                      </header>
                      <p className="has-text-black">550+ questions</p>
                    </a>                
                  </div>                
                </div>                           
              </div>             
              <div className="block">
                <div className="content">
                  <h3>SAT</h3>
                  <p>Questions are crafted by subject-matter experts and test concepts found on the actual exam.</p>
                </div>
                <div className="columns">
                  {
                    productsTopics.map((topic, index) => (
                      <div className="column is-one-quarter" key={index}>
                        <a className="bordered_box bb_info" onClick={(e) => this.updateUserTopic(e, topic.name, topic.paid_and_active)}>
                          <header className="bordered_box_header">
                            <span className="icon">
                              <FontAwesomeIcon icon={faCalculator}/>
                            </span>          
                            <p className="bordered_box_title">{topic.name}</p>
                          </header>
                          {topic.paid_and_active 
                            ?
                              <span className="tag fit_content is-info">Subscribed</span>
                            :
                              <span className="tag fit_content">Subscription Required</span>
                          }
                        </a>
                      </div>               
                    ))

                  }
                </div>                           
              </div>
            </div>
            {this.myErrors && 
              <p className='help is-danger'>{this.myErrors.productSubject}</p>
            }          
          </section>      
        </>
      );
    }
  }
}

export default ProductSubject;