import React, { Component } from "react"
import { Link } from "react-router-dom";
import { createCartTransfer } from './createCartTransfer'
import Loading from "./Loading";
import { updateUserTopic } from './updateUserTopic'

class Login extends Component {
  constructor() {
    super()
    this.state = {
      error: null,
      isLoaded: true
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.setJwtAndTransferCart = this.setJwtAndTransferCart.bind(this);
  }

  async setJwtAndTransferCart(data) {

    await localStorage.removeItem('guest');
    await localStorage.removeItem('valid_plans');
    await localStorage.setItem("jwt", data.jwt);

    {/*This uses user.topic set in ProductSubject and update topic for current_user*/}
    const topic = this.props.user.topic
    const request = {"user": {"topic": topic}};
    updateUserTopic(request)

    createCartTransfer()
    .then(res => {
      if(res.error) {
        this.setState({
          isLoaded: true
        });        
        let myErrors = {}
        myErrors.login = res.error
        this.setState({myErrors: myErrors})
      } else {
        this.setState({
          products: res.products
        }, () => {
          this.setState({
            isLoaded: true
          });
          {/*        
          if (res.plan_status === "valid_paid") {
            localStorage.setItem('plan_valid', true)
            if (res.user.topic) {
              this.props.history.push("/dashboard");
            } else {
              this.props.history.push("/for-students");
            }
          } else if (res.plan_status === "expired_paid") {
            this.props.history.push("/upgrade");
            this.props.setNotification({notice: "Your subscription is expired. Please upgrade your plan."});            
          } else if (res.plan_status === "no_plan") {
            if (this.state.products.length > 0) {
              this.props.history.push("/checkout");
            } else {
              this.props.history.push("/orders");
            }
            localStorage.setItem('plan_valid', true)
            if (res.user.topic) {
              this.props.history.push("/dashboard");
            } else {
              this.props.history.push("/for-students");
            }          
          }
        */}
          localStorage.setItem('valid_plans', JSON.stringify(res.topics_list_include_free))
          this.props.history.push("/for-students");
        });
      }
    })    
  }  

  handleSubmit (event) {
    event.preventDefault();
    this.setState({
      isLoaded: false
    });    
    const email = document.getElementById('email').value;
    const password = document.getElementById('password').value;
    const request = {"auth": {"email": email, "password": password}};
    fetch('/api/v1/user_token', {
      method: 'POST',
      body: JSON.stringify(request),     
      headers: {'Content-Type': 'application/json', 'Accept': 'application/json' }
    })
    .then(response => {
      if (response.ok) {
        return response.json()
      } else {
        this.setState({
          isLoaded: true
        });         
        throw new Error("Your email or password is incorrect. If this is a new account, please confirm by email.")
      }    
    })   
    .then(data => {
      this.setJwtAndTransferCart(data)
    })
    .catch(error => this.setState({ error }));
  }      

  render() {
    const {error, isLoaded} = this.state
    return (
      <>
        {isLoaded === false && 
          <Loading/>
        }       
        <section className="hero is-medium login_hero">
          <div className="hero-body">
            <div className="columns is-centered">
              <div className="column is-one-third">
                <div className="box has-background-dark-green">
                  <h1 className="title has-text-white mb-6">Welcome back!</h1>
                  <form onSubmit={this.handleSubmit}>
                    <div className="field">
                      <label htmlFor="email" className="label has-text-white">Email</label>
                      <input name="email" id="email" type="email" className="input" />
                    </div>
                    <div className="field">
                      <label htmlFor="password" className="label has-text-white">Password</label>
                      <input name="password" id="password" type="password" className="input" />
                      {error && 
                        <p className='help is-danger'>{error.message}</p>}                
                    </div>
                    <div className="field">
                      <div className="control">
                        <button type="submit" className="button is-link">Submit</button>
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">                    
                        <Link to="/signup">Sign Up</Link>
                      </div>
                    </div>           
                    <div className="field">
                      <div className="control">                    
                        <Link to="/forgot-password" className="is-text">Forgot Password?</Link>
                      </div>
                    </div>
                    <div className="field">
                      <div className="control">                    
                        <Link to="/resend-confirmation" className="is-text">Resend Confirmation</Link>
                      </div>
                    </div>              
                  </form>
                </div>
              </div>
            </div>
          </div>
        </section>
      </>
    );
  }
}

export default Login;