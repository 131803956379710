import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSmile, faFrown, faMeh } from '@fortawesome/free-regular-svg-icons';
import Loading from "./Loading";
import {handleResponse} from './Utils'

class Flashcard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      flipCard: false
    };

    this.flipCard = this.flipCard.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt")
    const { match: { params: { id } } } = this.props;
    fetch(`/api/v1/flashcards/${id}`, { method: 'GET', headers: {'Authorization': token }})
    .then((flashcard) => handleResponse(flashcard)) 
    .then(response => this.setState({ ...response, isLoaded: true }))
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }

  submitPerformanceRating(rating, event) {
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem("jwt")
    let {id, next_flashcard} = this.state

    fetch(`/api/v1/flashcards/${id}`, {
      method: 'put',
      body: JSON.stringify({flashcard: {performance_rating: rating}}),
      headers: { "Authorization": token, 'Content-Type': 'application/json' },
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })    
    .then((response) => {
      if (next_flashcard) {
        this.props.history.push(`/flashcards/${next_flashcard.id}`);
        this.setState({isLoaded: false}, () => this.componentDidMount());   
      } else {
        this.props.history.push("/flashcards");
      }
    })
    .catch(error => console.log('error', error));
  } 
  
  flipCard() {
    const flipCard = this.state.flipCard;
    this.setState({ flipCard: !flipCard });
  }      

  render() {
    const {id, front, back, flipCard, flashcard, rating_again, rating_hard, rating_good, rating_easy, status } = this.state
    const {topic} = this.props.user

    if (this.state.isLoaded === false) {
      return (
        <Loading/>    
      )
    } else {
      return (
        <>
          <div className="button_wrapper">
            <div>
              <h1 className="title mb-0">Flashcards</h1>
              <p className="tertiary_gray mb-5">{topic}</p>
            </div>
            <div className="buttons">
              <Link to="/flashcards/new" className="button is-primary">Create Flashcard</Link>
              <Link to="/flashcards" className="button">List</Link>
            </div>          
          </div>
          <div className="box">
            <div className="columns">
              <div className="column is-two-thirds">
                <div className={`flashcard_box ${flipCard && "flipped"}`} onClick={this.flipCard}>
                  <div className="box flashcard_content">
                    <div className="flashcard_front">
                      {front}
                      <div className="flashcard_instructions">Click card to flip</div>
                    </div>
                    <div className="flashcard_back">
                      {back}
                    </div>
                  </div>                  
                </div>
              </div>
            </div>
            <div className="columns">
              <div className="column is-two-thirds">
                <div className="buttons flashcard_nav">
                  <button className="button" onClick={(e) => this.submitPerformanceRating("again", e)} value="again">
                    <span className="icon">
                      <FontAwesomeIcon icon={faFrown}/>
                    </span>
                    <span>{rating_again}</span>
                  </button>
                  {status === "learned" && rating_again != rating_hard &&
                    <button className="button" onClick={(e) => this.submitPerformanceRating("hard", e)} value="hard">
                      <span className="icon">
                        <FontAwesomeIcon icon={faMeh}/>
                      </span>
                      <span>{rating_hard}</span>
                    </button>                  
                  }
                  <button className="button" onClick={(e) => this.submitPerformanceRating("good", e)} value="good">
                    <span className="icon">
                      <FontAwesomeIcon icon={faMeh}/>
                    </span>
                    <span>{rating_good}</span>
                  </button>
                  {status != "relearning" &&
                    <button className="button" onClick={(e) => this.submitPerformanceRating("easy", e)} value="easy">
                      <span className="icon">
                        <FontAwesomeIcon icon={faSmile}/>
                      </span>
                      <span>{rating_easy}</span>
                    </button>
                  }
                </div>
              </div>
            </div>
          </div>     
        </>
      );
    }    
  }    

}
export default Flashcard;