import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {faEye, faEdit } from '@fortawesome/free-solid-svg-icons';
import {handleResponse} from './Utils'
import Tabs from "./Tabs";
import QuestionModal from "./QuestionModal";
import PieLabel from "./PieLabel";
import { createTest } from './createTest'
import StudyPlanAccordion from "./StudyPlanAccordion";
import Loading from "./Loading";
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment/moment.js'
import { VictoryPie, VictoryTooltip, VictoryLabel } from 'victory';

const tabList = [
  {
    name: "Adaptive"
  }, {
    name: "Structured"
  }
];
class StudyPlan extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      subjects: [],
      hovered: false,
      showModal: false,
      activeTab: "Adaptive",
      myErrors: {
        custom_test: ''
      }      
    };
    this.onSubmitCustom = this.onSubmitCustom.bind(this);
    this.changeActiveTab = this.changeActiveTab.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt")
    const url = "/api/v1/questions/study-plan";
    fetch(url, { method: 'GET', headers: {'Authorization': token }})
    .then((question) => handleResponse(question))
    .then(response => this.setState({ 
      subjects: response.sort((a,b) => b.mistakes_count - a.mistakes_count),
      isLoaded: true
    }))
    .then((question) => console.log(this.state.subjects))
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }
  
  changeActiveTab(tab, e) {
    e.preventDefault()
    this.setState({ 
      activeTab: tab,
    });
  }  

  toggleModal(id, e) {
    e.preventDefault()
    const showModal = this.state.showModal === id ? null : id
    this.setState({showModal: showModal});
  };

  renderUnusedQuestions(unusedQuestionsLength, tagName) {
    if(unusedQuestionsLength > 0) {
      return (
        <tr>
          <td>Test</td>
          {unusedQuestionsLength === 1 
            ?
              <td>{`You have 1 unused question for ${tagName}`}</td>
            :
              <td>{`You have ${unusedQuestionsLength} unused questions for ${tagName}`}</td>
          }
          <td><span className="tag">To-do</span></td>
          <td><Link to="/tests/new">Create Test</Link></td>                    
        </tr>        
      )
    }
  }  

  renderLessonsToReview(lessonsToReview, strength) { 
    const lessonsList = lessonsToReview.map((lesson, index) => {
      return (
        <tr key={lesson.id}>
          <td>Lesson</td>
          <td>{lesson.name}</td>
          {strength === "todo"
            ? <td><span className="tag">To-do</span></td>
            : strength === "moderate" ? <td><span className="tag is-success is-light">Moderate</span></td>
            : <td><span className="tag is-success">Strong</span></td>
          }
          {strength === "todo"
            ? <td><Link to={`/lessons/${lesson.id}`}>View</Link></td>
            : <td><Link to={`/lessons/${lesson.id}`}>Review</Link></td>
          }                    
        </tr>
      )
    });
    return(lessonsList)  
  }  

  renderQuestionsToLog(questionsToLog) {
    const {showModal} = this.state;
    const questionList = questionsToLog.map((question, index) => {
      const newTo = {
        pathname: "/mistakes/new", 
        questionId: question.id
      }
      return (
        <tr key={question.id}>
          <td>Question</td>
          <td><span dangerouslySetInnerHTML={{__html: question.content.replace(/(<([^>]+)>)/ig,"")}}></span></td>
          <td><span className="tag">To-do</span></td>
          <td><Link to={newTo}>Log</Link></td>
        </tr>
      )
    });
    return(questionList)  
  }

  renderMistakesToReview(mistakesToReview, strength) {
    const {showModal} = this.state; 

    const mistakeList = mistakesToReview.map((mistake, index) => (
      <tr key={mistake.id}>
        <td>Question</td>
        <td><span dangerouslySetInnerHTML={{__html: mistake.question.content.replace(/(<([^>]+)>)/ig,"")}}></span></td>
        {strength === "moderate"
          ?
            <td><span className="tag is-success is-light">Moderate</span></td>
          :
            <td><span className="tag is-success">Strong</span></td>
        }
        <td><Link to={`/mistakes/${mistake.id}`}>Review</Link></td>
      </tr>
    ));
    return(mistakeList)    
  }

  renderQuestionsToLogAdaptive(parent_subject, subject) {
    const toLogQuestions = {
      pathname: "/mistakes/new",
      query: {question_id: subject.questions_to_log[0].id},
      questionId: subject.questions_to_log[0].id
    }          
    return (
      <div className="timeline-item">
        <div className="timeline-marker is-icon is-32x32">
          LQ
        </div>
        <div className="timeline-content">
          <div className="box">
            <p className="has-text-weight-bold">
              {`Log ${subject.questions_to_log.length} ${subject.questions_to_log.length === 1 ? "question" : "questions"}`}
            </p>
            <div className="tags">
              <span className="tag is-white">{parent_subject.name}</span>
              <span className="tag is-white">{subject.child_name}</span>
            </div>            
            <nav className="level is-mobile">
              <div className="level-left">
              </div>
              <div className="level-right">
                <Link className="level-item button is-success is-rounded is-small" to={toLogQuestions}>Log</Link>
              </div>                                
            </nav>                              
          </div>
        </div>
      </div>    
    )
  }

  onSubmitCustom(mistakesToRetry, event) {
    event.preventDefault();
    const custom_question_ids = mistakesToRetry.map((mistake) => (mistake.question.ancestry))
    const body = {question_mode: "Custom", custom_question_ids: custom_question_ids};

    createTest(body)
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors.custom_test = res.error
        this.setState({myErrors: myErrors})
      }else {
        this.props.history.push(`/tests/${res.id}`);
      }
    })
    .catch(error => console.log(error.message));
  }

  onSubmitPracticeTest(questions, event) {
    event.preventDefault();
    const body = {question_mode: "Custom", custom_question_ids: questions};

    createTest(body)
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors.practice_test = res.error
        this.setState({myErrors: myErrors})
      }else {
        this.props.history.push(`/tests/${res.id}`);
      }
    })
    .catch(error => console.log(error.message));
  }

  render() {
    if (this.state.isLoaded === false) {
      return (
        <Loading/>   
      )
    } else {
      const {subjects, pieData, hovered, activeTab, myErrors} = this.state; 
      let moderateColors = []
      let moderateData = []
      let strongColors = []
      let strongData = []
               
      return (
        <>
          <h1 className="title">Study Plan</h1>
          <Tabs tabList={tabList}
                activeTab={activeTab}
                changeActiveTab={this.changeActiveTab}
          />
          {activeTab === "Adaptive"
            ?
              <div className="columns">
                <div className="column">
                  <div className="timeline">
                    <div className="timeline-item">
                      <div className="timeline-marker"></div>
                      <div className="timeline-content">
                        <p className="heading">{moment().format("MMM. D, YYYY")}</p>
                      </div>
                    </div>
                    {subjects.map((s, i) => {
                      let moderateHash = {}
                      moderateHash["x"] = (s.cumulative_strong_length + s.cumulative_moderate_length)/s.cumulative_total_length
                      moderateHash["y"] = s.cumulative_total_length
                      moderateData.push(moderateHash)

                      let strongHash = {}
                      strongHash["x"] = s.cumulative_strong_length/s.cumulative_total_length
                      strongHash["y"] = s.cumulative_total_length
                      strongHash["name"] = s.name
                      strongHash["strong_length"] = s.cumulative_strong_length
                      strongHash["moderate_length"] = s.cumulative_moderate_length
                      strongHash["todo_length"] = s.cumulative_total_length - s.cumulative_strong_length - s.cumulative_moderate_length
                      strongData.push(strongHash)
                      return (
                        <React.Fragment key={i}>
                          {s.children.map((subject, index) => {
                            return(
                              <React.Fragment key={index}>
                                {subject.moderate_and_strong_lessons.length > 0 &&
                                  <div className="timeline-item">
                                    <div className="timeline-marker is-icon is-32x32">
                                      L
                                    </div>
                                    <div className="timeline-content">
                                      <div className="box">
                                        <p className="has-text-weight-bold">
                                          {`Review ${subject.moderate_and_strong_lessons.length} ${subject.moderate_and_strong_lessons.length === 1 ? "lesson" : "lessons"}`}
                                        </p>
                                        <div className="tags">
                                          <span className="tag is-white">{s.name}</span>
                                          <span className="tag is-white">{subject.child_name}</span>
                                        </div>                              
                                        <nav className="level is-mobile">
                                          <div className="level-left">
                                          </div>
                                          <div className="level-right">
                                            <Link className="level-item button is-warning is-rounded is-small" to={`/lessons/${subject.moderate_and_strong_lessons[0].id}`}>View</Link>
                                          </div>                                
                                        </nav>
                                      </div>
                                    </div>
                                  </div>
                                }                                
                                {subject.todo_review_lessons.length > 0 &&
                                  <div className="timeline-item">
                                    <div className="timeline-marker is-icon is-32x32">
                                      L
                                    </div>
                                    <div className="timeline-content">
                                      <div className="box">
                                        <p className="has-text-weight-bold">
                                          {`View ${subject.todo_review_lessons.length} ${subject.todo_review_lessons.length === 1 ? "lesson" : "lessons"}`}
                                        </p>
                                        <div className="tags">
                                          <span className="tag is-white">{s.name}</span>
                                          <span className="tag is-white">{subject.child_name}</span>
                                        </div>                              
                                        <nav className="level is-mobile">
                                          <div className="level-left">
                                          </div>
                                          <div className="level-right">
                                            <Link className="level-item button is-warning is-rounded is-small" to={`/lessons/${subject.todo_review_lessons[0].id}`}>View</Link>
                                          </div>                                
                                        </nav>
                                      </div>
                                    </div>
                                  </div>
                                }
                                {subject.mistakes_to_review.length > 0 &&
                                  <div className="timeline-item">
                                    <div className="timeline-marker is-icon is-32x32">
                                      RV
                                    </div>
                                    <div className="timeline-content">
                                      <div className="box">
                                        <p className="has-text-weight-bold">
                                          {`Review ${subject.mistakes_to_review.length} ${subject.mistakes_to_review.length === 1 ? "question" : "questions"}`}
                                        </p>
                                        <div className="tags">
                                          <span className="tag is-white">{s.name}</span>
                                          <span className="tag is-white">{subject.child_name}</span>
                                        </div>                              
                                        <nav className="level is-mobile">
                                          <div className="level-left">
                                          </div>
                                          <div className="level-right">
                                            <Link className="level-item button is-warning is-rounded is-small" to={`/mistakes/${subject.mistakes_to_review[0].id}`}>Review</Link>                              
                                          </div>                                
                                        </nav>                              
                                      </div>
                                    </div>
                                  </div>
                                }
                                {subject.mistakes_to_retry.length > 0 &&
                                  <div className="timeline-item">
                                    <div className="timeline-marker is-icon is-32x32">
                                      RT
                                    </div>
                                    <div className="timeline-content">
                                      <div className="box">
                                        <p className="has-text-weight-bold">
                                          {`Retry ${subject.mistakes_to_retry.length} ${subject.mistakes_to_retry.length === 1 ? "question" : "questions"}`}
                                        </p>
                                        <div className="tags">
                                          <span className="tag is-white">{s.name}</span>
                                          <span className="tag is-white">{subject.child_name}</span>
                                        </div>                              
                                        <nav className="level is-mobile">
                                          <div className="level-left">
                                          </div>
                                          <div className="level-right">
                                            <a href="#" className="level-item button is-info is-rounded is-small" onClick={(e) => this.onSubmitCustom(subject.mistakes_to_retry, e)}>Retry</a>
                                          </div>                                
                                        </nav>                              
                                        {myErrors.custom_test && 
                                          <p className='help is-danger'>{myErrors.custom_test}</p>}
                                      </div>
                                    </div>
                                  </div>
                                }
                                {subject.questions_to_log.length > 0 &&
                                  this.renderQuestionsToLogAdaptive(s, subject)
                                }                                
                                {subject.practice_test_questions.length > 0 &&
                                  <div className="timeline-item">
                                    <div className="timeline-marker is-icon is-32x32">
                                      PT
                                    </div>
                                    <div className="timeline-content">
                                      <div className="box">
                                        <p className="has-text-weight-bold">
                                          {`Practice test - ${subject.practice_test_questions.length} ${subject.practice_test_questions.length === 1 ? "question" : "questions"}`}
                                        </p>
                                        <div className="tags">
                                          <span className="tag is-white">{s.name}</span>
                                          <span className="tag is-white">{subject.child_name}</span>
                                        </div>                              
                                        <nav className="level is-mobile">
                                          <div className="level-left">
                                          </div>
                                          <div className="level-right">
                                            <a href="#" className="level-item button is-dark is-rounded is-small" onClick={(e) => this.onSubmitPracticeTest(subject.practice_test_questions, e)}>Take Practice Test</a>
                                          </div>                                
                                        </nav>                              
                                        {myErrors.practice_test && 
                                          <p className='help is-danger'>{myErrors.practice_test}</p>}
                                      </div>
                                    </div>
                                  </div>
                                }                                                                  
                              </React.Fragment>                             
                            )
                          })}
                        </React.Fragment>
                      )
                    })}
                  </div>
                </div>
                <div className="column">
                  <div className="stacked_pies">
                    <div className="stacked_pie_3">
                      <VictoryPie
                        height={500}
                        innerRadius={40}
                        colorScale={["#EDEDED"]}
                        labels={() => null}
                        radius={140}
                        style={{data: {stroke: "#ffffff", strokeWidth: 1}}}
                        data={moderateData}
                      />                    
                    </div>
                    <div className="stacked_pie_2">
                      <VictoryPie
                        height={500}
                        innerRadius={40}
                        colorScale={moderateColors}
                        labels={() => null}
                        radius={({ datum }) => 40 + (datum.x * 100)}
                        style={{data: {stroke: "#ffffff", strokeWidth: 1}}}
                        data={moderateData}                       
                      />                    
                    </div>                                       
                    <div className="stacked_pie_1">
                      <VictoryPie
                        height={500}
                        innerRadius={40}
                        colorScale={strongColors}
                        radius={({ datum }) => 40 + (datum.x * 100)}
                        style={{data: {stroke: "#ffffff", strokeWidth: 1}}}
                        labels={() => null}
                        data={strongData}                                                          
                      />                    
                    </div>
                    <div className="stacked_pie_4">
                      <VictoryPie
                        height={500}
                        innerRadius={40}
                        radius={140}
                        colorScale={["#ffffff"]}
                        style={{data: {stroke: "#ffffff", strokeWidth: 1, opacity: 0.7, visibility: ({ active }) => hovered && active ? "hidden" : hovered ? "visible" : "hidden"}}}
                        data={strongData}
                        labelComponent={<PieLabel/>}
                        events={[{
                          target: "data",
                          eventHandlers: {
                            onMouseOver: () => {
                              this.setState({hovered: true})
                              return [
                                {target: "labels", mutation: () => ({ active: true })},
                                {target: "data", mutation: () => ({ active: true })}
                              ]
                            },
                            onMouseOut: () => {
                              this.setState({hovered: false})
                              return [
                                {target: "labels", mutation: () => ({ active: false })},
                                {target: "data", mutation: () => ({ active: false })}
                              ]
                            }
                          }
                        }]}                        
                      />                    
                    </div>                                                                            
                  </div>
                </div>
              </div>
            :
              <div className="structured_plan">
                <div className="columns">
                  <div className="column">
                    {subjects.map((s,i) => {
                      let moderateHash = {}
                      moderateHash["x"] = (s.cumulative_strong_length + s.cumulative_moderate_length)/s.cumulative_total_length
                      moderateHash["y"] = s.cumulative_total_length
                      moderateData.push(moderateHash)

                      let strongHash = {}
                      strongHash["x"] = s.cumulative_strong_length/s.cumulative_total_length
                      strongHash["y"] = s.cumulative_total_length
                      strongHash["name"] = s.name
                      strongHash["strong_length"] = s.cumulative_strong_length
                      strongHash["moderate_length"] = s.cumulative_moderate_length
                      strongHash["todo_length"] = s.cumulative_total_length - s.cumulative_strong_length - s.cumulative_moderate_length
                      strongData.push(strongHash)
                      return(
                        <div className="box" key={i}>
                          <h1 className="title is-5 structured_plan_title">{s.name}</h1>
                          <StudyPlanAccordion>
                            {s.children.map((subject, index) => {
                              return (
                                <div
                                  key={index}
                                  label={subject.child_name}
                                  strong_fraction={subject.strong_fraction}
                                  moderate_fraction={subject.moderate_fraction}
                                  total_length={subject.total_length}
                                >
                                  <div className="accordion_table_wrapper">
                                    <nav className="level is-mobile">
                                      <div className="level-left">
                                        <div className="level-item has-text-centered">
                                          <div>
                                            <p className="heading">Strong</p>
                                            <p className="title is-4">{subject.strong_length}</p>
                                          </div>
                                        </div>
                                        <div className="level-item has-text-centered">
                                          <div>
                                            <p className="heading">Moderate</p>
                                            <p className="title is-4">{subject.moderate_length}</p>
                                          </div>
                                        </div>
                                        <div className="level-item has-text-centered">
                                          <div>
                                            <p className="heading">To-do</p>
                                            <p className="title is-4">{subject.todo_length}</p>
                                          </div>
                                        </div>                                    
                                      </div>
                                    </nav>                              
                                    <table className="table is-fullwidth">
                                      <thead>
                                        <tr>
                                          <th>Type</th>
                                          <th>Name</th>
                                          <th>Strength</th>
                                          <th>Action</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {this.renderUnusedQuestions(subject.unused_questions_length, subject.child_name)}
                                        {this.renderLessonsToReview(subject.todo_review_lessons, "todo")}
                                        {this.renderLessonsToReview(subject.moderate_review_lessons, "moderate")}
                                        {this.renderLessonsToReview(subject.strong_review_lessons, "strong")}
                                        {this.renderQuestionsToLog(subject.questions_to_log)}                      
                                        {this.renderMistakesToReview(subject.moderate_mistakes, "moderate")}
                                        {this.renderMistakesToReview(subject.strong_mistakes, "strong")}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>                
                              );
                            })}           
                          </StudyPlanAccordion>
                        </div>
                      )
                    })}
                  </div>
                  <div className="column">
                    <div className="stacked_pies">
                      <div className="stacked_pie_3">
                        <VictoryPie
                          height={500}
                          innerRadius={40}
                          radius={140}
                          colorScale={["#EDEDED"]}
                          labels={() => null}
                          style={{data: {stroke: "#ffffff", strokeWidth: 1}}}
                          data={moderateData}
                        />                    
                      </div>
                      <div className="stacked_pie_2">
                        <VictoryPie
                          height={500}
                          innerRadius={40}
                          colorScale={moderateColors}
                          labels={() => null}
                          radius={({ datum }) => 40 + datum.x * 100}
                          style={{data: {stroke: "#ffffff", strokeWidth: 1}}}
                          data={moderateData}                       
                        />                    
                      </div>                                       
                      <div className="stacked_pie_1">
                        <VictoryPie
                          height={500}
                          innerRadius={40}
                          colorScale={strongColors}
                          radius={({ datum }) => 40 + datum.x * 100}
                          style={{data: {stroke: "#ffffff", strokeWidth: 1}}}
                          labels={() => null}
                          data={strongData}                                                          
                        />                    
                      </div>
                      <div className="stacked_pie_4">
                        <VictoryPie
                          height={500}
                          innerRadius={40}
                          radius={140}
                          colorScale={["#ffffff"]}
                          style={{data: {stroke: "#ffffff", strokeWidth: 1, opacity: 0.7, visibility: ({ active }) => hovered && active ? "hidden" : hovered ? "visible" : "hidden"}}}
                          data={strongData}
                          labelComponent={<PieLabel/>}
                          events={[{
                            target: "data",
                            eventHandlers: {
                              onMouseOver: () => {
                                this.setState({hovered: true})
                                return [
                                  {target: "labels", mutation: () => ({ active: true })},
                                  {target: "data", mutation: () => ({ active: true })}
                                ]
                              },
                              onMouseOut: () => {
                                this.setState({hovered: false})
                                return [
                                  {target: "labels", mutation: () => ({ active: false })},
                                  {target: "data", mutation: () => ({ active: false })}
                                ]
                              }
                            }
                          }]}                        
                        />                    
                      </div>                                                                            
                    </div>                  
                  </div>
                </div>
              </div>          
          }          
        </>
      );
    }    
  }    

}
export default StudyPlan;