import React, { Component } from "react";
import PropTypes from "prop-types";

import StudyPlanAccordionSection from "./StudyPlanAccordionSection";

class StudyPlanAccordion extends Component {
  static propTypes = {
    allowMultipleOpen: PropTypes.bool,
    children: PropTypes.instanceOf(Object).isRequired,
  };

  constructor(props) {
    super(props);

    const openSections = {};
    
    this.props.children.forEach(child => {
      if (child.props.isOpen) {
        openSections[child.props.label] = true;
      }
    });

    this.state = { openSections };
  }

  onClick = label => {
    const { state: { openSections } } = this;

    const isOpen = !!openSections[label];

    this.setState({
      openSections: {
        ...openSections,
        [label]: !isOpen
      }
    });
  };

  render() {
    const { 
      onClick,
      props: { children },
      state: { openSections },
    } = this;

    return (
      <>
        {children.map(child => (
          <StudyPlanAccordionSection
            key={child.props.label}
            isOpen={!!openSections[child.props.label]}
            label={child.props.label}  
            total_length={child.props.total_length}
            strong_fraction={child.props.strong_fraction}
            moderate_fraction={child.props.moderate_fraction}
            onClick={onClick}
          >
            {child.props.children}
          </StudyPlanAccordionSection>
        ))}
      </>
    );
  }
}

export default StudyPlanAccordion;