import React from "react";
import {Route, Redirect} from "react-router-dom";
import {jwtValid} from './Utils'

const AppRouteRestricted = ({component: Component, layout: Layout, setNotification, addToCart, removeFromCart, products, user, setUserState, ...rest}) => (
  <Route 
    {...rest}
    render={props => (
      jwtValid(localStorage.getItem('jwt')) && JSON.parse(localStorage.getItem("valid_plans")).find(x => x.name === localStorage.getItem('localTopic')) && JSON.parse(localStorage.getItem("valid_plans")).find(x => x.name === localStorage.getItem('localTopic')).paid_and_active
        ?
          <Layout>
            <Component setNotification={setNotification} addToCart={addToCart} removeFromCart={removeFromCart} products={products} user={user} setUserState={setUserState} {...props}/>
          </Layout>
        :
          <Redirect setNotification={setNotification} to="/login"/>
    )}
  />
)


export default AppRouteRestricted;
