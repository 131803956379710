import React from "react";
import ReactHtmlParser from 'react-html-parser'; 
import Option from "./Option";
import GridIn from "./GridIn";
import Tutor from "./Tutor";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBolt, faBookOpen, faCalculator, faAngleUp, faExpand, faShapes} from '@fortawesome/free-solid-svg-icons';
import { faCommentAlt } from '@fortawesome/free-regular-svg-icons';
import {handleResponse, capSentence} from './Utils'
import NewFlashcard from "./NewFlashcard";
import NewFeedback from "./NewFeedback";
import Alert from "./Alert";
import Stopwatch from "./Stopwatch";
import Countdown from "./Countdown";
import RouteLeavingGuard from "./RouteLeavingGuard";
import LeaveTestModal from "./LeaveTestModal";
import SkippedQuestionsModal from "./SkippedQuestionsModal";
import TimeExpiredModal from "./TimeExpiredModal";
import Loading from "./Loading";
import Latex from "./Latex";
import moment from 'moment/moment.js'

const studentProducedResponse = "Student-produced response"
var oneParagraphHeight;
class EditTest extends React.Component { 
  constructor(props) {
    super(props);
    this.node = React.createRef();

    this.emptyAnswer = {
      content: '',
      letter: '',
      correct: false,
      question_id: null
    };    

    this.state = {
      questions_attributes: [],
      options: [],
      tutor: null,
      isLoaded: false,
      showLoadingMessage: false,
      showTool: null,
      timerOn: false,
      timerStart: 0,
      timerStopGap: 0,
      showModal: null,
      triggerPrompt: true,
      showStepDropdown: false,
      hideTimerControl: false
    };

    this.addHtmlEntities = this.addHtmlEntities.bind(this);
    this.handleGridIn = this.handleGridIn.bind(this);
    this._next = this._next.bind(this);
    this._prev = this._prev.bind(this);
    this.setAnswer = this.setAnswer.bind(this);
    this.toggleTool= this.toggleTool.bind(this);
    this.showAlert= this.showAlert.bind(this);
    this.removeAlert= this.removeAlert.bind(this);
    this.createMarkup= this.createMarkup.bind(this);
    this.startTimer = this.startTimer.bind(this);
    this.stopTimer = this.stopTimer.bind(this);
    this.startCountdown = this.startCountdown.bind(this);
    this.updatePatchRequest = this.updatePatchRequest.bind(this);
    this.updatePatchRequestWithoutRedirect = this.updatePatchRequestWithoutRedirect.bind(this);
    this.savePatchRequest = this.savePatchRequest.bind(this);
    this.triggerShowTutor = this.triggerShowTutor.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.leaveTestAndSave = this.leaveTestAndSave.bind(this);
    this.leaveTestWithoutSaving = this.leaveTestWithoutSaving.bind(this);
    this.endTest = this.endTest.bind(this);
    this.jumpToStep = this.jumpToStep.bind(this);
    this.toggleStepDropdown = this.toggleStepDropdown.bind(this);
    this.checkSkippedQuestions = this.checkSkippedQuestions.bind(this);
    this.checkTimer = this.checkTimer.bind(this);
    this.stopTimerIfModal = this.stopTimerIfModal.bind(this);
    this.fullScreen = this.fullScreen.bind(this);
    this.computeLines = this.computeLines.bind(this);
    this.dynamicLineNumber = this.dynamicLineNumber.bind(this);
    this.disableLinks = this.disableLinks.bind(this);

    // This is to clear the Cant perform a React state update on an unmounted component; Cancel All Subscriptions and Asyncs in the componentWillUnmount Method error

    this._isMounted = false;
  } 

  componentDidMount() {
    this._isMounted = true;
    let token = "Bearer " + localStorage.getItem("jwt");

    const { match: { params: { id } } } = this.props;
    Promise.all([
      fetch(`/api/v1/tests/${id}`, { method: 'GET', headers: {'Authorization': token }}),
      fetch("/calculator.html", { method: 'GET', headers: {'Authorization': token }})
    ])
    .then(([test, calculator]) => Promise.all([handleResponse(test), calculator.text()]))
    .then(([test, calculator]) => this.setState({ 
      ...test, calculator: calculator,  isLoaded: true
    }, () => {
      var counterWrapper = document.getElementById("counter_wrapper")
      if (counterWrapper) {
        var test_p = document.createElement('P')
        test_p.innerHTML = "test" 
        document.getElementById("counter_wrapper").append(test_p)
        oneParagraphHeight = test_p.clientHeight
        test_p.remove()

        window.addEventListener("resize", this.computeLines);
        window.addEventListener("resize", this.dynamicLineNumber);      
        this.computeLines();
        this.dynamicLineNumber();
        this.disableLinks();
      }
      this.checkTimer();
      window.ensureMathJax();
    })) 
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }

  componentWillUnmount() {
    this._isMounted = false;
    window.removeEventListener("resize", this.computeLines);
    window.addEventListener("resize", this.dynamicLineNumber);
  }

  disableLinks() {
    var anchors = document.getElementsByTagName("a");
    for (var i = 0; i < anchors.length; i++) {
      anchors[i].onclick = function(e) { e.preventDefault()}
    }    
  }  

  computeLines() { 
    // remove any previous numbering
    var elements_for_removal = document.getElementsByClassName("line_number"), element;
    while (element = elements_for_removal[0]) {
      element.parentNode.removeChild(element);
    }
    var cnt = 1;
    // // loop through paragraphs
    let paragraphs = document.querySelectorAll('div#counter_wrapper p')
    for (var i = 0; i < paragraphs.length; i++) {
      let pos = {
        top: paragraphs[i].offsetTop,
        left: paragraphs[i].offsetLeft
      };
      let paragraphHeight = paragraphs[i].offsetHeight;
      let lines = paragraphHeight / oneParagraphHeight;
      let lineHeight = paragraphHeight / lines;
      for (var j=pos.top ; j<pos.top+paragraphHeight ; j += lineHeight) {
        var top = j + 3
        var counter = cnt++
        if (counter === 4) {
          paragraphs[i].insertAdjacentHTML('beforebegin','<div style="top: ' + top +'px" class="line_number" id="line_number_' + top +'"><em>Line</em></div>');
        } else if (counter%5 == 0) {
          paragraphs[i].insertAdjacentHTML('beforebegin','<div style="top: ' + top +'px" class="line_number" id="line_number_' + top +'">' + counter +'</div>');
        } else {
          paragraphs[i].insertAdjacentHTML('beforebegin','<div style="display:none;top: ' + top +'px" class="line_number" id="line_number_' + top +'">' + counter +'</div>');
        }
      }
      paragraphs[i].style.marginLeft = "30px";
    }
  };

  dynamicLineNumber() {
    var question_column = document.querySelector(".question_column");
    if (question_column) {
      var matches = question_column.querySelectorAll("a");
      if (matches.length) {
        for (var i=0, max=matches.length; i < max; i++) {

          var question_start = matches[i]

          var passage_column = document.querySelector("#passage_column");
          // Get the first className of element
          var passage_start = passage_column.querySelector(`.${matches[i].className.split(" ")[0]}`)
          var new_content;

          if (document.body.contains(question_start) && document.body.contains(passage_start)) {
            let passage_start_pos = {
              top: passage_start.offsetTop,
              left: passage_start.offsetLeft
            };

            // This finds the line_number element on the same line as passage_start
            if (document.getElementById(`line_number_${passage_start_pos.top}`)) {
              var line_number_start = document.getElementById(`line_number_${passage_start_pos.top}`).innerHTML
            }
            if (line_number_start == "<em>Line</em>") {
              line_number_start = 4
            }
            var line_number_end;

            if (passage_start.offsetHeight > oneParagraphHeight) {
              var passage_end_top = (Math.floor(passage_start.offsetHeight/oneParagraphHeight) * oneParagraphHeight) + passage_start_pos.top
              if (document.getElementById(`line_number_${passage_end_top}`)) {
                line_number_end = document.getElementById(`line_number_${passage_end_top}`).innerHTML
              }

              // This handles text that span multiple line numbers
              if (line_number_start !== line_number_end) {
                new_content = `lines ${line_number_start} - ${line_number_end}`
              } else {
                new_content = `line ${line_number_start}`
              }   
            } else {
              new_content = `line ${line_number_start}`
            }
            // If element has class cap, capitalize it
            if (question_start.classList.contains("cap")) {
              question_start.innerHTML = capSentence(new_content)
            } else {
              question_start.innerHTML = new_content
            }
          }
        }
      }
    }
  } 

  toggleTool(name) {
    const showTool = this.state.showTool === name ? null : name
    this.setState({
      showTool: showTool
    });
  };

  showAlert(name) {
    this.setState({showAlert: name});
  };

  removeAlert() {
    this.setState({showAlert: false});
  };        
  
  // This is to convert escaped characters saved in test
  addHtmlEntities(str) {
    return String(str)
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");
  }

  updatePatchRequest(event) {
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem("jwt")
    let id = this.state.id
    this.setTimeSpent()   
    fetch(`/api/v1/tests/${id}`, {
      method: 'put',
      body: JSON.stringify(this.state),
      headers: { "Authorization": token, 'Content-Type': 'application/json' },
    })
    .then((response) => {
      this.props.history.push(`/tests/${id}/result`);
    })
    .catch(error => console.log('error', error));  
  }

  updatePatchRequestWithoutRedirect() {
    let token = "Bearer " + localStorage.getItem("jwt")
    let id = this.state.id
    this.setTimeSpent()   
    fetch(`/api/v1/tests/${id}`, {
      method: 'put',
      body: JSON.stringify(this.state),
      headers: { "Authorization": token, 'Content-Type': 'application/json' },
    })
    .catch(error => console.log('error', error));  
  }  

  savePatchRequest(event) {
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem("jwt")
    let id = this.state.id
    fetch(`/api/v1/tests/save/${id}`, {
      method: 'put',
      body: JSON.stringify(this.state),
      headers: { "Authorization": token, 'Content-Type': 'application/json' },
    })
    .then((response) => {
      this.props.history.push("/tests");
    })
    .catch(error => console.log('error', error));  
  }

  toggleModal(arg, e) {    
    e.preventDefault()
    const showModal = this.state.showModal === arg ? null : arg
    this.setState({
      showModal: showModal
    }, () => {
      this.stopTimerIfModal()
    });
  };

  stopTimerIfModal() {
    const {showModal, timerOn, timed} = this.state
    if (showModal === null) {
      if (timerOn === false) {
        timed ? this.startCountdown() : this.startTimer()
      }
    } else {
      this.stopTimer();
    }
  }  

  leaveTestAndSave(event) {
    event.persist();
    this.setState({
      isLoaded: false,
      triggerPrompt: false
    }, () => {
      this.savePatchRequest(event)
    });
  }

  leaveTestWithoutSaving(event) {
    event.persist();
    this.setState({
      isLoaded: false,
      triggerPrompt: false
    }, () => {
      this.props.history.push("/tests");
    });
  }  

  endTest(event) {
    event.persist();
    this.setState({
      isLoaded: false,
      showLoadingMessage: true,
      triggerPrompt: false
    }, () => {
      this.updatePatchRequest(event)
    });
  } 

  checkSkippedQuestions(event) {
    event.persist();
    const questions = this.state.questions_attributes
    const skippedQuestions = questions.filter(key => key.skipped === true || key.skipped === null)
    if (skippedQuestions.length > 0) {
      this.toggleModal("confirmSkipped", event)
    } else {
      this.endTest(event)
    }
  }      

  handleGridIn(event) {
    let {name, value} = event.target

    const step = this.state.step
    let questions_attributes = [...this.state.questions_attributes];
    const question = questions_attributes.find(obj => {
      return obj.order === step
    });
    if (question[name] === value) {
      value = ""
    }
    // Only allow numbers, decimals, forward slashes, and negative sign
    question[name] = value.replace(/[^0-9./-]/g, '');

    this.setState({
      questions_attributes
    }, () => {
      this.setAnswer()
    });
  }  

  setTimeSpent() {
    const {questions_attributes, time_spent, timed, countdown_time, initialTimerTime} = this.state
    let {step, timerStopGap} = this.state
    const question = questions_attributes.find(obj => {
      return obj.order === step
    });
    // This sets time_spent for question, not test
    if (timed) {
      // If setting time for the first time, use the initialTimerTime. Else, use the timerStopGap
      if (timerStopGap === 0) {
        question.time_spent = question.time_spent + (initialTimerTime - countdown_time)
      } else {
        question.time_spent = question.time_spent + (timerStopGap - countdown_time)
      }
      timerStopGap = countdown_time
    } else {
      question.time_spent = question.time_spent + (time_spent - timerStopGap)
      timerStopGap = time_spent
    }

    // This tells us the user actually looked at the question and can use it for AI
    question.answer_attributes.inserted = moment();

    this.setState({
      questions_attributes: questions_attributes,
      timerStopGap: timerStopGap
    })        
  }

  async _next() {
    const {questions_attributes} = this.state
    let {step} = this.state

    this.setTimeSpent();

    let lastStep = questions_attributes.length - 1
    let secondToLastStep = lastStep - 2
    
    step = step > secondToLastStep ? lastStep : step + 1
    
    this._isMounted && this.setState({
      step: step
    }, () => {
      this.checkTimer();
      this.computeLines();
      this.dynamicLineNumber();
      window.ensureMathJax();
    }); 
  }
    
  async _prev() {
    let {step} = this.state

    this.setTimeSpent();

    step = step <= 0 ? 0 : step - 1
    this._isMounted && this.setState({
      step: step
    }, () => {
      this.checkTimer();
      this.computeLines();
      this.dynamicLineNumber();
      window.ensureMathJax();
    }); 
  }

  async jumpToStep(index, event) {
    event.preventDefault()
    await this.setTimeSpent()

    this.setState({
      showModal: null,
      step: index
    }, () => {
      this.checkTimer();
      this.computeLines();
      this.dynamicLineNumber();  
      this.toggleStepDropdown();
    }); 
  }  

  previousButton(){
    let step = this.state.step;
    if(step !==0){
      return (
        <button 
          className="button is-ghost" 
          type="button" onClick={this._prev}>
        Previous
        </button>
      )
    }
    return null;
  }

  nextButton(){
    let step = this.state.step;
    let lastStep = this.state.questions_attributes.length - 1
    if(step < lastStep){
      return (
        <button 
          className="button is-dark" 
          type="button" onClick={this._next}>
        Next
        </button>        
      )
    }
    return null;
  }
  ///////////////

  setAnswer(event, option=0){
    const {questions_attributes} = this.state
    const question = questions_attributes.find(obj => {
      return obj.order === this.state.step
    }); 

    if (question.question_type === studentProducedResponse) {
      // Combine grids to get answer as string
      let joinedAnswer = question.student_produced_answer

      // If / exists calculate the answer
      if (joinedAnswer.includes('/')) {
        const [numerator, denominator] = joinedAnswer.split('/')
        joinedAnswer = Number(numerator)/Number(denominator)
      } else {
        joinedAnswer = Number(joinedAnswer)
      }      

      if (question.correct_grid_in_answer === joinedAnswer) {
        question.skipped = false
        question.answer_attributes = {correct: true, question_id: question.id, inserted: moment()}
      } else {
        if (joinedAnswer === 0) {
          question.skipped = true
          question.answer_attributes = {correct: false, question_id: question.id, inserted: moment()}
        } else {
          question.skipped = false
          question.answer_attributes = {correct: false, question_id: question.id, inserted: moment()}
        }
      }
    } else {
      question.answer_attributes = question.answer_attributes || Object.assign({}, this.emptyAnswer);

      if (event.target.value == question.answer_attributes.letter) {
        question.skipped = true
        question.answer_attributes = {content: "", letter: "", correct: false, question_id: question.id, inserted: moment()}
        question.answer_tracker.unshift("incorrect")
      } else {
        question.skipped = false
        question.answer_attributes = {content: option.content, letter: option.letter, correct: option.correct, question_id: question.id, inserted: moment()}
        question.answer_tracker.unshift(option.correct ? "correct" : "incorrect")
      }
    }

    this.setState({
      questions_attributes: questions_attributes
    });    
  }

  // This pushes keeps track of taken questions to show tutor on next and previous
  async triggerShowTutor() {
    await this.setTimeSpent()
    const step = this.state.step

    let taken_tracker = [...this.state.taken_tracker];
    let taken_item = {...taken_tracker[step]};
    taken_item = "taken";
    taken_tracker[step] = taken_item;
    this.setState({
      taken_tracker
    }, () => {
      this.checkTimer()
    });
  }

  handleCheckbox(step, event) {
    const target = event.target;
    const value = target.checked;
    let questions = this.state.questions_attributes
    let question = questions.find(obj => {
      return obj.order === this.state.step
    });
    question.marked = value
    // questions[step] = question
    this.setState({
      questions_attributes: questions
    })
  }

  createMarkup(html) { 
    return {__html: html};
  }

  checkTimer() {
    const {taken_tracker, step, tutor, score, timerOn, timed, hideTimerControl} = this.state
    if (tutor) {
      if (taken_tracker[step] === "taken") {
        this.stopTimer()
        this.setState({
          hideTimerControl: true
        })
      } else {
        if (timerOn === false) {
          timed ? this.startCountdown() : this.startTimer()
        }
        if (hideTimerControl === true) {
          this.setState({
            hideTimerControl: false
          })          
        }        
      }
    } else {
      // If score is not null, the default state for timerOn is false so stays false
      if (score === null) {
        if (timerOn === false) {
          timed ? this.startCountdown() : this.startTimer()
        }
        if (hideTimerControl === true) {
          this.setState({
            hideTimerControl: false
          })          
        }        
      } else {
        this.setState({
          hideTimerControl: true
        })        
      }
    }
  }

  toggleStepDropdown() {
    this.setState(prevState => ({
      showStepDropdown: !prevState.showStepDropdown
    }));
  };    

  startTimer() {
    const {time_spent} = this.state
    this._isMounted && this.setState({
      timerOn: true,
      time_spent: time_spent,
      timerStart: Date.now() - time_spent
    });
    this.timer = setInterval(() => {
      this._isMounted && this.setState({
        time_spent: Date.now() - this.state.timerStart
      });
    }, 1000);
  };

  stopTimer() {
    this.setState({ timerOn: false });
    clearInterval(this.timer);
  };

  startCountdown() {
    this.setState({
      timerOn: true,
      countdown_time: this.state.countdown_time,
      timerStart: this.state.countdown_time
    });
    this.timer = setInterval(() => {
      const newTime = this.state.countdown_time - 10;
      if (newTime >= 0) {
        this.setState({
          countdown_time: newTime
        });
      } else {
        clearInterval(this.timer);
        this.updatePatchRequestWithoutRedirect()
        this.setState({ 
          timerOn: false,
          triggerPrompt: false,
          showModal: "timeExpired"
        });

      }
    }, 10);
  };

  fullScreen(event) {
    let elem = document.documentElement;
    if (elem.requestFullscreen) {
      elem.requestFullscreen();
    } else if (elem.mozRequestFullScreen) { /* Firefox */
      elem.mozRequestFullScreen();
    } else if (elem.webkitRequestFullscreen) { /* Chrome, Safari & Opera */
      elem.webkitRequestFullscreen();
    } else if (elem.msRequestFullscreen) { /* IE/Edge */
      elem.msRequestFullscreen();
    }
  };           

  render() {

    if (this.state.isLoaded === false) {
      return (
        <Loading messages={this.state.showLoadingMessage ? ["Analyzing your performance...", "Predicting your mastery level...", "Finding recommendations...", "Creating your learning path..."] : []}/>    
      )
    } else {
      const {id, step, tutor, timed, score, timerOn, timerStart, countdown_time, showTool, showAlert, calculator, taken_tracker, showModal, showStepDropdown, triggerPrompt, time_spent, hideTimerControl, section} = this.state
      const { history } = this.props
      const questions = this.state.questions_attributes
      const question = questions.find(obj => {
        return obj.order === step
      });
      const timeSpent = Number((question.time_spent/1000)).toFixed()
      const percentageCorrect = (question.question_percent_correct_average * 100).toFixed()
      const isGridIn = question.question_type === studentProducedResponse ? true : false
      const answer = question ? question.answer_attributes : ""
      const answerExplanation = question ? question.answer_explanation : ""
      const passageQuestionStart = question.passage ? question.passage_question_start[question.passage.id].start + 1 : ""
      const passageQuestionLength = question.passage ? question.passage_question_start[question.passage.id].length : ""
      let correctOption
      if (question) {
        if (question.question_type === studentProducedResponse) {
          correctOption = question.correct_grid_in_answer
        } else {
          correctOption = question.options.find(option => option.correct === true).letter
        }
      }
      const dropdownItems = questions.map((question, index) =>
        <button href="#" key={index} className="button is-ghost dropdown-item" onClick={(e) => this.jumpToStep(index, e)}>
          {index + 1}
        </button>
      );      
      return (
        <div onClick={showStepDropdown ? this.toggleStepDropdown : undefined}>
          <div className="timer_wrapper">
            {timed
              ? <Countdown timerOn={timerOn} timerStart={timerStart} countdown_time={countdown_time} startCountdown={this.startCountdown} stopTimer={this.stopTimer} hideTimerControl={hideTimerControl}/>
              : <Stopwatch timerOn={timerOn} timerStart={timerStart} time_spent={time_spent} startTimer={this.startTimer} stopTimer={this.stopTimer} hideTimerControl={hideTimerControl}/>       
            }          
          </div>    
          {score === null &&
            <RouteLeavingGuard
              when={triggerPrompt}
              navigate={path => history.push(path)}
              checkSkippedQuestions={this.checkSkippedQuestions}
              leaveTestAndSave={this.leaveTestAndSave}
              stopTimerIfModal={this.stopTimerIfModal}
              shouldBlockNavigation={location => {
                if (true) {
                  this.stopTimer();
                  return true
                } 
                return false
              }}
            />
          }
          {(questions.length > 0 && step < questions.length
            ? <Latex>
                {showAlert &&
                  <Alert delay={5000} removeAlert={this.removeAlert}>
                    <button className="delete" onClick={this.removeAlert}></button>
                    {showAlert}
                  </Alert>
                }
                <section className="test_section">  
                  <div className="container pb-52">
                    <LeaveTestModal checkSkippedQuestions={this.checkSkippedQuestions} leaveTestAndSave={this.leaveTestAndSave} leaveTestWithoutSaving={this.leaveTestWithoutSaving} showModal={showModal} toggleModal={(e) => this.toggleModal("leave", e)}/> 
                    <SkippedQuestionsModal endTest={this.endTest} questions={questions} jumpToStep={this.jumpToStep} taken_tracker={taken_tracker} showModal={showModal} toggleModal={(e) => this.toggleModal("confirmSkipped", e)}/> 
                    <TimeExpiredModal questions={questions} showModal={showModal} testId={id}/>                    
                    <div className={`modal ${showTool === "flashCard" ? "is-active" : ""}`}>
                      <div className="modal-background" onClick={(e) => this.toggleTool("flashCard", e)}></div>
                      <div className="modal-content">
                        <div className="box">
                          <div className="margin_bottom"><div className="tool_title">Flashcard</div></div>
                          <NewFlashcard fromTest={true} handleCancel={(e) => this.toggleTool("flashCard", e)} showAlert={() => this.showAlert("Flashcard created!")}/>
                        </div>                
                      </div>
                      <button className="modal-close is-large" aria-label="close" onClick={(e) => this.toggleTool("flashCard", e)}></button>
                    </div>
                    {!question.passage &&
                      <>
                        <div className={`modal ${showTool === "reference" ? "is-active" : ""}`}>
                          <div className="modal-background" onClick={(e) => this.toggleTool("reference", e)}></div>
                          <div className="modal-content">
                            <div className="box">
                              <div className="margin_bottom"><div className="tool_title">Reference</div></div>
                              <picture>
                                <source className="img-fluid" srcSet={`${process.env.PUBLIC_URL}/images/regents_math_reference.webp`} type="image/webp"/>
                                <source className="img-fluid" srcSet={`${process.env.PUBLIC_URL}/images/regents_math_reference.png`} type="image/png"/> 
                                <img className="img-fluid" src={`${process.env.PUBLIC_URL}/images/regents_math_reference.png`} alt="Regents Math Reference Sheet"/>
                              </picture>                              
                            </div>                
                          </div>
                          <button className="modal-close is-large" aria-label="close" onClick={(e) => this.toggleTool("reference", e)}></button>
                        </div>
                        <div className={`modal ${showTool === "calculator" ? "is-active" : ""}`}>
                          <div className="modal-background" onClick={(e) => this.toggleTool("calculator", e)}></div>
                          <div className="modal-content">
                            <div className="box">
                              <div className="margin_bottom"><div className="tool_title">Calculator</div></div>
                              <div dangerouslySetInnerHTML={this.createMarkup(calculator)} />
                            </div>                
                          </div>
                          <button className="modal-close is-large" aria-label="close" onClick={(e) => this.toggleTool("calculator", e)}></button>
                        </div>
                      </>
                    }
                    <div className={`modal ${showTool === "feedback" ? "is-active" : ""}`}>
                      <div className="modal-background" onClick={(e) => this.toggleTool("feedback", e)}></div>
                      <div className="modal-content">
                        <div className="box">
                          <div className="margin_bottom"><div className="tool_title">Feedback</div></div>
                          <NewFeedback fromTest={true} questionId={question.ancestry} handleCancel={(e) => this.toggleTool("feedback", e)} showAlert={() => this.showAlert("Feedback created!")}/>
                        </div>               
                      </div>
                      <button className="modal-close is-large" aria-label="close" onClick={(e) => this.toggleTool("feedback", e)}></button>
                    </div>                                                 
                    <div className="columns">
                      {question.passage &&
                        <>
                          <div className="column" id="passage_column">
                            <div className={`${question.passage.category.has_passage ? "block reading_block" : "block"}`}>
                              {passageQuestionLength === 0
                                ?
                                  <strong>{`Question ${passageQuestionStart} is based on the following passage.`}</strong>
                                :
                                  <strong>{`Questions ${passageQuestionStart} - ${passageQuestionStart + passageQuestionLength} are based on the following passage.`}</strong>
                              }
                            </div>                        
                            <div className={`${question.passage.category.has_passage ? "block reading_block" : "block writing_block"}`}>
                              <div className="content">
                                {ReactHtmlParser(question.passage.description)}
                              </div>
                            </div>
                            <div className="content">
                              <div id="counter_wrapper">
                                {ReactHtmlParser(question.passage.content)}
                              </div>
                            </div>
                          </div>
                          <div className="is-divider-vertical"></div>
                        </>
                      }
                      <div 
                        className={`column is-8 is-offset-2 ${question.passage && "question_column"}`}
                        ref={this.node}
                        style={{width: document.getElementById('passage_column') && document.getElementById('passage_column').offsetWidth}}
                      >
                        <div className="box mobile_box">
                          <div className="is-flex">
                            <div>
                              <span> {step + 1}.</span>
                            </div>
                            <div className="is-flex-grow-1">
                              <div className="content question_content_wrapper" dangerouslySetInnerHTML={{__html: question.content}}></div>
                              {isGridIn
                                ? 
                                  <input name="student_produced_answer" className="input mb-5 ml-4 set_width" type="text" maxLength="6" value={question.student_produced_answer} onChange={this.handleGridIn} disabled={taken_tracker[step] === "taken" || score !== null}/>
                                : 
                                  <div className="options_wrapper"> 
                                    {question.options.sort((a, b) => (a.letter > b.letter) ? 1 : -1).map((option) => {
                                      return (
                                        <div key={option.id} className="field">
                                          <div className="control test_options">
                                            <Option
                                              changed={ event => this.setAnswer(event, option) }
                                              id={step}
                                              isSelected={ answer ? answer.letter === option.letter : "" }
                                              label={option.content}
                                              value={option.letter}
                                              disabled={taken_tracker[step] === "taken" || score !== null}
                                              readOnly={score !== null}/>
                                          </div>
                                        </div>
                                      )       
                                    })}
                                  </div>
                              }
                              {(typeof taken_tracker[step] !== "undefined") &&
                                <Tutor answerExplanation={answerExplanation} correctOption={correctOption} timeSpent={timeSpent} percentageCorrect={percentageCorrect}/>
                              }                        
                              <div className="buttons_wrapper buttons">
                                {tutor 
                                  ? 
                                    <>
                                      {(typeof taken_tracker[step] === "undefined") &&
                                        <>
                                          {this.previousButton()}
                                          <button className="button is-dark" onClick={this.triggerShowTutor}>Submit</button>
                                        </>
                                      }
                                    </>
                                  : 
                                    <>
                                      {this.previousButton()}
                                      {this.nextButton()}
                                    </>
                                }
                                {(typeof taken_tracker[step] !== "undefined") &&
                                  <>
                                    {this.previousButton()}
                                    {this.nextButton()}
                                    {(step === questions.length - 1 &&
                                      <button className="button is-dark" onClick={(e) => this.toggleModal("leave", e)}>Finish</button>
                                    )}                                
                                  </>
                                }

                                {(step === questions.length - 1 && !tutor && score === null &&
                                  <button className="button is-dark" onClick={(e) => this.toggleModal("leave", e)}>Finish</button>
                                )}
                                {(step === questions.length - 1 && !tutor && score !== null &&
                                  <button className="button is-dark" onClick={this.leaveTestWithoutSaving}>Finish</button>
                                )}
                              </div>                
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </section>
                <div className="test_toolbar is-hidden-tablet">
                  <div className="step_dropdown_wrapper">
                    <div className={`dropdown is-up ${showStepDropdown ? "is-active" : ""}`} onClick={showStepDropdown ? undefined : this.toggleStepDropdown}>
                      <div className="dropdown-trigger">
                        <button className="button is-ghost has-text-black stepdown_button" aria-haspopup="true" aria-controls="dropdown-menu">
                          <span>{(step + 1) + "/" + questions.length}</span>
                          <span className="icon is-small">
                            <FontAwesomeIcon icon={faAngleUp}/>
                          </span>
                        </button>
                      </div>
                      <div className="dropdown-menu" id="dropdown-menu" role="menu">
                        <div className="dropdown-content">
                          {dropdownItems}
                        </div>
                      </div>
                    </div>          
                  </div>               
                  <span className="icon tooltip-left" data-tooltip="Star Question"><input className="star" onChange={(e) => this.handleCheckbox(step, e)} type="checkbox" checked={question.marked} name="Marked" /></span>
                  <span className={`icon tooltip-left ${showTool === "flashCard" ? "active" : ""}`} data-tooltip="Flashcards" onClick={(e) => this.toggleTool("flashCard", e)}><FontAwesomeIcon icon={faBolt}/></span>
                  {!question.passage &&
                    <>
                      <span className={`icon tooltip-left ${showTool === "reference" ? "active" : ""}`} data-tooltip="Reference" onClick={(e) => this.toggleTool("reference", e)}><FontAwesomeIcon icon={faShapes}/></span>
                      <span className={`icon tooltip-left ${showTool === "calculator" ? "active" : ""}`} data-tooltip="Calculator" onClick={(e) => this.toggleTool("calculator", e)}><FontAwesomeIcon icon={faCalculator}/></span>
                    </>
                  }
                  <span className="icon tooltip-left is-hidden-mobile" data-tooltip="Full Screen" onClick={this.fullScreen}><FontAwesomeIcon icon={faExpand}/></span>
                  <span className={`icon tooltip-left ${showTool === "feedback" ? "active" : ""}`} data-tooltip="Feedback" onClick={(e) => this.toggleTool("feedback", e)}><FontAwesomeIcon icon={faCommentAlt}/></span>
                  <span onClick={score === null ? (e) => this.toggleModal("leave", e) : this.leaveTestWithoutSaving}>EXIT</span>
                </div>
                <div className="test_toolbar is-hidden-mobile">
                  <div className="toolbar_resources">
                    <span className="icon tooltip-left" data-tooltip="Star Question"><input className="star" onChange={(e) => this.handleCheckbox(step, e)} type="checkbox" checked={question.marked} name="Marked" /></span>
                    <span className={`icon tooltip-left ${showTool === "flashCard" ? "active" : ""}`} data-tooltip="Flashcards" onClick={(e) => this.toggleTool("flashCard", e)}><FontAwesomeIcon icon={faBolt}/></span>
                    {!question.passage &&
                      <>
                        <span className={`icon tooltip-left ${showTool === "reference" ? "active" : ""}`} data-tooltip="Reference" onClick={(e) => this.toggleTool("reference", e)}><FontAwesomeIcon icon={faShapes}/></span>
                        <span className={`icon tooltip-left ${showTool === "calculator" ? "active" : ""}`} data-tooltip="Calculator" onClick={(e) => this.toggleTool("calculator", e)}><FontAwesomeIcon icon={faCalculator}/></span>
                      </>
                    }
                    <span className="icon tooltip-left is-hidden-mobile" data-tooltip="Full Screen" onClick={this.fullScreen}><FontAwesomeIcon icon={faExpand}/></span>
                  </div>
                  <div className="toolbar_settings">
                    <span className={`icon tooltip-left ${showTool === "feedback" ? "active" : ""}`} data-tooltip="Feedback" onClick={(e) => this.toggleTool("feedback", e)}><FontAwesomeIcon icon={faCommentAlt}/></span>
                    <span className={"icon"} onClick={score === null ? (e) => this.toggleModal("leave", e) : this.leaveTestWithoutSaving}>EXIT</span>
                  </div>
                </div>
              </Latex>              
            : (<div>There are 0 questions.</div>)
          )}       
          <div className="step_dropdown_wrapper is-hidden-mobile">
            <div className={`dropdown is-up ${showStepDropdown ? "is-active" : ""}`} onClick={showStepDropdown ? undefined : this.toggleStepDropdown}>
              <div className="dropdown-trigger">
                <button className="button is-ghost has-text-black" aria-haspopup="true" aria-controls="dropdown-menu">
                  <span>{(step + 1) + "/" + questions.length}</span>
                  <span className="icon is-small">
                    <FontAwesomeIcon icon={faAngleUp}/>
                  </span>
                </button>
              </div>
              <div className="dropdown-menu" id="dropdown-menu" role="menu">
                <div className="dropdown-content">
                  {dropdownItems}
                </div>
              </div>
            </div>          
          </div>
        </div>
      )
    }
  }    
}

export default EditTest;