import React from "react";
import Menu from './Menu';

export default ({children}) => (
  <div className="menu_wrapper">
    <Menu user={children.props.user} setUserState={children.props.setUserState}/>
    <div className="div80">
      <div className="container">
        {children}
      </div>
    </div>
  </div>
);
