import React from 'react';
import { createFeedback } from './createFeedback'
import {handleResponse} from './Utils'
import Loading from "./Loading";

// We use the Map construct for checkedTags for flexibility and convenience of setting and getting values
class NewFeedback extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false,
      error: null         
    };      

    this.onSubmit = this.onSubmit.bind(this);
    this.onSubmitFromTest = this.onSubmitFromTest.bind(this);
    this.stripHtmlEntities = this.stripHtmlEntities.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleCancelFromTest = this.handleCancelFromTest.bind(this);
    this.clearState = this.clearState.bind(this);
  }

  // Get tags
  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt")
    const url = "/api/v1/feedbacks/new";
    fetch(url, { method: 'GET', headers: {'Authorization': token }})
    .then((feedback) => handleResponse(feedback))
    .then(response => this.setState({ ...response, message: "", isLoaded: true }))     
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }

  stripHtmlEntities(str) {
    return String(str)
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  onSubmit(event) {
    event.preventDefault();
    const { message } = this.state;
    const body = { message }

    createFeedback(body)
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors.newFeedback = res.error
        this.setState({myErrors: myErrors})
      } else {
        this.props.history.push("/");
      }
    })
    .catch(error => console.log(error.message)); 
  }

  onSubmitFromTest(event) {
    event.preventDefault();
    const { message } = this.state;
    const question_id = this.props.questionId;
    const body = {message, question_id}

    createFeedback(body)
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors.newFeedback = res.error
        this.setState({myErrors: myErrors})
      } else {
        this.clearState()
        // This closes the Create Feedback box in EditTest
        this.props.handleCancel()
        this.props.showAlert()
      }
    })
    .catch(error => console.log(error.message)); 
  }  

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleCancel(event) {
    event.preventDefault()
    this.clearState()
    this.props.history.push("/");
  }

  handleCancelFromTest(event) {
    event.preventDefault()
    this.clearState()
    this.props.handleCancel(event)
  }

  clearState(){
    this.setState({
      message: "",
    })    
  }


  render() {
    const {myErrors, message} = this.state
    if (this.state.isLoaded === false) {
      return (
        <Loading/>    
      )
    } else {
      return (
        <>
          {this.props.fromTest
            ? <>
                <form onSubmit={this.onSubmitFromTest}>
                  {this.myErrors && 
                    <p className='help is-danger'>{this.myErrors.newFeedback}</p>}      
                  <div className="columns">
                    <div className="column">
                      <div className="field">             
                        <div className="control">
                          <textarea className="textarea" rows="6" name="message" value={message} placeholder="Message" onChange={this.handleChange}></textarea>
                        </div>
                      </div>
                      <p className="is-size-7">{"Question ID: " + this.props.questionId}</p>
                    </div>
                  </div>
                  <div className="field">
                    <div className="buttons">
                      <button type="submit" className="button is-dark">Create</button>
                      <button className="button is-light" onClick={this.handleCancelFromTest}>Cancel</button>
                    </div>
                  </div>
                </form>
              </>            
            : <>
                <p>Open Feedback</p>
              </>
          }
        </>
      );
    }    

  }      
}

export default NewFeedback;