import React from 'react';
import { Redirect } from 'react-router-dom'

const Logout = () => {
  localStorage.removeItem('localTopic');
  localStorage.removeItem('guest');
  localStorage.removeItem('jwt');
  localStorage.removeItem('valid_plans');
  localStorage.removeItem('cart');
  return <Redirect to='/' />
}

export default Logout;