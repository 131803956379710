import React, { useLayoutEffect, useRef, useState } from "react";
import Orders from "./Orders";
import { Link } from "react-router-dom";
import Arrows from "./Arrows"
import NextArrow from "./NextArrow"
import IconBrain from "./IconBrain"
import IconData from "./IconData"
import DemoEmail from "./DemoEmail"
import ImgWithFallback from './ImgWithFallback';
import cms from '../images/cms.webp';
import cms_png from '../images/cms.png';
import adaptive_plan from '../images/adaptive_plan.webp';
import adaptive_plan_png from '../images/adaptive_plan.png';
import practice_test from '../images/practice_test.webp';
import practice_test_png from '../images/practice_test.png';
import practice_test_mobile from '../images/practice_test_mobile.webp';
import practice_test_mobile_png from '../images/practice_test_mobile.png';
import journal from '../images/journal.webp';
import journal_png from '../images/journal.png';
import graph from '../images/graph.webp';
import breakdown from '../images/breakdown.webp';
import perf from '../images/perf.webp';
import graph_png from '../images/graph.png';
import breakdown_png from '../images/breakdown.png';
import perf_png from '../images/perf.png';
import dashboard from '../images/dashboard.webp';
import dashboard_png from '../images/dashboard.png';
import home_banner from '../images/home_banner.webp';
import home_banner_jpg from '../images/home_banner.jpg';
import TryNow from "./TryNow"
import {jwtValid} from './Utils'

const Home = props => {
  const [show, doShow] = useState({
    itemOne: false,
    itemTwo: false,
    itemThree: false,
    itemFour: false               
  });

  const [percent, setPercent] = useState(0);

  const refOne = useRef(null),
        refTwo = useRef(null),
        refThree = useRef(null),
        refFour = useRef(null),                              
        notificationRef = useRef(null),
        arrowRef = useRef(null);      
  
  useLayoutEffect(() => {
    const arrowTopPos = element => element.offsetTop;
    const topPos = element => element.getBoundingClientRect().top;

    const arrowTop = arrowTopPos(arrowRef.current) + 100,
          notificationTop = arrowTopPos(notificationRef.current) + 100,
          div1Pos = topPos(refOne.current),
          div2Pos = topPos(refTwo.current),
          div3Pos = topPos(refThree.current),
          div4Pos = topPos(refFour.current);

    // For larger screens, show the complete arrow
    let tempScrollPos = window.innerHeight;
    let tempDiff = (tempScrollPos - arrowTop) / 2
    let tempHeight = notificationTop - arrowTop
    let tempPercent = Math.min(1.5, Math.max(0, tempDiff/tempHeight))
    setPercent(tempPercent)
    ///           

    const onScroll = () => {
      let scrollPos = window.scrollY + window.innerHeight;
      let diff = (scrollPos - arrowTop) / 2
      let height = notificationTop - arrowTop
      let percent = Math.min(1.5, Math.max(tempPercent, diff/height));
      setPercent(percent)

      if (div1Pos < scrollPos) {
        doShow(state => ({ ...state, itemOne: true }));
      } 
      if (div2Pos < scrollPos) {
        doShow(state => ({ ...state, itemTwo: true }));
      } 
      if (div3Pos < scrollPos) {
        doShow(state => ({ ...state, itemThree: true }));
      }
      if (div4Pos < scrollPos) {
        doShow(state => ({ ...state, itemFour: true }));
      }                                         
    };

    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="home">
      <section className="hero leading_section">
        <div className="hero-body">
                                       
          <div className="container">
            <div className="columns is-align-items-center">
              <div className="column is-7">           
                <div className="block">
                  <h1 className="title banner_title is-spaced">
                    Learn more in less time
                  </h1>
                  <h2 className="subtitle">
                    Optimize how you learn with <strong className="has-text-primary">Blend AI</strong> so you know what to study, how long to study, and when you've mastered a subject.
                  </h2>
                </div>
                {
                  !jwtValid(localStorage.getItem('jwt')) &&                
                    <div className="block">
                      <div className="buttons">
                        <Link to="/signup" className="button is-primary is-rounded is-medium">Sign Up</Link>
                        <TryNow buttonClass="is-medium"></TryNow>
                      </div>                
                    </div>
                }                
              </div>
              <div className="column is-5">
                <ImgWithFallback
                  src={home_banner}
                  fallback={home_banner_jpg}
                  alt="Girl in graduation gown looking up."
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="container">
        <div className="columns">
          <div className="column is-8 is-offset-2">
            <div className="has-text-centered arrows_notification box mobile_box has-background-gradient">
              <h1 className="title is-2">
                Prepform adapts to you and creates a personalized learning path.
              </h1>
            </div>
          </div>
        </div>
      </div>
      <div ref={arrowRef} className="container is-hidden-mobile">
        <div className="arrows_wrapper">      
          <Arrows percent={percent}/>
        </div>
      </div>
      <section className="section product_wrapper">
        <div ref={notificationRef} className="container">
          <div className="tile is-ancestor">
            <div className="tile is-parent">
              <article className="tile is-child box outlined_tile">
                <div className="icon_wrapper block">
                  <IconBrain/>
                </div>
                <p className="title">Student-driven</p>
                <div className="content">
                  <p className="subtitle">Guides you to diagnose, analyze, review, and evaluate how you learn.</p>
                  <p className="subtitle">Promotes active learning with interactive tools.</p>
                </div>
              </article>
            </div>
            <div className="tile is-parent">
              <article className="tile is-child box outlined_tile">
                <div className="icon_wrapper block">
                  <IconData/>
                </div>              
                <h1 className="title">Data-driven</h1>
                <div className="content">
                  <p className="subtitle">Enhances evidence-based learning strategies with machine learning and spaced-repetition.</p>
                  <p className="subtitle">Recommends what to study and when to review.</p>
                  <p className="subtitle">Visualizes data so you identify and target weaknesses.</p>
                </div>
              </article>
            </div>
          </div>
        </div>
      </section>
      <section className="section has-background-light growth_section pb-120 works_hero">
        <div className="container">
          <div className="columns">
            <div className="column has-text-centered">
              <h1 className="title is-1 mb-6">
                How it works
              </h1>
            </div>
          </div>
          <div className="columns is-desktop">
            <div className="column">
              <div className="content">
                <ol start="1">
                  <li>
                    <div>
                      <h1 className="title is-spaced">
                        Practice
                      </h1>
                      <p className="subtitle">
                        Follow AI recommendations to practice thousands of questions like those on real exams.
                      </p>
                    </div>
                  </li>
                </ol>
              </div>                                     
            </div>
            <div className="column">
              <div className="content">
                <ol start="2">
                  <li>
                    <div>
                      <h1 className="title is-spaced">
                        Analyze
                      </h1>
                      <p className="subtitle">
                        Use data visualization and score prediction to target your weak points.
                      </p>
                    </div>
                  </li>
                </ol>
              </div>              
            </div>
            <div className="column">
              <div className="content">
                <ol start="3">
                  <li>
                    <div>
                      <h1 className="title is-spaced">
                        Review
                      </h1>
                      <p className="subtitle">
                        Log your mistakes to identify what you did wrong and what you should have done instead.
                      </p>
                    </div>
                  </li>
                </ol>
              </div>              
            </div>
            <div className="column">
              <div className="content">
                <ol start="4">
                  <li>
                    <div>
                      <h1 className="title is-spaced">
                        Retry
                      </h1>
                      <p className="subtitle">
                        Retry your mistakes at optimal intervals to improve your understanding.
                      </p>
                    </div>
                  </li>
                </ol>
              </div>              
            </div>                                                
          </div>
        </div>           
      </section>
      <section className="section pt-120 has-background-dark-green">
        <div className="container">
          <div className="columns">
            <div className="column has-text-centered">
              <h1 className="title is-1 has-text-white">
                How it looks
              </h1>
            </div>
          </div>
        </div>
      </section>      
      <section className="section has-background-dark-green">
        <div className="container">
          <div className="columns is-align-items-center">
            <div className="column is-5">
              <p className="tertiary_title">Student-driven</p>
              <h1 className="title is-spaced has-text-white">Promote active learning</h1>
              <h2 className="subtitle is_secondary_font">Diagnose, analyze, review, and evaluate your performance.</h2>
              <h2 className="subtitle is_secondary_font">Log your mistakes to see why they happened and what you should have noticed.</h2>
              <h2 className="subtitle is_secondary_font">Immediate feedback and answer explanations reinforce key concepts and themes.</h2>
            </div>
            <div ref={refOne} className={`column is-offset-1 no_opacity ${show.itemOne ? "add_opacity" : ""}`}>
              <div className="desktop_box">
                <div className="desktop_box_bg"></div>
                <ImgWithFallback
                  className="box"
                  src={journal}
                  fallback={journal_png}
                  alt="Gain critical insights"
                />
              </div>           
            </div>
          </div>
        </div>
      </section>
      <section className="section has-background-dark-green">
        <div className="container">
          <div className="columns is-align-items-center is-hidden-mobile">
            <div ref={refTwo} className={`column is-6 is-relative no_opacity ${show.itemTwo ? "add_opacity" : ""}`}>
              <div className="desktop_box_bg"></div>
              <div className="is-relative">
                <div className="columns">
                  <div className="column">
                    <ImgWithFallback
                      className="box p-2"
                      src={perf}
                      fallback={perf_png}
                      alt="Solidify knowledge in less time"
                    />
                  </div>
                </div>
                <div className="columns">
                  <div className="column is-5">              
                    <ImgWithFallback
                      className="box p-2"
                      src={breakdown}
                      fallback={breakdown_png}
                      alt="Solidify knowledge in less time"
                    />
                  </div>
                  <div className="column is-7">
                    <ImgWithFallback
                      className="box p-2"
                      src={graph}
                      fallback={graph_png}
                      alt="Solidify knowledge in less time"
                    /> 
                  </div>
                </div>
              </div>                           
            </div>
            <div className="column is-offset-1">
              <p className="tertiary_title">Data-driven</p>
              <h1 className="title is-spaced has-text-white">Solidify knowledge in less time</h1>
              <h2 className="subtitle is_secondary_font">Raise your score in less time with adaptive pathways.</h2>
              <h2 className="subtitle is_secondary_font">Target what needs work and internalize what you learn with spaced-repetition.</h2>
            </div>
          </div>
          <div className="columns is-align-items-center is-hidden-tablet">
            <div className="column">
              <p className="tertiary_title">Data-driven</p>
              <h1 className="title is-spaced has-text-white">Solidify knowledge in less time</h1>
              <h2 className="subtitle is_secondary_font">Raise your score in less time with adaptive pathways.</h2>
              <h2 className="subtitle is_secondary_font">Target what needs work and internalize what you learn with spaced-repetition.</h2>
            </div>          
            <div ref={refThree} className={`column is-6 is-relative no_opacity ${show.itemThree ? "add_opacity" : ""}`}>
              <div className="desktop_box">
                <div className="desktop_box_bg"></div>
                <ImgWithFallback
                  className="box"
                  src={dashboard}
                  fallback={dashboard_png}
                  alt="Solidify knowledge in less time"
                />
              </div>
            </div>           
          </div>
        </div>
      </section>
      <section className="section pb-120 has-background-dark-green">
        <div className="container">
          <div className="columns is-align-items-center">
            <div className="column is-5">
              <p className="tertiary_title">Complete learning system</p>
              <h1 className="title is-spaced has-text-white">High-quality content</h1>
              <h2 className="subtitle is_secondary_font">Prepform unifies lessons, questions, and explanations to create a complete learning system.</h2>
              <h2 className="subtitle is_secondary_font block">Helps you discover the concepts and relationships in questions, and understand your mistakes.</h2>
            </div>
            <div ref={refFour} className={`column is-offset-1 no_opacity ${show.itemFour ? "add_opacity" : ""}`}>
              <div className="multiple_devises">
                <div className="desktop_box">
                  <div className="desktop_box_bg"></div>
                  <ImgWithFallback
                    className="box"
                    src={practice_test}
                    fallback={practice_test_png}
                    alt="Promote active learning"
                  />
                  <div className="mobile_screenshot">
                    <ImgWithFallback
                      className="box"
                      src={practice_test_mobile}
                      fallback={practice_test_mobile_png}
                      alt="Promote active learning mobile"
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {
        !jwtValid(localStorage.getItem('jwt')) &&
          <section className="section pt-120 pb-120">
            <div className="container has-text-centered">
              <div className="block">
                <h2 className="title is-2">
                  Ready to optimize how you learn?
                </h2>
              </div>
              <div className="block">
                <div className="buttons is-justify-content-center">
                  <Link to="/signup" className="button is-primary is-rounded is-medium">Sign Up</Link>
                  <TryNow buttonClass="is-medium"></TryNow>
                </div>                
              </div>
            </div>
          </section>
      }
      {/*<section className="section pt-120 pb-120">
        <div className="container">
          <div className="columns">
            <div className="column">
              <div className="box has-background-dark-alt p-6">  
                <p className="tertiary_title">For business</p>
                <h1 className="title has-text-white">
                  Add a new revenue stream
                </h1>
                <p className="subtitle has-text-white">Prepform creates white-label eLearning solutions for education, professional development, and training needs.</p>
                <Link to="/for-business" className="button is-white is-rounded">Learn more</Link>
              </div>
            </div>
          </div>
        </div>      
      </section>
      */}
      {/*<Orders addToCart={props.addToCart}/>*/}
    </div>   
  )
};
export default Home;