import React from 'react';
import Loading from "./Loading";
import {handleResponse} from './Utils'

// We use the Map construct for checkedTags for flexibility and convenience of setting and getting values
class EditFlashcard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      front: "",
      back: "", 
      tags: "",
      isLoaded: false,
      myErrors: {
        newFlashcard: ''
      },          
    };      

    this.onSubmit = this.onSubmit.bind(this);
    this.stripHtmlEntities = this.stripHtmlEntities.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt");
    const { match: { params: { id } } } = this.props;
    fetch(`/api/v1/flashcards/${id}`, { method: 'GET', headers: {'Authorization': token }})
    .then((flashcard) => handleResponse(flashcard))
    .then((flashcard) => this.setState({ ...flashcard, isLoaded: true })) 
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }  

  stripHtmlEntities(str) {
    return String(str)
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  onSubmit(event) {
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem("jwt")
    let id = this.state.id

    fetch(`/api/v1/flashcards/${id}`, {
      method: 'put',
      body: JSON.stringify(this.state),
      headers: { "Authorization": token, 'Content-Type': 'application/json' },
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })    
    .then((response) => {
      this.props.history.push("/flashcards");
    })
    .catch(error => console.log('error', error));
  }

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  handleCancel() {
    this.props.history.push("/flashcards");
  }

  handleDelete(event) {
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem("jwt")
    let id = this.state.id

    fetch(`/api/v1/flashcards/${id}`, {
      method: 'DELETE',
      body: JSON.stringify(this.state),
      headers: { "Authorization": token, 'Content-Type': 'application/json' },
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })    
    .then((response) => {
      this.props.history.push("/flashcards");
    })
    .catch(error => console.log('error', error));
  }

  render() {
    const {front, back, tags, myErrors} = this.state
    const {topic} = this.props.user
    if (this.state.isLoaded === false) {
      return (
        <Loading/>     
      )
    } else {
      return (
        <>      
          <h1 className="title mb-0">Edit Flashcard</h1>
          <p className="tertiary_gray mb-5">{topic}</p>
          <div className="box">
            <form onSubmit={this.onSubmit}>
              {this.myErrors && 
                <p className='help is-danger'>{this.myErrors.newFlashcard}</p>}      
              <div className="columns">
                <div className="column">
                  <div className="field">
                    <label className="label"> 
                      Front
                    </label>              
                    <div className="control">
                      <textarea className="textarea" rows="6" name="front" value={front} onChange={this.handleChange}></textarea>                                                 
                    </div>
                  </div>
                </div>
                <div className="column">
                  <div className="field">
                    <label className="label"> 
                      Back
                    </label>              
                    <div className="control">
                      <textarea className="textarea" rows="6" name="back" value={back} onChange={this.handleChange}></textarea>                                                
                    </div>
                  </div>
                </div>
              </div>
              <div className="columns">
                <div className="column">      
                  <div className="field">
                    <label className="label"> 
                      Tags
                    </label>              
                    <div className="control">
                      <input className="input" type="text" placeholder="Comma-separated tags" name="tags" value={tags} onChange={this.handleChange} onKeyPress={(e) => { e.key === 'Enter' && e.preventDefault(); }}/>
                    </div>
                  </div>
                </div>
              </div>
              <div className="button_wrapper">
                <div className="field">
                  <div className="control">
                    <div className="buttons">
                      <button type="submit" className="button is-dark">Update</button>
                      <button className="button is-light" onClick={this.handleCancel}>Cancel</button>
                    </div>
                  </div>
                </div>
                <div className="field">
                  <div className="control">
                    <button className="button is-danger" onClick={(e) => { if (window.confirm('Are you sure you wish to delete this flashcard?')) this.handleDelete(e) } }>Delete</button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </>
      );
    }    

  }      
}

export default EditFlashcard;