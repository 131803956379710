import React, { Component } from "react";
import PropTypes from "prop-types";
import { VictoryStack, VictoryBar } from 'victory';

class AccordionSection extends Component {
  static propTypes = {
    children: PropTypes.instanceOf(Object).isRequired,
    isOpen: PropTypes.bool.isRequired,
    label: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired
  };

  onClick = () => {
    this.props.onClick(this.props.label);
  };

  toPercent(length, totalLength) {
    return (
      ((length/totalLength) * 100).toFixed() + "%"
    )
  }  

  render() {
    const { onClick, props: { isOpen, label, correct, incorrect, skipped, totalQuestions, dataset, prediction } } = this;

    return (
      <>
        <tr onClick={onClick} style={{ cursor: "pointer" }}>
          <td className="mobile_position">
            {!isOpen && <span>+</span>}
            {isOpen && <span>-</span>}
          </td>          
          <td>
            {label}        
            <VictoryStack
              horizontal
              height={6}
              width={100}
              padding={0}
              colorScale={["#00b45a", "#363636", "#7a7a7a"]}
            >
              {dataset.map((data, i) => {
                return <VictoryBar barWidth={3} data={data} key={i}/>;
              })}
            </VictoryStack>            
          </td>
          <td>{correct} ({this.toPercent(correct, totalQuestions)})</td>
          <td>{incorrect} ({this.toPercent(incorrect, totalQuestions)})</td>
          <td>{skipped} ({this.toPercent(skipped, totalQuestions)})</td>
          <td className="has-text-weight-bold">
            {`${((prediction)*100).toFixed(1)}%`}
          </td>
        </tr>
        {isOpen && (
          this.props.children
        )}
      </>
    );
  }
}

export default AccordionSection;