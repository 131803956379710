import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faCircleNotch } from '@fortawesome/free-solid-svg-icons';
import Tabs from "./Tabs";
import QuestionModal from "./QuestionModal";
import {handleResponse} from './Utils'
import QuestionListModal from "./QuestionListModal";
import { createMistake } from './createMistake'
import Loading from "./Loading";

const tabList = [
  {
    name: "All"
  }, {
    name: "Correct"
  }, {
    name: "Incorrect"
  }, {
    name: "Starred"
  }, {
    name: "Skipped"
  }
];
class Test extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions_attributes: [],
      activeTab: "All",
      isLoaded: false,
      showModal: null
    };

    this.addHtmlEntities = this.addHtmlEntities.bind(this);
    this.renderResult = this.renderResult.bind(this);
    this.changeActiveTab = this.changeActiveTab.bind(this);
    this.toggleModal = this.toggleModal.bind(this);
    this.createMistakeEntry = this.createMistakeEntry.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt");
    const { match: { params: { id } } } = this.props;
    fetch(`/api/v1/tests/${id}/result`, { method: 'GET', headers: {'Authorization': token }})
    .then((test) => handleResponse(test))
    .then((test) => this.setState({ 
      ...test,
      isLoaded: true 
    }, () => {
      window.setMobileTable('.wide_table')
    }))      
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }

  // This is to convert escaped characters saved in test
  addHtmlEntities(str) {
    return String(str)
      .replace(/&lt;/g, "<")
      .replace(/&gt;/g, ">");
  }

  changeActiveTab(tab, e) {
    e.preventDefault()
    let questions = this.state.questions_attributes
    let filtered_questions = {}
    if (tab === "All") {
      filtered_questions = questions
    } else if (tab === "Correct") {
      filtered_questions = questions.filter(question => question.answer_attributes.correct === true)
    } else if (tab === "Incorrect") {
      filtered_questions = questions.filter(question => question.skipped === false && (question.answer_attributes.correct === false || !question.answer_attributes.correct))
    } else if (tab === "Starred") {
      filtered_questions = questions.filter(question => question.marked === true)
    } else if (tab === "Skipped") {
      filtered_questions = questions.filter(question => question.skipped === true)
    }
    this.setState({ 
      activeTab: tab,
      filtered_questions: filtered_questions 
    }, () => {
      window.setMobileTable('.wide_table')
    });
  }

  toggleModal(id, e) {
    e.preventDefault()
    const showModal = this.state.showModal === id ? null : id
    this.setState({showModal: showModal});
  };

  createMistakeEntry(question_id, event) {
    event.preventDefault();
    const body = {question_id}

    createMistake(body)
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors.newMistake = res.error
        this.setState({myErrors: myErrors})
      } else {
        this.props.history.push(`/mistakes/${res.id}/edit`);
      }
    })
    .catch(error => {
      this.props.setNotification({error: error.message}) 
    })    
  }  

  renderResult(){
    const {tags, activeTab, showModal, id, score, account_average_time, your_average_time} = this.state
    const user_topic = this.props.user.topic

    // Show this.state.questions_attributes on first render, then show filtered questions
    let questions = this.state.filtered_questions ? this.state.filtered_questions : this.state.questions_attributes
    
    const account_average_score = (this.state.account_average_score * 100).toFixed()

    if (questions) {
      var result = questions.map((value, index) => {
        let answer = value.answer_attributes
        let topic = tags.find(tag => tag.id === value.tag_id)

        return (
          <tr key={index}>
            <td>
              {(answer.correct) 
                ? <FontAwesomeIcon icon={faCheck}/>
                : <FontAwesomeIcon icon={faTimes}/>
              }
            </td>
            <td>
              <a href="#" onClick={(e) => this.toggleModal(value.id, e)}>{value.ancestry}</a>
              <QuestionModal index={index} value={value} id={value.id} showModal={showModal} closeModal={(e) => this.toggleModal(value.id, e)}/>
            </td>
            <td>
              {(topic)
                ? topic.name
                : "No topics"
              }
            </td>
            <td>{(value.question_percent_correct_average * 100).toFixed()}%</td>
            <td>{Number((value.time_spent/1000)).toFixed()}s</td>
            <td>{value.question_time_spent_average}s</td>
            <td>
              {value.mistake
                ?
                  <Link to={`/mistakes/${value.mistake.id}`}>View Journal Entry</Link> 
                :
                  <Link to={`/mistakes/new?question_id=${value.ancestry}`}>Log Question</Link>
              }
            </td>
          </tr>
        )
      });
      return (
        <>
          <QuestionListModal questions={questions} showModal={showModal} toggleModal={(e) => this.toggleModal("questionList", e)}/>
          {score === null
            ?
              <>
                <h1 className="title mb-0">Test Result</h1>
                <p className="tertiary_gray mb-5">{user_topic}</p>
                <article className="message is-dark">
                  <div className="message-body">
                    This test has not been scored.
                  </div>
                </article>
              </>            
            :
              <>
                <div className="button_wrapper">
                  <div>
                    <h1 className="title mb-0">Test Result</h1>
                    <p className="tertiary_gray mb-5">{user_topic}</p>
                  </div>
                  <div className="buttons">
                    <button className="button is-white" onClick={(e) => this.toggleModal("questionList", e)}>Question List</button>
                    <Link to={`/tests/${id}/analysis`} className="button is-primary">Test Analysis</Link>         
                  </div>
                </div>
                <div className="columns is-mobile is-multiline mb-0">
                  <div className="column is-half-mobile">
                    <div>
                      <p className="heading">Your Score</p>
                      <p className="title is-1">{Math.round((score + Number.EPSILON) * 100) / 100}%</p>
                    </div>            
                  </div>
                  <div className="column is-half-mobile">
                    <div>
                      <p className="heading">Average Score of All Users</p>
                      <p className="title is-1">{account_average_score}%</p>
                    </div>            
                  </div>
                  <div className="column is-half-mobile">
                    <div>
                      <p className="heading">Your Avg. Time per Question</p>
                      <p className="title is-1">{your_average_time}s</p>
                    </div>            
                  </div>
                  <div className="column is-half-mobile">
                    <div>
                      <p className="heading">Avg. Time per Question of All Users</p>
                      <p className="title is-1">{account_average_time}s</p>
                    </div>            
                  </div>                                    
                </div>
                <div className="columns">
                  <div className="column">
                    <div className="box">
                      <Tabs tabList={tabList}
                            activeTab={activeTab}
                            changeActiveTab={this.changeActiveTab}
                      />                   
                      {questions.length === 0
                        ? 
                          <span>No {activeTab} Questions</span>
                        :
                          <div className="table-container wide_table">
                            <table className="table is-fullwidth">
                              <thead>
                                <tr>
                                  <th>Correct</th>
                                  <th>Question</th>
                                  <th>Topic</th>
                                  <th>Average</th>
                                  <th>Time Spent</th>
                                  <th>Avg. Time Spent</th>
                                  <th>Action</th>
                                </tr>
                              </thead>
                              <tbody>
                                {result}
                              </tbody>
                            </table>
                          </div>
                      }
                    </div>
                  </div>
                </div>
              </>        
          }
        </>
      );
    }
  }  

  render() {
    if (this.state.isLoaded === false) {
      return (
        <Loading/>
      )
    } else {
      return (
        <>
          {this.renderResult()}
        </>
      );
    }   
  }    
}

export default Test;