import React from "react";
import { Link } from "react-router-dom";

const TableRow = (props) => {
  return (
    <>
      <tr>
        <td>
          <span style={{paddingLeft: `${20 * props.indent}px`}}>{props.lesson.name}</span>
        </td>
        <td><Link to={`/lessons/${props.lesson.id}`}>View</Link></td>
      </tr>
    </>
  );
}

export default TableRow;