import React from 'react';
import Latex from "./Latex";
import QuestionModal from "./QuestionModal";
import ReactHtmlParser from 'react-html-parser';

// We use the Map construct for checkedTags for flexibility and convenience of setting and getting values
class Search extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions: [],
      query: "",
      isLoading: false,
      error: null,
      message: null,
      showModal: false
    };    
    this.onSubmit = this.onSubmit.bind(this);
    this.stripHtmlEntities = this.stripHtmlEntities.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  stripHtmlEntities(str) {
    return String(str)
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }
 
  onSubmit(event) {
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem("jwt")
    const { query } = this.state;
    this.setState({ isLoading: true });
    fetch('/api/v1/search?query=' + query, { method: 'GET', headers: {'Authorization': token }})
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Something went wrong ...');
      }      
    })
    .then(data => this.setState({ questions: data.questions, message: data.message, isLoading: false }))
    .catch(error => this.setState({ error }));

  } 

  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }

  toggleModal(id, e) {
    e.preventDefault()
    const showModal = this.state.showModal === id ? null : id
    this.setState({showModal: showModal});
  };  

  render() {
    const {questions, query, error, isLoading, message, showModal} = this.state
    const searchResults = questions.map((question, index) => (
      <tr key={index}>
        <td><a href="#" onClick={(e) => this.toggleModal(question.id, e)}>{question.id}</a></td>
        <td>
          {ReactHtmlParser(question.content)}
        </td>
        <td>
          <QuestionModal index={index} value={question} id={question.id} showModal={showModal} closeModal={(e) => this.toggleModal(question.id, e)}/>
        </td>
      </tr>
    ));

    if (isLoading) {
      return <p>Loading ...</p>;
    }    
    
    if (error) {
      return <p>{error.message}</p>;
    }       
    return (
      <Latex>
        <h1 className="title">
          Search
        </h1>
        <div className="box">
          <div className="block">        
            <form onSubmit={this.onSubmit}>   
              <div className="field">            
                <div className="control">
                  <input className="input" type="text" placeholder="Search used questions by id or keywords" name="query" value={query} onChange={this.handleChange}/>
                </div>
              </div>
              <div className="field">
                <div className="buttons">
                  <button type="submit" className="button is-dark">Search</button>
                </div>
              </div>        
            </form>
          </div>
          {message &&
            <div className="block">{message}</div>
          }
          {questions.length > 0 &&
            <div className="block">
              <div className="table-container">
                <table className="table is-narrow">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Question</th>
                    </tr>
                  </thead>
                  <tbody>
                    {searchResults}
                  </tbody>
                </table>            
              </div>
            </div>
          }
        </div>
      </Latex>
    );
  }      
}

export default Search;