import React from "react";

const ImgWithFallback = ({
  className,
  src,
  fallback,
  type = 'image/webp',
  ...delegated
}) => {
  return (
    <picture>
      <source srcSet={src} type={type} />
      <img className={className} src={fallback} {...delegated} />
    </picture>
  );
};

export default ImgWithFallback