import React from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRobot} from '@fortawesome/free-solid-svg-icons';
import { faSmile, faFrown, faMeh } from '@fortawesome/free-regular-svg-icons';
import ReactHtmlParser from 'react-html-parser'; 
import {handleResponse} from './Utils'
import Loading from "./Loading";
import moment from 'moment/moment.js';
import Latex from "./Latex";

class Lesson extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoaded: false
    };
    this.stripHtmlEntities = this.stripHtmlEntities.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt")
    const { match: { params: { id } } } = this.props;
    fetch(`/api/v1/lessons/${id}`, { method: 'GET', headers: {'Authorization': token }})
    .then((lesson) => handleResponse(lesson))
    .then(response => this.setState({ ...response, isLoaded: true }))
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })    
  }

  stripHtmlEntities(str) {
    return String(str)
      .replace(/</g, "&lt;")
      .replace(/>/g, "&gt;");
  }

  submitPerformanceRating(rating, event) {
    event.preventDefault();
    let token = "Bearer " + localStorage.getItem("jwt")
    let {id, next_lesson} = this.state

    fetch(`/api/v1/lessons/${id}`, {
      method: 'put',
      body: JSON.stringify({lesson: {performance_rating: rating}}),
      headers: { "Authorization": token, 'Content-Type': 'application/json' },
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      }
      throw new Error("Network response was not ok.");
    })    
    .then((response) => {
      if (next_lesson) {
        this.props.history.push(`/lessons/${next_lesson.id}`);
        this.setState({isLoaded: false}, () => this.componentDidMount());   
      } else {
        this.props.history.push("/dashboard");
      }
    })
    .catch(error => console.log('error', error));
  }      

  render() {
    const {name, content, rating_low, rating_medium, rating_high} = this.state;

    if (this.state.isLoaded === false) {
      return (
        <Loading/>    
      )
    } else {
      return (
        <>       
          <h1 className="title">{name}</h1>
          <div className="box">
            <div className="content">
              <Latex>
                {ReactHtmlParser(content)}
              </Latex>
            </div>
            <div className="ai_recommendation message">
              <span className="icon">
                <FontAwesomeIcon icon={faRobot}/>
              </span>          
              <div>              
                <p className="title is-5 has-text-weight-bold">
                  AI Recommendation
                </p>
                <p className="subtitle is-6">
                  {`Review in ${moment(rating_medium).diff(moment(), 'days')} days`}
                </p>
              </div>
            </div>
            <div className="buttons">
              <button className="button" onClick={(e) => this.submitPerformanceRating(0, e)} value="Low">
                <span className="icon">
                  <FontAwesomeIcon icon={faFrown}/>
                </span>
                <span>{`< 1 minute`}</span>
              </button>
              <button className="button" onClick={(e) => this.submitPerformanceRating(3, e)} value="Medium">
                <span className="icon">
                  <FontAwesomeIcon icon={faMeh}/>
                </span>
                <span>{`in ${moment(rating_medium).diff(moment(), 'days')} days`}</span>
              </button>
              <button className="button" onClick={(e) => this.submitPerformanceRating(5, e)} value="High">
                <span className="icon">
                  <FontAwesomeIcon icon={faSmile}/>
                </span>
                <span>{`in ${moment(rating_high).diff(moment(), 'days')} days`}</span>
              </button>
            </div>
          </div>          
        </>
      );
    }    
  }    

}
export default Lesson;