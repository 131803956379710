import React from "react";
import { Link, NavLink } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faTachometerAlt, faChalkboard, faPlus, faBook, faInbox, faMoneyCheck } from '@fortawesome/free-solid-svg-icons';
import {handleResponse} from './Utils'
import ImgWithFallback from './ImgWithFallback';
import logo from '../images/logo.webp';
import logo_png from '../images/logo.png';
import logoWhite from '../images/logo_white.webp';
import logoWhite_png from '../images/logo_white.png';

class Menu extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: false,
      showDropdown: false,
      slideInOrOut: ""
    };
    this.toggleClass= this.toggleClass.bind(this);
    this.toggleDropdown = this.toggleDropdown.bind(this);
  }

  componentDidMount() {
    window.addEventListener('resize', () => {
      if (window.innerWidth > 768) {
        this.setState({
          active: false,
          slideInOrOut: ""
        });
      }
    })
    let token = "Bearer " + localStorage.getItem("jwt")
    const url = "/api/v1/users/current";
    fetch(url, { method: 'GET', headers: {'Authorization': token }})
    .then((user) => handleResponse(user))
    .then(response => this.props.setUserState(response))
  }

  componentWillUnmount() {
    window.removeEventListener('resize', () => {
      if (window.innerWidth > 768) {
        this.setState({
          active: false,
          slideInOrOut: ""
        });
      }
    })
  }          
  
  toggleDropdown() {
    this.setState(prevState => ({
      showDropdown: !prevState.showDropdown
    }));
  }

  toggleClass() {
    const currentState = this.state.active;
    this.setState({
      active: !currentState
    }, () => {
      const newState = this.state.active;
      const slideInOrOut = newState ? "slide-in" : "slide-out"
      this.setState({
        slideInOrOut: slideInOrOut
      })
    });
  }; 

  render () {
    const active = this.state.active
    let {showDropdown, slideInOrOut} = this.state
    let {id, first_name, last_name, test_date, diagnostic_status, topic} = this.props.user    
    return (
      <>
        {Object.keys(this.props.user).length === 0
          ?
            <div></div>
          : 
            <nav className="navbar flex_navbar" role="navigation" aria-label="main navigation">
              <div className="is-hidden-tablet">
                <a aria-expanded="false" aria-label="menu" className={`navbar-burger burger ${active ? 'is-active': ''}`} role="button" ref={this.burger} onClick={this.toggleClass}>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                  <span aria-hidden="true"></span>
                </a>
              </div>
              <div className="navbar-brand is-hidden-tablet">
                <Link to="/dashboard" className="navbar-item mobile_brand">
                  PREPFORM
                </Link>
              </div>
              {first_name
                ?
                  diagnostic_status &&
                    diagnostic_status[topic] === -1
                      ?
                        <p className="is-size-7 is-hidden-mobile">Hi, {first_name}!</p>
                      :
                        <p className="is-size-7 is-hidden-mobile">Welcome back, {first_name}!</p>
                :
                  <p></p>
              }              
              <div className={`navbar-item has-dropdown user_icon ${showDropdown ? 'is-active' : ""}`} onClick={this.toggleDropdown}>
                <a className="navbar-link is-arrowless">
                  <FontAwesomeIcon icon={faUser}/> 
                </a>
                <div className="navbar-dropdown is-right">
                  {localStorage.getItem('guest')
                    ?
                      <NavLink className="navbar-item" to="/logout">Log Out</NavLink>
                    :
                      <>
                        <NavLink className="navbar-item" to={`/users/${id}`}>My Account</NavLink>
                        <hr className="navbar-divider"></hr>
                        <NavLink className="navbar-item" to="/logout">Log Out</NavLink>
                      </>
                  }
                </div>
              </div>              
            </nav>    
        }
        <div className={`backgroundOverlay ${active ? 'is-active': ''}`} onClick={this.toggleClass}></div>
        <div className={`div20 menu_layout_sidebar ${slideInOrOut}`}>
          <nav className="navbar mobile_flex_navbar" role="navigation" aria-label="main navigation">
            <div className="navbar-brand">                     
              <Link to="/dashboard" className="navbar-item mobile_brand is-hidden-mobile">
                <img src={logo_png}/>
              </Link>               
            </div>
          </nav>
          <div className="menu_wrapper">
            <aside className="menu">        
              <ul className="menu-list block">
                <li>
                  <NavLink className="icon_wrapper" activeClassName="is-active" exact to="/dashboard" onClick={this.toggleClass}>
                    <FontAwesomeIcon icon={faTachometerAlt} size='lg'/>
                    <span className="is-size-7">Dashboard</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="icon_wrapper" activeClassName="is-active" exact to="/tests/new" onClick={this.toggleClass}>
                    <FontAwesomeIcon icon={faPlus} size='lg'/>
                    <span className="is-size-7">Create Test</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className="icon_wrapper" activeClassName="is-active" exact to="/tests" onClick={this.toggleClass}>
                    <FontAwesomeIcon icon={faInbox} size='lg'/>
                    <span className="is-size-7">Previous Tests</span>
                  </NavLink>
                </li>
                {/*
                <li>
                  <NavLink className={`${window.location.href.indexOf("lessons") > -1 ? "is-active icon_wrapper" : "icon_wrapper"}`} exact to="/lessons" onClick={this.toggleClass}>
                    <FontAwesomeIcon icon={faChalkboard} size='lg'/>
                    <span className="is-size-7">Lessons</span>
                  </NavLink>
                </li>
              */}
                <li>
                  <NavLink className={`${window.location.href.indexOf("mistakes") > -1 ? "is-active icon_wrapper" : "icon_wrapper"}`} exact to="/mistakes" onClick={this.toggleClass}>
                    <FontAwesomeIcon icon={faBook} size='lg'/>
                    <span className="is-size-7">Mistake Journal</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink className={`${window.location.href.indexOf("flashcards") > -1 ? "is-active icon_wrapper" : "icon_wrapper"}`} exact to="/flashcards" onClick={this.toggleClass}>
                    <FontAwesomeIcon icon={faMoneyCheck} size='lg'/>
                    <span className="is-size-7">Flashcards</span>
                  </NavLink>
                </li>
              </ul>                 
            </aside>
          </div>                     
        </div>
      </> 
    )  
  }
}


export default Menu