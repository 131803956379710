export const createUser = (user) => {
  return fetch('/api/v1/users', {
    method: 'POST',
    body: JSON.stringify(user),     
    headers: {'Content-Type': 'application/json',
              'Accept': 'application/json',
    }
  })
  .then(res => res.json())
}

