import React from "react";
import { Link } from "react-router-dom";
import {handleResponse} from './Utils'
import Loading from "./Loading";

import { VictoryStack, VictoryBar, VictoryPie, VictoryLabel } from 'victory';

class TestAnalysis extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      questions_attributes: [],
      isLoaded: false, 
      tagFilter: ""
    };
    this.filterTags= this.filterTags.bind(this);
  }

  componentDidMount() {
    let token = "Bearer " + localStorage.getItem("jwt");
    const { match: { params: { id } } } = this.props;
    fetch(`/api/v1/tests/${id}`, { method: 'GET', headers: {'Authorization': token }})
    .then((test) => handleResponse(test))
    .then((test) => this.setState({ ...test }, () => {this.setTagBreakdown();}))
    .catch(error => {
      this.props.history.push("/")
      this.props.setNotification({error: error})
    })
  }

  // We set all the attributes we need in one loop
  setTagBreakdown() {
    const questions = this.state.questions_attributes;
    const tags = this.state.tags
    const tagBreakdown = {}
    const questionCount = {correct: 0, incorrect: 0, skipped: 0, correctToIncorrect: 0, incorrectToCorrect: 0, incorrectToIncorrect: 0}

    for (var x = 0; x < questions.length; x++) {
      let tag = tags.find(tag => tag.id == questions[x].tag_id)
      let tagName = tag.name

      tagBreakdown["tags"] = tagBreakdown["tags"] || {}
      tagBreakdown["tags"][tagName] = tagBreakdown["tags"][tagName] || {}
      tagBreakdown["tags"][tagName]["barChartData"] = tagBreakdown["tags"][tagName]["barChartData"] || [[{x: "a", y: 0}], [{x: "a", y: 0}], [{x: "a", y: 0}]]
      if (questions[x].answer_attributes.correct === true) {
        questionCount["correct"]++;
        tagBreakdown["tags"][tagName]["correct"] = tagBreakdown["tags"][tagName]["correct"] || 0
        tagBreakdown["tags"][tagName]["correct"]++;
        tagBreakdown["tags"][tagName]["barChartData"][0] = [{x: "a", y: tagBreakdown["tags"][tagName]["correct"]}]
      }
      if (questions[x].answer_attributes.correct === false && questions[x].answer_attributes.letter != "") {
        questionCount["incorrect"]++;
        tagBreakdown["tags"][tagName]["incorrect"] = tagBreakdown["tags"][tagName]["incorrect"] || 0
        tagBreakdown["tags"][tagName]["incorrect"]++;      
        tagBreakdown["tags"][tagName]["barChartData"][1] = [{x: "a", y: tagBreakdown["tags"][tagName]["incorrect"]}]
      }
      if (questions[x].answer_attributes.letter === "") {
        questionCount["skipped"]++;
        tagBreakdown["tags"][tagName]["skipped"] = tagBreakdown["tags"][tagName]["skipped"] || 0
        tagBreakdown["tags"][tagName]["skipped"]++;
        tagBreakdown["tags"][tagName]["barChartData"][2] = [{x: "a", y: tagBreakdown["tags"][tagName]["skipped"]}]
      }
      if (questions[x].answer_tracker[0] === "incorrect" && questions[x].answer_tracker[1] === "correct") {
        questionCount["correctToIncorrect"]++;
      }
      if (questions[x].answer_tracker[0] === "correct" && questions[x].answer_tracker[1] === "incorrect") {
        questionCount["incorrectToCorrect"]++;
      }
      if (questions[x].answer_tracker[0] === "incorrect" && questions[x].answer_tracker[1] === "incorrect") {
        questionCount["incorrectToIncorrect"]++;
      }                              
    }
    this.setState({
      tagBreakdown: tagBreakdown,
      questionCount: questionCount,
      isLoaded: true
    }, () => {
      window.setMobileTable('.wide_table')
    })
  }

  renderTagsTable(){
    const tagBreakdown = this.state.tagBreakdown
    const filter = this.state.tagFilter.toLowerCase()
    const tags = this.state.tags.filter(tag => tag.name.toLowerCase().includes(filter))
    const tagsNames = tags.map(a => a.name);

    return (
      <>
        {tags.length === 0
          ?
            <tr><td>No Results</td></tr>
          :
            Object.entries(tagBreakdown.tags).map(([key, value]) => {
              if (tagsNames.includes(key)) {
                const correct = value.correct || 0
                const incorrect = value.incorrect || 0
                const skipped = value.skipped || 0
                const totalQuestions = correct + incorrect + skipped
                const dataset = this.transformData(value.barChartData);
                return (
                  <tr key={key}>
                    <td>
                      {key}
                      <VictoryStack
                        horizontal
                        height={6}
                        width={100}
                        padding={0}
                        colorScale={["#00b45a", "#363636", "#7a7a7a"]}
                      >
                        {dataset.map((data, i) => {
                          return <VictoryBar barWidth={3} data={data} key={i}/>;
                        })}
                      </VictoryStack>            
                    </td>
                    <td>{correct || 0} ({((correct/totalQuestions) * 100).toFixed(0)}%)</td>
                    <td>{incorrect || 0} ({((incorrect/totalQuestions) * 100).toFixed()}%)</td>
                    <td>{skipped || 0} ({((skipped/totalQuestions) * 100).toFixed()}%)</td>
                  </tr>
                )
              }
            })
            
        }
      </>
    )
  }

  transformData(dataset) {
    const totals = dataset[0].map((data, i) => {
      return dataset.reduce((memo, curr) => {
        return memo + curr[i].y;
      }, 0);
    });
    return dataset.map((data) => {
      return data.map((datum, i) => {
        return { x: datum.x, y: (datum.y / totals[i]) * 100 };
      });
    });
  }

  toPercent(length, totalLength) {
    return (
      ((length/totalLength) * 100).toFixed() + "%"
    )
  }

  filterTags(event) {
    this.setState({
      tagFilter: event.target.value
    }, () => {
      window.setMobileTable('.wide_table')
    });
  }

  render() {
    const questions = this.state.questions_attributes;
    const {id, questionCount, score, tagFilter} = this.state
    const {topic} = this.props.user

    if (this.state.isLoaded === false) {
      return (
        <Loading/>     
      )
    } else {
      const correctLength = questionCount.correct
      const incorrectLength = questionCount.incorrect
      const skippedLength = questionCount.skipped
      const questionLength = correctLength + incorrectLength + skippedLength
      const correctToIncorrect = questionCount.correctToIncorrect
      const incorrectToCorrect = questionCount.incorrectToCorrect
      const incorrectToIncorrect = questionCount.incorrectToIncorrect
      return (
        <>
          {score === null
            ?
              <article className="message">
                <div className="message-body">
                  This test has not been scored.
                </div>
              </article>            
            :
              <div>
                <div className="button_wrapper">
                  <div>
                    <h1 className="title mb-0">Test Analysis</h1>
                    <p className="tertiary_gray mb-5">{this.props.user.topic}</p>
                  </div>
                  <div className="buttons">
                    <Link to={`/tests/${id}`} className="button is-white">Review Test</Link>
                    <Link to={`/tests/${id}/result`} className="button is-link">Test Results</Link>
                  </div>          
                </div>
                <div className="tile is-ancestor">
                  <div className="tile is-parent">
                    <div className="tile is-child box">
                      <h6 className="title is-6">Percentage Breakdown</h6>
                      <svg viewBox="0 0 400 400" className="pie_svg">
                        <VictoryPie
                          standalone={false}
                          width={400}
                          height={400}
                          innerRadius={130}
                          style={{ labels: { display: "none"} }}
                          colorScale={["#00b45a", "#363636", "#7a7a7a"]}
                          data={[
                            { x: this.toPercent(correctLength, questionLength), y: correctLength },
                            { x: this.toPercent(incorrectLength, questionLength), y: incorrectLength },
                            { x: this.toPercent(skippedLength, questionLength), y: skippedLength }
                          ].filter(Boolean)}                                      
                        />
                        <VictoryLabel
                          textAnchor="middle"
                          style={[{ fontSize:60, fontFamily: "Helvetica, sans-serif"}, {fontSize:30, fontFamily: "Helvetica, sans-serif"}]}
                          x={200} y={200}
                          text={[questionLength, "Questions"]}
                        />
                      </svg>
                      <nav className="level is-mobile">
                        <div className="level-item has-text-centered correct_level">
                          <div>
                            <p className="title">{this.toPercent(correctLength, questionLength)}</p>
                            <p className="heading">{`Correct (${correctLength})`}</p>
                          </div>
                        </div>
                        <div className="level-item has-text-centered incorrect_level">
                          <div>
                            <p className="title">{this.toPercent(incorrectLength, questionLength)}</p>
                            <p className="heading">{`Incorrect (${incorrectLength})`}</p>
                          </div>
                        </div>
                        <div className="level-item has-text-centered skipped_level">
                          <div>
                            <p className="title">{this.toPercent(skippedLength, questionLength)}</p>
                            <p className="heading">{`Skipped (${skippedLength})`}</p>
                          </div>
                        </div>
                      </nav>                                              
                    </div>
                  </div>
                  <div className="tile is-parent">
                    <div className="tile is-child box">
                      <h6 className="title is-6">Answer Changes</h6>
                      <table className="table is-fullwidth">
                        <tbody>
                          <tr>
                            <td>Correct to Incorrect</td>
                            <td><span className="tag is-light">{correctToIncorrect}</span></td>
                          </tr>
                          <tr>
                            <td>Incorrect to Correct</td>
                            <td><span className="tag is-light">{incorrectToCorrect}</span></td>
                          </tr>
                          <tr>
                            <td>Incorrect to Incorrect</td>
                            <td><span className="tag is-light">{incorrectToIncorrect}</span></td>
                          </tr>
                        </tbody>
                      </table>   
                    </div>                                   
                  </div>                    
                </div>
                <div className="columns">
                  <div className="column">
                    <div className="box">
                      <div className="button_wrapper">
                        <h4 className="title is-6">Topics</h4>
                        <div className="control">
                          <input className="input" type="text" placeholder="Filter" value={tagFilter} onChange={this.filterTags}/>
                        </div>
                      </div>
                      <div className="table-container wide_table">
                        <table className="table is-fullwidth svg_table">
                          <thead>
                            <tr>
                              <th>Name</th>
                              <th>Correct</th>
                              <th>Incorrect</th>
                              <th>Skipped</th>
                            </tr>
                          </thead>
                          <tbody>
                            {this.renderTagsTable()}
                          </tbody>
                        </table>
                      </div>                                    
                    </div>
                  </div>                    
                </div>                         
              </div>        
          }
        </>
      );
    }    
  }    

}
export default TestAnalysis;