import React from "react";
import { Link, NavLink } from "react-router-dom";
import { HashLink } from 'react-router-hash-link';
import DemoEmail from "./DemoEmail"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShoppingCart } from '@fortawesome/free-solid-svg-icons';
import {jwtValid} from './Utils'
import { createUser } from './createUser'
import history from "./history";
import { createCartTransfer } from './createCartTransfer'

class Navigation extends React.Component {

  constructor(props) {
    super(props);
    this.state = {
      buttonClass: "is-dark-light",
      inputClass: "",
      navbarClass: "is-transparent"
    }
    this.mobileMenu = React.createRef();
    this.burger = React.createRef();
    this.handleScroll = this.handleScroll.bind(this);
  }  

  componentDidMount() {
    window.addEventListener('scroll', this.handleScroll)
    // This adds hamburger functionality to navbar
    const $navbarBurgers = Array.prototype.slice.call(document.querySelectorAll('.navbar-burger'), 0);
    if ($navbarBurgers.length > 0) {
      $navbarBurgers.forEach( el => {
        el.addEventListener('click', () => {
          const target = el.dataset.target;
          const $target = document.getElementById(target);
          el.classList.toggle('is-active');
          $target.classList.toggle('is-active');
          if ($target.classList.contains('is-active')) {
            // This is so you cant scroll when mobile nav is open
            document.body.style.overflowY = 'hidden';
            document.body.style.height = '100%';   
            document.documentElement.style.overflowY = 'hidden';
            document.documentElement.style.height = '100%';
          } else {
            // This undoes stopping scroll when mobile nav is open
            document.body.style.overflowY = '';
            document.body.style.height = '';
            document.documentElement.style.overflowY = '';
            document.documentElement.style.height = '';              
          }
        });
      });
    }  
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }  

  handleScroll(event) {
    if (document.getElementsByClassName("navbar-menu is-active").length === 0) {
      if (window.scrollY > 1) {
        this.setState({
          buttonClass: "is-primary",
          inputClass: "dark_border",
          navbarClass: "is-white is-transparent"
        })
      } else {
        this.setState({
          buttonClass: "is-dark-light",
          inputClass: "",
          navbarClass: "is-transparent"
        })      
      }
    }
  }  

  closeMobileMenu() {
    const mobileMenu = this.mobileMenu.current;
    const burger = this.burger.current;
    if (mobileMenu && burger) {
      mobileMenu.classList.remove('is-active')
      burger.classList.remove('is-active')
      // This undoes stopping scroll when mobile nav is open
      document.body.style.overflowY = '';
      document.body.style.height = '';
      document.documentElement.style.overflowY = '';
      document.documentElement.style.height = '';             
    }
  }

  async setJwtAndTransferCart(data) {

    await localStorage.removeItem('valid_plans');
    await localStorage.setItem("jwt", data.jwt);

    createCartTransfer()
    .then(res => {
      if(res.error) {
        this.setState({
          isLoaded: true
        });        
        let myErrors = {}
        myErrors.login = res.error
        this.setState({myErrors: myErrors})
      } else {
        this.setState({
          products: res.products
        }, () => {
          this.setState({
            isLoaded: true
          }); 
          {/*         
          if (res.plan_status === "valid_paid") {
            localStorage.setItem('plan_valid', true)
            if (res.user.topic) {
              history.push("/dashboard");
            } else {
              history.push("/for-students");
            }
          } else if (res.plan_status === "expired_paid") {
            history.push("/upgrade");
            this.props.setNotification({notice: "Your subscription is expired. Please upgrade your plan."});            
          } else if (res.plan_status === "no_plan") {
            if (this.state.products.length > 0) {
              history.push("/checkout");
            } else {
              history.push("/orders");
            }
            localStorage.setItem('plan_valid', true)
            if (res.user.topic) {
              history.push("/dashboard");
            } else {
              history.push("/for-students");
            }          
          }
        */}
          localStorage.setItem('valid_plans', JSON.stringify(res.topics_list_include_free))
          history.push("/for-students");
        });
      }
    })    
  }    

  createGuestUser(event) {
    event.preventDefault();
    this.closeMobileMenu();

    const email = `guest_${Date.now()}${Math.floor(Math.random() * 100)}@prepform.com`
    const password = "secret123S"
    
    localStorage.setItem("guest", true);

    createUser({"user": {"guest": true, "email": email, "password": password}})
    .then(response => {

      const request = {"auth": {"email": email, "password": password}};    
      fetch('/api/v1/user_token', {
        method: 'POST',
        body: JSON.stringify(request),     
        headers: {'Content-Type': 'application/json', 'Accept': 'application/json' }
      })    
      .then(response => {
        if (response.ok) {
          return response.json();
        }
        throw new Error("Network response was not ok.");
      })    
      .then((data) => {
        this.setJwtAndTransferCart(data)     
      })
    })     
    .catch(error => {
      history.push("/login");
    });
  }

  render () {
    const {products, match, logo} = this.props
    const {buttonClass, inputClass, navbarClass} = this.state
    return (
      <nav aria-label="main navigation" className={`navbar is-fixed-top ${navbarClass}`} role="navigation">
        <div className="navbar-brand">
          <Link to="/" className="navbar-item">
            <img src={logo}/>
            <h1 className="logo">PREPFORM</h1>
          </Link>
          <a aria-expanded="false" aria-label="menu" className="navbar-burger burger" data-target="prepformNavbar" role="button" ref={this.burger}>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
            <span aria-hidden="true"></span>
          </a>
        </div>
        <div className="navbar-menu" id="prepformNavbar" ref={this.mobileMenu}>
        {
          <div className={`navbar-start ${match.path === "/" ? "" : "active_nav_wrapper"}`}>
            <NavLink to="/for-students" activeClassName="is-active" className="navbar-item" onClick={this.closeMobileMenu()}>For Students</NavLink>
            {/*<NavLink to="/student-driven" activeClassName="is-active" className="navbar-item" onClick={this.closeMobileMenu()}>Student-driven</NavLink>*/}
            {/*<NavLink to="/for-business" activeClassName="is-active" className="navbar-item" onClick={this.closeMobileMenu()}>For Business</NavLink>*/}
            <NavLink to="/product" activeClassName="is-active" className="navbar-item" onClick={this.closeMobileMenu()}>Product</NavLink>
            {/*<HashLink to="/#pricing" className="navbar-item" onClick={this.closeMobileMenu()}>Pricing</HashLink>*/}
            <NavLink to="/about" activeClassName="is-active" className="navbar-item" onClick={this.closeMobileMenu()}>About</NavLink>
          </div>

        }
        <div className="navbar-end is-hidden-touch">
          {/*
          <div className="navbar-item">
            <NavLink className="cart_link" exact to="/cart" onClick={this.closeMobileMenu()}><FontAwesomeIcon icon={faShoppingCart}/><span className="tag is-dark is-rounded">{products.length}</span></NavLink>
          </div>
        */}
          <div className="navbar-item">
            {
              jwtValid(localStorage.getItem('jwt')) ?
                <>
                  {localStorage.getItem('guest')
                    ?
                      <>
                        <p className="navbar-item">Logged in as&nbsp;<strong>Guest</strong></p>
                        <Link to="/signup" className="button is-rounded is-info" onClick={this.closeMobileMenu()}>Become a Member</Link>
                      </>
                    :
                      <NavLink exact className="log_in navbar-item" to="/logout" onClick={this.closeMobileMenu()}>Log Out</NavLink>
                  }               
                </>
              :
                <>
                  <NavLink exact className="navbar-item log_in" activeClassName="active" to="/login" onClick={this.closeMobileMenu()}>Log In</NavLink>
                  <div className="buttons">
                    <Link to="/signup" className={`button is-rounded demo_button ${buttonClass}`} onScroll={this.handleScroll} onClick={(e) => this.createGuestUser(e)}><strong>Try It Now</strong></Link>
                    {/*<Link to="/signup" className={`button is-rounded`} onScroll={this.handleScroll}>Request Demo</Link>*/}
                  </div>
                </>
            }
          </div>
        </div>
        <div className="navbar-end is-hidden-desktop">
          {
            jwtValid(localStorage.getItem('jwt')) ?
              <>
                {localStorage.getItem('guest')
                  ?
                    <Link to="/signup" className="button is-large is-info mt-5" onClick={this.closeMobileMenu()}>Become a Member</Link>
                  :
                    <NavLink exact className="button is-large is-light mt-5" to="/logout" onClick={this.closeMobileMenu()}>Log Out</NavLink>
                }            
              </>
            :
              <>
                <NavLink exact className="button is-large mb-5 mt-5" activeClassName="active" to="/login" onClick={this.closeMobileMenu()}>Log In</NavLink>
                <br></br>
                <Link to="/signup" className="button is-large is-primary" onClick={(e) => this.createGuestUser(e)}>Try It Now</Link>
              </>
          }       
          {/*
          <div className="navbar-item">
            <DemoEmail buttonClass={buttonClass} inputClass={inputClass} handleScroll={this.handleScroll} justify="is-justify-content-center"/>
          </div>
        */}
        </div>
        </div>
      </nav>
    )   
  }
}


export default Navigation