import React, { Component } from "react"
import { createPasswordForgot } from './createPasswordForgot'

class PasswordForgot extends Component {
  constructor() {
    super()
    this.state = {
      error: null,
      myErrors: {
        email: ''
      }       
    };
    this.handleSubmit = this.handleSubmit.bind(this);
  } 

  handleSubmit (event) {
    event.preventDefault();
    const email = document.getElementById('email').value;
    const body = { email }

    createPasswordForgot(body)
    .then(res => {
      if(res.error) {
        let myErrors = {}
        myErrors.email = res.error
        this.setState({myErrors: myErrors})
      }else {
        this.props.history.push("/");
        this.props.setNotification({notice: "If your account exists, an email will be sent with further instructions."})
      }
    })
    .catch(error => this.setState({ error }));      
  }      

  render() {
    const {error, myErrors} = this.state
    return (
      <section className="hero is-medium login_hero">
        <div className="hero-body">
          <div className="columns is-centered">
            <div className="column is-one-third">
              <div className="box has-background-dark-green">
                <h1 className="title has-text-white">Recover Password</h1>
                <p className="subtitle has-text-white mb-6">Don’t worry, it happens to the best of us.</p>
                <form onSubmit={this.handleSubmit}>
                  <div className="field">
                    <label htmlFor="email" className="label">Email</label>
                    <input name="email" id="email" type="email" className="input" />
                  </div>
                  <div className="field">
                    <div className="control">
                      <button type="submit" className="button is-link">Email me a recovery link</button>
                    </div>
                  </div>
                  {error && 
                    <p className="help is-danger">{error.message}</p>
                  }
                  {myErrors.email && 
                    <p className='help is-danger'>{myErrors.email}</p>
                  }                             
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default PasswordForgot;